import Cropper from 'cropperjs';

export type PhotoEditorModel = {
  blobUrl: string;
  name: string;
  blob: Blob|null;
  fileType: string;
}

const canvasSize = 300;
const URL = window.URL || window.webkitURL;

export const imageLoader = {
  maxSizeInMb: 20,
  getSizeInMb(file: File): number {
    return file.size / 1024 / 1024;
  },
  isFileTooLarge(file: File): boolean {
    return this.getSizeInMb(file) > this.maxSizeInMb;
  },
  read(files: File[]): Promise<PhotoEditorModel> {
    return new Promise((resolve, reject) => {
      if (!files || files.length === 0) {
        resolve({ blobUrl: '', name: '', blob: null, fileType: '' });
        return;
      }

      const file = files[0];

      if (this.isFileTooLarge(file)) {
        reject(new Error(`File too big, max ${this.maxSizeInMb}Mb`));
      }

      if (/^image\/\w+$/.test(file.type) && (/\.(gif|jpe?g|png|webp|bmp)$/i).test(file.name)) {
        if (URL) {
          resolve({
            name: file.name,
            blobUrl: URL.createObjectURL(file),
            blob: null,
            fileType: file.type,
          });
        } else {
          reject(new Error('Your browser is not supported'));
        }
      } else {
        reject(new Error('File type not supported'));
      }
    });
  },
};

export function useKeyboardShortcuts(e: KeyboardEvent, cropper: Cropper, cropCmd: () => void) {
  if (!cropper) {
    return;
  }

  switch (e.key) {
    case 'c':
      cropCmd();
      break;
    case 'ArrowLeft':
      e.preventDefault();
      cropper.move(-1, 0);
      break;
    case 'ArrowUp':
      e.preventDefault();
      cropper.move(0, -1);
      break;
    case 'ArrowRight':
      e.preventDefault();
      cropper.move(1, 0);
      break;
    case 'ArrowDown':
      e.preventDefault();
      cropper.move(0, 1);
      break;
    case 'i':
      cropper.zoom(0.1);
      break;
    case 'o':
      cropper.zoom(-0.1);
      break;
    case 'l':
      cropper.rotate(-90);
      break;
    case 'r':
      cropper.rotate(90);
      break;
    default:
  }
}

export function getUrl(cropper: Cropper): string {
  return cropper.getCroppedCanvas({
    fillColor: '#212121',
    imageSmoothingEnabled: true,
    imageSmoothingQuality: 'high',
    width: canvasSize,
    height: canvasSize,
  })
    .toDataURL();
}

export function getBlob(cropper: Cropper, callback: BlobCallback, background = 'white') {
  return cropper.getCroppedCanvas({
    fillColor: background,
    imageSmoothingEnabled: true,
    imageSmoothingQuality: 'high',
    width: canvasSize,
    height: canvasSize,
  })
    .toBlob((blob) => callback(blob), 'image/jpeg', 0.7);
}

export function getCropper(image: HTMLImageElement, fullSize = false): Cropper {
  let minSize = canvasSize / 2;
  if (fullSize) {
    minSize = canvasSize;
  }

  return new Cropper(image, {
    aspectRatio: 1,
    autoCrop: !fullSize,
    dragMode: fullSize ? 'none' : 'move',
    background: false,
    movable: !fullSize,
    cropBoxResizable: !fullSize,
    minCropBoxWidth: minSize,
    minCropBoxHeight: minSize,
    zoomOnWheel: false,
  });
}
