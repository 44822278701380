<template>
  <q-input
    v-bind="$attrs"
    v-on="$listeners"
    outlined
    no-error-icon
    :value="value"
    rows="3"
    type="textarea"
    :error-message="errorMessage"
    :error="!!errorMessage"
    :rules="[ validation ]"
    lazy-rules="ondemand"
    @keyup.enter.stop
  />
</template>

<script>
import description from '@/validation/description';

export default {
  name: 'FieldDescription',
  props: {
    value: String,
    errorMessage: String,
    required: Boolean,
  },
  methods: {
    validation(text) {
      if (this.required) {
        return description.isRequired(text);
      }
      return description.isValid(text);
    },
  },
};
</script>
