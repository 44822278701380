









import Vue, { PropType } from 'vue';
import { Breadcrumbs, PageTab } from '@/types/common';
import { OrgModel } from '@/types/org';
import { ProjectModel } from '@/types/project';
import PageTabs from '@/layouts/PageTabs.vue';
import { Resource, ResourceType } from '@/types/resource';

export default Vue.extend({
  name: 'ResourceTabs',
  components: {
    PageTabs,
  },
  props: {
    tabs: {
      type: Array as PropType<PageTab[]>,
      required: true,
    },
    resourceType: {
      type: String as PropType<ResourceType>,
      required: true,
    },
  },
  computed: {
    baseUrl(): string {
      return `/org/${this.org.id}/project/${this.project.id}/${this.resourceType}/${this.$route.params.resourceId}`;
    },
    project(): ProjectModel {
      return this.$store.getters['projectModule/details'];
    },
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    breadcrumbs(): Breadcrumbs[] {
      let path = 'assets';
      if (this.resourceType === ResourceType.CONNECTION) {
        path = 'connections';
      }
      if (this.resourceType === ResourceType.DEPENDENCY) {
        path = 'dependencies';
      }

      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}/projects`,
        },
        {
          label: this.project.label,
          url: `/org/${this.org.id}/project/${this.project.id}/${path}`,
        },
        {
          label: this.resource.label,
        },
      ];
    },
  },
  data() {
    return {
      resource: {} as Resource,
      error: '',
    };
  },
});
