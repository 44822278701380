

































import Vue, { PropType } from 'vue';
import { NodeSize } from '@/bridge/types/diagramModel';
import FieldNumeric from '@/components/fields/FieldNumeric.vue';

export default Vue.extend({
  name: 'SizeSettings',
  components: {
    FieldNumeric,
  },
  props: {
    hideTitle: Boolean,
    title: {
      type: String,
      required: false,
    },
    widthLabel: {
      type: String,
      default: 'width',
    },
    heightLabel: {
      type: String,
      default: 'height',
    },
    minWidth: {
      type: [Number, String],
      default: Number.MIN_SAFE_INTEGER,
    },
    minHeight: {
      type: [Number, String],
      default: Number.MIN_SAFE_INTEGER,
    },
    value: {
      type: Object as PropType<NodeSize>,
      required: true,
    },
  },
});
