<template>
  <q-btn
    no-caps
    v-bind="$attrs"
    v-on="$listeners"
    :ripple="false"
  >
    <q-tooltip v-if="tooltip && !$attrs.disable">
      {{tooltip}}
    </q-tooltip>

    <slot></slot>
  </q-btn>
</template>

<script>
export default {
  name: 'Btn',
  props: {
    tooltip: {
      type: String,
      required: false,
    },
  },
};
</script>
