import * as go from 'gojs';
import { DCH } from '@/bridge/types/drawCommandHandler.d';
import { TextStyle } from '@/bridge/types/textStyle';
import { FontStyle, FontWeight, VerticalAlignment } from '@/bridge/enums/textStyle';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';

export const defaultFontFamily = 'DIN Next LT Pro';

export const getFontString = (options: Partial<TextStyle> = {}) => {
  const fontStyle = options.fontStyle || FontStyle.NORMAL;
  const fontWeight = options.fontWeight || FontWeight.BOLD;
  const fontSize = options.fontSize || '14px';
  const fontFamily = options.fontFamily || defaultFontFamily;

  return `${fontStyle} ${fontWeight} ${fontSize}px ${fontFamily}`;
};

export const getVerticalAlignment = (va: VerticalAlignment): go.Spot => {
  switch (va) {
    case VerticalAlignment.TOP:
      return go.Spot.Top;
    case VerticalAlignment.CENTER:
      return go.Spot.Center;
    default:
      return go.Spot.Bottom;
  }
};

export function getSizeStr(part: go.Part): string {
  return go.Size.stringify(part.desiredSize);
}

/**
 * Settings precedence (High > Low)
 * Element settings
 * * * Element preset
 * * * * * Layer preset
 * * * * * * * * Layer settings
 * * * * * * * * * * * Layer defaults
 */
export function getPartModelData(part: go.Part | go.Diagram): AllDiagramSettings {
  if (part instanceof go.Diagram) {
    return part.model.modelData as AllDiagramSettings;
  }

  return (part.diagram as go.Diagram).model.modelData as AllDiagramSettings;
}

export function onUpdateDiagramSettings(d: go.Diagram) {
  const md = d.model.modelData as AllDiagramSettings;
  const { gridWidth, gridHeight } = md.diagram;

  // These need to be updated manually as bindings don't work for some reason.

  // grid
  d.grid.gridCellSize = new go.Size(gridWidth, gridHeight);
  d.grid.visible = md.diagram.gridVisible;
  (d.grid.elt(0) as go.Shape).stroke = md.diagram.gridColor;
  (d.grid.elt(1) as go.Shape).stroke = md.diagram.gridColor;

  // grid snap
  d.toolManager.draggingTool.isGridSnapEnabled = md.diagram.gridSnap;
  d.toolManager.resizingTool.isGridSnapEnabled = md.diagram.gridSnap;

  // tools
  (d.commandHandler as DCH).pasteOffset = new go.Point(gridWidth, gridHeight);

  // guides
  (d.toolManager.draggingTool as any).horizontalGuidelineColor = md.diagram.guidesColor;
  (d.toolManager.draggingTool as any).verticalGuidelineColor = md.diagram.guidesColor;
  (d.toolManager.draggingTool as any).centerGuidelineColor = md.diagram.guidesColor;
  (d.toolManager.draggingTool as any).guidelineWidth = md.diagram.guidesVisible ? 1 : 0;
}
