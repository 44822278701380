<template>
  <div v-if="loading" class="text-center">
    <q-spinner-dots size="1.5rem" color="primary" />
  </div>

  <div v-else-if="error">
    <p class="text-negative">Something went wrong. Please try again later.</p>
  </div>

  <div v-else-if="emptyData">
    <slot name="fallback"></slot>
  </div>

  <div v-else>
    <slot v-bind="{data}"></slot>
  </div>
</template>

<script>
import ApiService from '@/services/api.service';

export default {
  name: 'LazyLoad',
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    emptyData() {
      if (!this.data) {
        return true;
      }

      if (Array.isArray(this.data) && !this.data.length) {
        return true;
      }

      return (typeof this.data === 'object' && !Object.keys(this.data).length);
    },
  },
  data() {
    return {
      loading: false,
      data: null,
      error: '',
    };
  },
  async created() {
    try {
      this.loading = true;
      this.data = await ApiService.get(this.url);
    } catch (e) {
      this.error = e.message;
    } finally {
      this.loading = false;
    }
  },
};
</script>
