<template>
  <div class="bug-report">
    <q-page-sticky position="bottom-left" :offset="[18, 18]" class="z-fab">
      <q-btn round color="primary" icon="question_answer" @click="open"/>
    </q-page-sticky>

    <app-modal
      ref="modal"
      title="Help us improve the platform"
      position="left"
      class="bug-report-form"
    >
      <div class="text-grey">
        Did you find something that doesn't work or can be improved?
      </div>
      <q-form
        style="width: 500px;"
        ref="form"
        @submit.prevent="onSubmit"
        @keyup.enter="onSubmit"
      >
        <q-select
          :disable="loading"
          dense
          outlined
          required
          emit-value
          map-options
          v-model="fields.type"
          :options="options"
          label="Type"
          class="q-mb-md"
        />

        <q-input
          dense
          outlined
          label="Subject"
          :disable="loading"
          v-model="fields.subject"
          :rules="[ validateSubject ]"
        />

        <q-input
          label="Details"
          :disable="loading"
          v-model="fields.description"
          outlined
          no-error-icon
          rows="3"
          type="textarea"
          :rules="[ validateDetails ]"
          @keyup.enter.stop
        />

        <q-input
          v-if="uploadReady"
          :disable="loading"
          dense
          outlined
          clearable
          multiple
          type="file"
          v-model="fields.data"
          :rules="[ validateFile ]"
        />
      </q-form>

      <template #footer>
        <q-space/>
        <Btn
          :loading="loading"
          color="primary"
          label="Send"
          @click="onSubmit"
        />
      </template>
    </app-modal>
  </div>
</template>

<script>
import prepareRequest from '@/components/customerSupport/prepareRequest';
import notify from '@/util/notify';
import AppModal from '@/components/AppModal.vue';
import Btn from '@/components/buttons/Btn.vue';

// upload selected file
async function uploadAttachment(files) {
  const tokens = [];
  if (files && files.length) {
    const upload = async (f) => {
      const response = await fetch(`https://obashi.zendesk.com/api/v2/uploads.json?filename=${f.name}`, {
        method: 'POST',
        body: f,
        headers: {
          'Content-Type': f.type || 'application/binary',
        },
      });
      const json = await response.json();
      if (json.upload && json.upload.token) {
        return json.upload.token;
      }
      return '';
    };

    const fileArr = Array.from(files);
    await Promise.all(fileArr.map(async (file) => {
      const token = await upload(file);
      if (token) {
        tokens.push(token);
      }
    }));
  }
  return tokens;
}

export default {
  name: 'CustomerSupport',
  components: {
    Btn,
    AppModal,
  },
  computed: {
    requester() {
      const user = this.$store.getters['profileModule/user'];
      return {
        name: `${user.surname} ${user.forename}`,
        email: `${user.email}`,
      };
    },
  },
  data() {
    return {
      uploadReady: true,
      dialog: false,
      loading: false,
      options: [
        {
          value: 'question',
          label: 'Ask a question',
        },
        {
          value: 'improvement',
          label: 'Suggest an improvement or enhancement',
        },
        {
          value: 'issue',
          label: 'Report an issue',
        },
      ],
      fields: {
        data: [],
        orgLabel: '',
        type: 'question',
        subject: '',
        description: '',
      },
    };
  },
  methods: {
    validateSubject(val) {
      if (!val) {
        return 'Please add a subject';
      }
      if (val.length > 250) {
        return 'Maximum allowed length is 250 characters';
      }
      return true;
    },
    validateDetails(val) {
      if (!val) {
        return 'Please add some details';
      }
      // https://support.zendesk.com/hc/en-us/articles/360000567348-Is-there-a-character-limit-on-ticket-comments-
      if (val.length > 65535) {
        return 'The text is too long. Maximum allowed length is 65.535 characters';
      }
      return true;
    },
    open() {
      this.$refs.modal.open();
    },
    async onSubmit() {
      this.loading = true;
      const valid = await this.$refs.form.validate();
      if (!valid) {
        this.loading = false;
        return;
      }

      const org = this.$store.getters['orgModule/details'];
      if (org.id) {
        this.fields.orgLabel = org.label;
      }

      let tokens = [];
      if (this.fields.data) {
        tokens = await uploadAttachment(this.fields.data);
      }
      const req = await prepareRequest(this.fields, this.requester, tokens);

      try {
        await fetch('https://obashi.zendesk.com/api/v2/requests.json', {
          method: 'POST',
          body: JSON.stringify(req),
          headers: {
            'Content-Type': 'application/json',
          },
          responseType: 'json',
        });

        notify.success('Ticket created');
      } catch {
        notify.danger('Something went wrong. Please try again later.');
      }

      await this.resetFields();
      await this.$refs.modal.close();
      this.loading = false;
    },
    validateFile(fileList) {
      if (!fileList) {
        return true;
      }

      let valid = true;
      const fileArr = Array.from(fileList);
      if (!Array.isArray(fileArr)) {
        return false;
      }
      fileArr.forEach((file) => {
        if (file.size > 15 * 1024 * 1024) {
          valid = false;
        }
      });

      if (valid) {
        return true;
      }
      return 'Max 15mb';
    },
    async resetFields() {
      this.fields = {
        data: [],
        type: 'question',
        subject: '',
        description: '',
        orgLabel: '',
      };
      this.uploadReady = false;
      this.$nextTick(() => {
        this.uploadReady = true;
      });
      await this.$refs.form.resetValidation();
    },
  },
  mounted() {
    const script = document.getElementById('ze-snippet');
    if (!script && !window.Cypress) {
      const zenDeskScript = document.createElement('script');
      zenDeskScript.setAttribute('id', 'ze-snippet');
      zenDeskScript.setAttribute(
        'src',
        'https://static.zdassets.com/ekr/snippet.js?key=7ca74f74-e2a7-4e55-9464-b3ed56179d34',
      );
      document.body.appendChild(zenDeskScript);
    }
  },
};
</script>

<style>
iframe#launcher {
  left: 50px !important;
  bottom: 3px !important;
  z-index: 6000 !important;
}

.bug-report-form {
  position: relative;
  z-index: 9999;
}
</style>
