import { DiagramType } from '@/bridge/enums/diagramOptions';

export const viewBit = [
  {
    path: 'view-bit/:diagramId/version/:versionId',
    component: () => import(/* webpackChunkName: "view-diagram" */ '@/views/diagram/ViewDiagram.vue'),
    props: {
      diagramType: DiagramType.BIT,
    },
  },
  {
    path: 'view-dav/:diagramId/version/:versionId',
    component: () => import(/* webpackChunkName: "view-diagram" */ '@/views/diagram/ViewDiagram.vue'),
    props: {
      diagramType: DiagramType.DAV,
    },
  },
];

// These will use a different layout
export const modelBit = [
  {
    path: 'model-bit/:diagramId/version/:versionId',
    component: () => import(/* webpackChunkName: "model-bit" */ '@/views/bit/ModelBit.vue'),
  },
  {
    path: 'model-dav/:diagramId/version/:versionId',
    component: () => import(/* webpackChunkName: "model-dav" */ '@/views/dav/ModelDav.vue'),
  },
  {
    path: 'review-bit/:diagramId/version/:versionId',
    component: () => import(/* webpackChunkName: "review-bit" */ '@/views/bit/ReviewBit.vue'),
  },
];
