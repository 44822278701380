




















































// library
import Vue, { VueConstructor, PropType } from 'vue';
import formSubmit from '@/mixins/formSubmit';
// types
import { BasicOrgDetails } from '@/types/org';
import { FormWrapperRequest } from '@/types/form';
// components
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import ApiError from '@/components/ApiError.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';

function getDefaultModel(): BasicOrgDetails {
  return {
    label: '',
    location: '',
    slogan: '',
    description: '',
  };
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'EditOrgDetails',
  components: {
    Btn,
    ApiError,
    AppModal,
    FieldLabel,
    FieldDescription,
  },
  mixins: [formSubmit],
  props: {
    details: {
      type: Object as PropType<BasicOrgDetails>,
      required: true,
    },
  },
  computed: {
    request(): FormWrapperRequest {
      return {
        config: {
          data: this.model,
          method: 'put',
          url: `/org/${this.$route.params.orgId}`,
        },
      };
    },
  },
  data() {
    return {
      model: getDefaultModel(),
    };
  },
  methods: {
    async onSubmit() {
      await this.submit(this.request, this.$refs.form);

      if (this.hasError) {
        return;
      }

      await this.$store.dispatch('orgModule/loadDetails', {
        orgId: this.$route.params.orgId,
        force: true,
      });
      (this.$refs.modal as any).close();
      this.notify('Details updated');
    },
    openModal() {
      this.model = {
        label: this.details.label,
        slogan: this.details.slogan,
        location: this.details.location,
        description: this.details.description,
      };
      (this.$refs.modal as any).open();
    },
  },
});
