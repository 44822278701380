

















































import Vue, { PropType } from 'vue';
import { LinkSettings } from '@/bridge/types/diagramModel';
import { DiagramType } from '@/bridge/enums/diagramOptions';
import FieldColorPicker from '@/components/fields/FieldColorPicker.vue';
import FieldNumeric from '@/components/fields/FieldNumeric.vue';
import FieldCheckbox from '@/components/fields/FieldCheckbox.vue';

export default Vue.extend({
  name: 'LinkSettings',
  components: {
    FieldCheckbox,
    FieldNumeric,
    FieldColorPicker,
  },
  props: {
    isConnection: Boolean,
    value: {
      type: Object as PropType<LinkSettings>,
      required: false,
    },
    diagramType: {
      type: String as PropType<DiagramType>,
      default: DiagramType.BIT,
    },
  },
  computed: {
    name(): string {
      return this.isConnection ? 'connection' : 'dependency';
    },
    isBit(): boolean {
      return this.diagramType === DiagramType.BIT;
    },
  },
  data() {
    return {
      model: { ...this.value },
    };
  },
  methods: {
    emitInput() {
      this.$emit('input', this.model);
    },
  },
});
