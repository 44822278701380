<template>
  <q-layout view="lhh lpr lff" class="main-layout">
    <toolbar :color="color">
      <template #left>
        <slot name="toolbarLeft"></slot>
      </template>
      <template #right>
        <slot name="toolbarRight"></slot>
      </template>
    </toolbar>

    <slot name="drawers"></slot>

    <q-page-container class="main-layout--page-container">
      <router-view />
    </q-page-container>

    <Footer />

    <CustomerSupport />
  </q-layout>
</template>

<script>
import Toolbar from '@/layouts/toolbar/Toolbar.vue';
import Footer from '@/layouts/main/Footer.vue';
import CustomerSupport from '@/components/customerSupport/CustomerSupport.vue';

export default {
  name: 'MainLayout',
  components: {
    CustomerSupport,
    Footer,
    Toolbar,
  },
  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>

<style lang="scss">
@import "~quasar-variables";

body:not(.body--dark) {
  .main-layout {
    &--page-container {
      background: #fafafa;
    }
  }
}
</style>
