
























import Vue, { PropType } from 'vue';
import { NodeColor } from '@/bridge/types/diagramModel';
import FieldColorPicker from '@/components/fields/FieldColorPicker.vue';

export default Vue.extend({
  name: 'ColorSettings',
  components: { FieldColorPicker },
  props: {
    value: {
      type: Object as PropType<NodeColor>,
      required: true,
    },
  },
});
