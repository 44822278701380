import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import { getDefaultDiagramSettings } from '@/bridge/settings/diagramSettings';
import { getDefaultConnectionSettings } from '@/bridge/settings/connectionSettings';
import { getDefaultDependencySettings } from '@/bridge/settings/dependencySettings';
import { getDefaultElementSettings } from '@/bridge/settings/elementSettings';
import { getDefaultLayerSettings } from '@/bridge/settings/layerSettings';

export function getDefaultModelData(): AllDiagramSettings {
  return {
    nextNodeId: 10,
    nextLinkId: 10,
    diagram: getDefaultDiagramSettings(),
    connection: getDefaultConnectionSettings(),
    dependency: getDefaultDependencySettings(),
    element: getDefaultElementSettings(),
    layers: getDefaultLayerSettings(),
    nodePresets: {},
    meta: {
      globalSettingsVersion: 1,
      skipVersion: 1,
      showUpdateNotification: true,
      hasLocalSettings: false,
    },
  };
}
