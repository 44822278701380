












































import Vue, { PropType } from 'vue';
import { ProjectModel } from '@/types/project';
import { SocialDetailsMap } from '@/types/social';
import ApiService from '@/services/api.service';
import ViewSocial from '@/components/social/ViewSocial.vue';
import AvatarWithControls from '@/components/avatar/AvatarWithControls.vue';
import ExpandableListItem from '@/components/list/ExpandableListItem.vue';
import ProjectPeople from '@/views/projects/ProjectPeople.vue';

function getDefaultSocial(): SocialDetailsMap {
  return {
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    website: '',
  };
}

export default Vue.extend({
  name: 'ProjectDetails',
  components: {
    ProjectPeople,
    ViewSocial,
    ExpandableListItem,
    AvatarWithControls,
  },
  props: {
    showEdit: Boolean,
    details: {
      type: Object as PropType<ProjectModel>,
      required: true,
    },
  },
  data() {
    return {
      socialDetails: getDefaultSocial(),
      loaded: false,
    };
  },
  methods: {
    async loadDetails() {
      await this.$store.dispatch('projectModule/loadDetails', {
        force: true,
        id: this.$route.params.projectId,
      });
    },
    async loadSocialDetails() {
      try {
        this.socialDetails = await ApiService.get(`/project/${this.$route.params.projectId}/social`);
      } catch {
        this.socialDetails = getDefaultSocial();
      }
      this.loaded = true;
    },
  },
  async created() {
    await this.loadSocialDetails();
  },
});
