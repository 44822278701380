<template>
  <q-btn
    dense
    size="sm"
    class="q-ma-sm"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'PhotoEditorButton',
};
</script>
