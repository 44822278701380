import * as go from 'gojs';
import { NodeCategory } from '@/bridge/enums/partCategories';
import { NodeColor, LayerSettings, NodeSize } from '@/bridge/types/diagramModel';
import { getPartModelData } from '@/bridge/settings/common';
import { FontWeight, FontStyle, VerticalAlignment, TextAlign } from '@/bridge/enums/textStyle';
import { MinLength, MinBreadth } from '@/bridge/poolLayout/PoolLayout';

const getDefaultLayerSize = () => ({ width: MinLength, height: MinBreadth });

const getDefaultElementSize = () => ({ width: 120, height: 40 });

const getDefaultElementTextStyle = () => ({
  fontSize: 16,
  fontWeight: FontWeight.NORMAL,
  fontStyle: FontStyle.NORMAL,
  fontFamily: 'DIN Next LT Pro',
  verticalAlignment: VerticalAlignment.CENTER,
  textAlign: TextAlign.CENTER,
  isUnderline: false,
});

const getOwnershipColor = (): NodeColor => ({
  textColor: '#47145a',
  borderColor: '#47145a',
  backgroundColor: '#d5b7e1',
});

const getBusinessColor = (): NodeColor => ({
  textColor: '#4d2b5a',
  borderColor: '#4d2b5a',
  backgroundColor: '#e8dce6',
});

const getApplicationColor = (): NodeColor => ({
  textColor: '#5a2a2a',
  borderColor: '#5a2a2a',
  backgroundColor: '#ffb0b7',
});

const getSystemColor = (): NodeColor => ({
  textColor: '#003a5a',
  borderColor: '#003a5a',
  backgroundColor: '#c4d8e5',
});

const getHardwareColor = (): NodeColor => ({
  textColor: '#595a0c',
  borderColor: '#595a0c',
  backgroundColor: '#eeea9d',
});

const getInfrastructureColor = (): NodeColor => ({
  textColor: '#005a29',
  borderColor: '#005a29',
  backgroundColor: '#abdebe',
});

const getGenericLayerColor = (): NodeColor => ({
  backgroundColor: 'rgb(222,222,222)',
  borderColor: 'rgba(0, 0, 0, 1)',
  textColor: 'rgba(0, 0, 0, 1)',
});

function getGenericLayerSettings(): LayerSettings {
  return {
    color: getGenericLayerColor(),
    size: getDefaultLayerSize(),
    elementSize: getDefaultElementSize(),
    elementTextStyle: getDefaultElementTextStyle(),
  };
}

function getCommonSettings() {
  return {
    size: getDefaultLayerSize(),
    elementSize: getDefaultElementSize(),
    elementTextStyle: getDefaultElementTextStyle(),
  };
}

export const getDefaultLayerSettings = (): LayerSettings[] => [
  {
    color: getOwnershipColor(),
    ...getCommonSettings(),
  },
  {
    color: getBusinessColor(),
    ...getCommonSettings(),
  },
  {
    color: getApplicationColor(),
    ...getCommonSettings(),
  },
  {
    color: getSystemColor(),
    ...getCommonSettings(),
  },
  {
    color: getHardwareColor(),
    ...getCommonSettings(),
  },
  {
    color: getInfrastructureColor(),
    ...getCommonSettings(),
  },
];

export function isObashiLayer(x: any): boolean {
  const i = parseInt(x, 10);
  if (Number.isNaN(i)) {
    return false;
  }
  return i >= 0 && i <= 5;
}

// The part can be an element or a layer
export function getLayerSettings(part?: go.Part): LayerSettings {
  if (!part) {
    return getGenericLayerSettings();
  }

  const md = getPartModelData(part);
  const { group, id, category } = part.data;

  // elements have a layer property which is the layer id/index
  if (isObashiLayer(group)) {
    return md.layers[group];
  }

  // for layers, we can use the id
  if (isObashiLayer(id) && category === NodeCategory.LAYER) {
    return md.layers[id];
  }

  return getGenericLayerSettings();
}

// The part can be an element or a layer
export function layerColor(part?: go.Part): NodeColor {
  if (!part) {
    return getGenericLayerColor();
  }

  let s: LayerSettings;
  const md = getPartModelData(part);

  const { group, id, category } = part.data;

  // elements have a layer property which is the layer id/index
  if (isObashiLayer(group)) {
    s = md.layers[group];
  } else if (isObashiLayer(id) && category === NodeCategory.LAYER) {
    // for layers, we can use the id
    s = md.layers[id];
  } else {
    return getGenericLayerColor();
  }

  return {
    textColor: s.color.textColor,
    borderColor: s.color.borderColor,
    backgroundColor: s.color.backgroundColor,
  };
}

export function layerSize(part?: go.Part): NodeSize {
  if (!part) {
    return getDefaultLayerSize();
  }

  const { id } = part.data;
  const md = getPartModelData(part);
  if (!isObashiLayer(id)) {
    return getDefaultLayerSize();
  }

  const ls = md.layers[id].size;
  const width = Math.max(ls.width, part.data.size.width);
  const height = Math.max(ls.height, part.data.size.height);

  return { width, height };
}
