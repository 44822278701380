




























import Vue, { PropType } from 'vue';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import DiagramSettings from '@/components/diagram/DiagramSettings.vue';
import LayerSettings from '@/components/diagram/LayerSettings.vue';
import LinkSettings from '@/components/diagram/LinkSettings.vue';
import { DiagramType } from '@/bridge/enums/diagramOptions';

type SettingsTab = {
  name: keyof AllDiagramSettings;
  label: string;
  component: string;
  isConnection?: boolean;
};

export default Vue.extend({
  name: 'AllDiagramSettings',
  components: {
    LinkSettings,
    LayerSettings,
    DiagramSettings,
  },
  props: {
    vertical: Boolean,
    value: {
      type: Object as PropType<AllDiagramSettings>,
      required: false,
    },
    diagramType: {
      type: String as PropType<DiagramType>,
      default: DiagramType.BIT,
    },
  },
  computed: {
    tabs(): SettingsTab[] {
      const tabs: SettingsTab[] = [
        {
          name: 'diagram',
          label: 'Diagram',
          component: 'DiagramSettings',
        },
        {
          name: 'layers',
          label: 'Layer',
          component: 'LayerSettings',
        },
        {
          name: 'connection',
          label: 'Connection',
          component: 'LinkSettings',
          isConnection: true,
        },
        {
          name: 'dependency',
          label: 'Dependency',
          component: 'LinkSettings',
        },
      ];

      if (this.diagramType === DiagramType.BIT) {
        return tabs;
      }
      return tabs.slice(1);
    },
  },
  data() {
    return {
      tab: 'diagram',
    };
  },
  methods: {
    onInput(e: any, group: keyof AllDiagramSettings) {
      // each tab emits the model for that tab. so we can replace it and emit a new value
      const tmp = { ...this.value };
      tmp[group] = e;
      this.$emit('input', tmp);
    },
  },
  created() {
    if (this.diagramType === DiagramType.DAV) {
      this.tab = 'layers';
    }
  },
});
