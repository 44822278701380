import { Route } from 'vue-router';
import { CreateElement } from 'vue';
import store from '@/store';
import connections from '@/router/projectContent/connections';
import dependencies from '@/router/projectContent/dependencies';
import assets from '@/router/projectContent/assets';
import forms from '@/router/projectContent/forms';
import files from '@/router/projectContent/files';
import projectTabs from '@/router/projectContent/projectTabs';
import orgTabs from '@/router/org/orgTabs';
import { viewBit } from '@/router/projectContent/diagrams';

export default [
  {
    path: '/org/:orgId',
    component: { render: (c: CreateElement) => c('router-view') },
    meta: { auth: true },
    async beforeEnter(to: Route, from: Route, next: () => void) {
      await store.dispatch('orgModule/loadDetails', {
        orgId: to.params.orgId,
        force: true,
      });
      next();
    },
    children: [
      {
        path: 'shared-diagram/:diagramId/version/:versionId',
        component: () => import(/* webpackChunkName: "view-shared-diagram" */ '@/views/shared/ViewSharedDiagram.vue'),
        async beforeEnter(to: Route, from: Route, next: () => void) {
          await store.dispatch('projectModule/loadSharedPreferences', {
            force: true,
            id: to.params.diagramId,
            orgId: to.params.orgId,
          });
          next();
        },
      },
      ...orgTabs,
      {
        path: 'project/:projectId',
        component: { render: (c: CreateElement) => c('router-view') },
        async beforeEnter(to: Route, from: Route, next: () => void) {
          await store.dispatch('projectModule/loadDetails', {
            force: true,
            id: to.params.projectId,
          });
          await store.dispatch('projectModule/loadPreferences', {
            force: true,
            id: to.params.projectId,
          });
          next();
        },
        children: [
          ...connections,
          ...dependencies,
          ...assets,
          ...forms,
          ...files,
          ...viewBit,
          ...projectTabs,
        ],
      },
    ],
  },
];
