import Vue from 'vue';
import ApiService from '@/services/api.service';
import { FormWrapperRequest } from '@/types/form';
import notify from '@/util/notify';

export default Vue.extend({
  data() {
    return {
      loading: false,
      error: '',
      fieldErrors: {} as Record<string, string>,
      hasError: false,
    };
  },
  methods: {
    // Important - form should be a $ref for <q-form>
    async validateForm(form: any): Promise<boolean> {
      const valid = await form.validate();

      if (valid) {
        this.hasError = false;
      } else {
        this.loading = false;
        this.hasError = true;
      }

      return valid;
    },

    resetValidation() {
      Object.keys(this.fieldErrors).forEach((field: string) => {
        this.fieldErrors[field] = '';
      });
      this.error = '';
    },

    async handleRequest(request: FormWrapperRequest): Promise<unknown> {
      if (request.callback) {
        return request.callback();
      }

      if (request.config) {
        const { data, url, method } = request.config;

        if (data && typeof data === 'object') {
          Object.keys(data).forEach((k) => {
            if (data[k] === null) {
              data[k] = '';
            }
          });
        }

        return ApiService[method](url, data);
      }

      throw new Error('Invalid arguments for formSubmit');
    },

    async submit(request: FormWrapperRequest, form?: any): Promise<any> {
      this.loading = true;

      if (form) {
        const valid = await this.validateForm(form);
        if (!valid) {
          return null;
        }
      }

      try {
        const resp = await this.handleRequest(request);

        this.error = '';
        this.fieldErrors = {};
        this.hasError = false;

        return resp;
      } catch (e: any) {
        this.hasError = true;

        if (e.message) {
          this.error = e.message;
        } else if (e.fields) {
          this.fieldErrors = e.fields;
        }
      }

      this.loading = false;
      return null;
    },

    // This can be used to temporarily disable the submit button
    // to prevent submitting the form multiple times, when
    // there's no redirect or modal close after submit
    preventTooManyRequests() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2500);
    },
    dispatch(action: string, payload: unknown) {
      this.$store.dispatch(action, payload);
    },
    notify(message: string) {
      notify.success(message);
    },
  },
});
