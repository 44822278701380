















import Vue, { PropType } from 'vue';
import { SocialDetailsMap } from '@/types/social';
import SocialDetails from '@/components/social/SocialDetails.vue';
import EditSocial from '@/components/social/EditSocial.vue';

export default Vue.extend({
  name: 'ViewSocial',
  components: {
    EditSocial,
    SocialDetails,
  },
  props: {
    isCompany: Boolean,
    showEdit: Boolean,
    size: {
      type: String,
      default: 'md',
    },
    url: {
      type: String,
      required: true,
    },
    details: {
      type: Object as PropType<SocialDetailsMap>,
      required: true,
    },
  },
});
