
















































































































import Vue, { PropType } from 'vue';
import { TextStyle } from '@/bridge/types/textStyle';
import { FontWeight, FontStyle } from '@/bridge/enums/textStyle';
import Btn from '@/components/buttons/Btn.vue';

const ACTIVE_COLOR = 'accent';

export default Vue.extend({
  name: 'TextSettings',
  components: { Btn },
  props: {
    value: {
      type: Object as PropType<TextStyle>,
      required: true,
    },
  },
  computed: {
    fontWeightColor(): string {
      return this.value.fontWeight === FontWeight.BOLD ? ACTIVE_COLOR : '';
    },
    fontStyleColor(): string {
      return this.value.fontStyle === FontStyle.ITALIC ? ACTIVE_COLOR : '';
    },
    isUnderlineColor(): string {
      return this.value.isUnderline ? ACTIVE_COLOR : '';
    },
  },
  data() {
    return {
      sizeOptions: [
        10, 12, 14, 16, 18,
        20, 22, 28, 32, 36,
        40, 48, 55, 65, 72,
      ],
    };
  },
  methods: {
    getTextAlignColor(val: string): string {
      return this.value.textAlign === val ? ACTIVE_COLOR : '';
    },
    getVerticalAlignmentColor(val: string): string {
      return this.value.verticalAlignment === val ? ACTIVE_COLOR : '';
    },
    updateFontSize(val: string) {
      let fontSize = parseInt(val, 10);
      if (Number.isNaN(fontSize)) {
        fontSize = 16;
      }

      this.$emit('input', { ...this.value, fontSize } as TextStyle);
    },
    updateFontWeight() {
      if (this.value.fontWeight === FontWeight.NORMAL) {
        this.$emit('input', { ...this.value, fontWeight: FontWeight.BOLD } as TextStyle);
      } else {
        this.$emit('input', { ...this.value, fontWeight: FontWeight.NORMAL } as TextStyle);
      }
    },
    updateFontStyle() {
      if (this.value.fontStyle === FontStyle.NORMAL) {
        this.$emit('input', { ...this.value, fontStyle: FontStyle.ITALIC } as TextStyle);
      } else {
        this.$emit('input', { ...this.value, fontStyle: FontStyle.NORMAL } as TextStyle);
      }
    },
    updateFontUnderline() {
      this.$emit('input', { ...this.value, isUnderline: !this.value.isUnderline } as TextStyle);
    },
    updateTextAlign(textAlign: string) {
      this.$emit('input', { ...this.value, textAlign } as TextStyle);
    },
    updateVerticalAlignment(verticalAlignment: string) {
      this.$emit('input', { ...this.value, verticalAlignment } as TextStyle);
    },
  },
});
