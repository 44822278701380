import * as go from 'gojs';
import { DiagramSettings } from '@/bridge/types/diagramModel';
import { getPartModelData } from '@/bridge/settings/common';

export const getDefaultDiagramSettings = (): DiagramSettings => ({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  portHoverColor: 'rgba(41, 128, 185, 0.5)',
  highlightColor: 'rgba(5, 196, 107, 1)',
  gridWidth: 10,
  gridHeight: 10,
  gridColor: 'rgba(37,174,234,0.1)',
  gridVisible: true,
  gridSnap: true,
  guidesColor: '#27ae60',
  guidesVisible: true,
  rulerVisible: false,
});

export function diagramSettings(part: go.Part | go.Diagram): DiagramSettings {
  return getPartModelData(part).diagram || getDefaultDiagramSettings();
}
