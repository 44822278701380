<template>
  <a
    :href="href"
    rel="noopener nofollow"
    :class="cssClasses"
    target="_blank"
  >
    <div class="content">
      <q-icon :name="icon" v-if="icon" />
      <span class="q-pt-xs">{{ content }}</span>
    </div>
  </a>
</template>

<script>
export default {
  name: 'ExternalLink',
  props: {
    noUnderline: Boolean,
    href: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'transparent',
    },
    textColor: {
      type: String,
      default: 'light-blue-5',
    },
  },
  computed: {
    cssClasses() {
      if (this.noUnderline) {
        return `bg-${this.color} text-${this.textColor} no-link-underline`;
      }
      return `bg-${this.color} text-${this.textColor}`;
    },
  },
};
</script>
