import * as go from 'gojs';
import { locToGoPoint, pointToNodeLoc } from '@/bridge/util/shared';

// common settings for both Lane and Pool Groups
export default function groupStyle() {
  return [
    {
      deletable: false,
      layerName: 'Background', // all pools and lanes are always behind all nodes and links
      movable: false, // allows users to re-order by dragging
      copyable: false, // can't copy lanes or pools
      avoidable: false, // don't impede AvoidsNodes routed Links
    },
    new go.Binding('location', 'loc', locToGoPoint).makeTwoWay(pointToNodeLoc),
  ];
}
