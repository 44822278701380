










































































import Vue, { PropType } from 'vue';
import { LayerSettings } from '@/bridge/types/diagramModel';
import { DiagramType } from '@/bridge/enums/diagramOptions';
import { layerUtils } from '@/bridge/base/layerInfo';
import { getLayerMap } from '@/util/layers';
import { getDefaultLayerSettings } from '@/bridge/settings/layerSettings';
import { MinBreadth, MinLength } from '@/bridge/poolLayout/PoolLayout';
import Btn from '@/components/buttons/Btn.vue';
import FieldNumeric from '@/components/fields/FieldNumeric.vue';
import SelectLayer from '@/components/fields/SelectLayer.vue';
import TextSettings from '@/components/diagram/TextSettings.vue';
import ColorSettings from '@/components/diagram/ColorSettings.vue';
import SizeSettings from '@/components/diagram/SizeSettings.vue';

export default Vue.extend({
  name: 'LayerSettings',
  components: {
    SizeSettings,
    ColorSettings,
    Btn,
    TextSettings,
    SelectLayer,
    FieldNumeric,
  },
  props: {
    value: {
      type: Array as PropType<LayerSettings[]>,
      required: true,
    },
    diagramType: {
      type: String as PropType<DiagramType>,
      default: DiagramType.BIT,
    },
  },
  computed: {
    isBit(): boolean {
      return this.diagramType === DiagramType.BIT;
    },
  },
  data() {
    return {
      validation: {
        minWidth: MinLength,
        minHeight: MinBreadth,
      },
      selectedLayer: 0,
      // All layers have the same width
      minWidth: this.value[0].size.width,
      layers: getLayerMap(),
      model: [...this.value],
    };
  },
  methods: {
    updateMinWidth(value: number) {
      layerUtils.layers.forEach((l, index) => {
        this.model[index].size.width = value;
      });
      this.emitInput();
    },
    resetSettingsForLayer() {
      this.$set(this.model, this.selectedLayer, getDefaultLayerSettings()[this.selectedLayer]);
      this.model[this.selectedLayer].size.width = this.minWidth;

      this.emitInput();
    },
    emitInput() {
      this.$emit('input', this.model);
    },
  },
});
