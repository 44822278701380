<template>
  <div class="flex flex-center">
    <q-spinner-dots size="3rem" :color="color"/>
  </div>
</template>

<script>
export default {
  name: 'PageSpinner',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
  },
};
</script>
