import * as go from 'gojs';
import { layerColor, layerSize } from '@/bridge/settings/layerSettings';
import { NodeSize } from '@/bridge/types/diagramModel';

const $ = go.GraphObject.make;

const ResizeHandlerSizeLow = 5;
const ResizeHandlerSizeHigh = 50;

function commonBindings() {
  return [
    new go.Binding('fill', '', (_, part) => layerColor(part.part).backgroundColor),
    new go.Binding('stroke', '', (_, part) => layerColor(part.part).borderColor),
  ];
}

function rightAdornmentTemplate() {
  return $(
    go.Shape,
    'RoundedRectangle', // for changing the length of a lane
    {
      alignment: go.Spot.Right,
      desiredSize: new go.Size(ResizeHandlerSizeLow, ResizeHandlerSizeHigh),
      cursor: 'col-resize',
    },
    new go.Binding('desiredSize', 'size', (size: NodeSize, panel: go.Panel) => {
      const ls = layerSize(panel.part as go.Part);
      return new go.Size(ResizeHandlerSizeLow, ls.height - ResizeHandlerSizeHigh);
    }),
    commonBindings(),
  );
}

function bottomAdornmentTemplate() {
  return $(
    go.Shape,
    'RoundedRectangle', // for changing the breadth of a lane
    {
      alignment: go.Spot.Bottom,
      desiredSize: new go.Size(ResizeHandlerSizeHigh, ResizeHandlerSizeLow),
      cursor: 'row-resize',
    },
    new go.Binding('desiredSize', 'size', (size: NodeSize, panel: go.Panel) => {
      const ls = layerSize(panel.part as go.Part);
      return new go.Size(ls.width - ResizeHandlerSizeHigh, ResizeHandlerSizeLow);
    }),
    commonBindings(),
  );
}

// define a custom resize adornment that has two resize handles
export default function resizeAdornmentTemplate() {
  return $(
    go.Adornment,
    'Spot',
    $(go.Placeholder),
    rightAdornmentTemplate(),
    bottomAdornmentTemplate(),
  );
}
