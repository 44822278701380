







































import Vue, { PropType } from 'vue';
import ApiService from '@/services/api.service';

type SelectOption = {
  id: string;
  label: string;
};

export default Vue.extend({
  name: 'SelectDivisions',
  props: {
    required: Boolean,
    layerFilter: String,
    errorMessage: String,
    value: {
      type: Array as PropType<string[]>,
      required: false,
    },
    fieldErrors: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: [] as SelectOption[],
    };
  },
  methods: {
    validation(selected: string | string[]) {
      if (this.required && !selected) {
        return 'Asset is required';
      }

      return true;
    },
    async loadOptions() {
      const response = await ApiService.get(
        `project/${this.$route.params.projectId}/assets/info`,
        { perPage: 99, layer: this.layerFilter },
      );

      if (!response.results || !response.results.length) {
        this.options = [];
        return;
      }

      this.options = response.results;
    },
    async filterFn(val: string, update: any, abort: any) {
      try {
        const response = await ApiService.get(`/org/${this.$route.params.orgId}/division/info`, {
          perPage: 99,
          search: val,
        });

        update(() => {
          if (!response.results || !response.results.length) {
            this.options = [];
            return;
          }
          const needle = val.toLowerCase();
          this.options = response.results
            .filter((v: SelectOption) => v.label.toLowerCase()
              .includes(needle));
        });
      } catch (e) {
        if (abort) {
          abort();
        }
      }
    },
  },
  async created() {
    const response = await ApiService.get(`/org/${this.$route.params.orgId}/division/info`, {
      perPage: 99,
    });

    if (!response.results || !response.results.length) {
      this.options = [];
      return;
    }

    this.options = response.results;
  },
});
