<template>
  <q-tooltip
    v-if="$slots.default"
    v-bind="$props"
    :offset="[10, 0]"
    content-class="bg-white text-dark-grey white-space-pre-wrap q-pa-md shadow-3"
    content-style="font-size: 1rem;"
    max-width="300px"
  >
    <slot></slot>
  </q-tooltip>
</template>

<script>
export default {
  name: 'ListTooltip',
};
</script>
