






















import Vue from 'vue';
import { OrgModel } from '@/types/org';
import Page from '@/layouts/Page.vue';
import ApiService from '@/services/api.service';
import OrgDetails from '@/views/org/OrgDetails.vue';
import EmptyOrgList from '@/views/org/EmptyOrgList.vue';
import Btn from '@/components/buttons/Btn.vue';

export default Vue.extend({
  name: 'Home',
  components: {
    Btn,
    Page,
    OrgDetails,
    EmptyOrgList,
  },
  computed: {
    pageTitle(): string {
      const { displayName, forename } = this.$store.getters['profileModule/user'];

      return `${displayName || forename}'s organisations`;
    },
  },
  data() {
    return {
      loading: false,
      orgList: [] as OrgModel[],
      hasError: false,
      users: [],
      socialMediaDetails: {
        facebook: '',
        instagram: '',
        linkedin: '',
        website: '',
        twitter: '',
      },
    };
  },
  async created() {
    this.loading = true;
    try {
      this.orgList = await ApiService.get('/org/list') || [];
    } catch {
      this.hasError = true;
    } finally {
      this.loading = false;
    }
  },
});
