import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { loadDetails } from '@/store/storeHelpers';
import { RootState } from '@/store/rootState';
import { OrgModel, OrgModelPreview } from '@/types/org';
import { Storable } from '@/types/common';
import { Roles, SecurityLevel } from '@/types';

type OrgState = Storable<OrgModel>;

type LoadDetailsParams = {
  orgId: string;
  force: false;
}

const getOrgDetails = () => ({
  id: '',
  label: '',
  description: '',
  totalMembers: 0,
  location: '',
  avatar: '',
  slogan: '',
  createdBy: '',
  updatedBy: '',
  createdAt: 0,
  updatedAt: 0,
  status: '',
  selfUrl: '',
  roleWithinOrg: Roles.GUEST,
  securityLevel: SecurityLevel.None,
});

const orgState: OrgState = {
  details: getOrgDetails(),
  loaded: false,
  error: '',
};

const getters: GetterTree<OrgState, RootState> = {
  basicDetails(state: OrgState): OrgModelPreview {
    const details = state.details || getOrgDetails();
    return {
      id: details.id,
      label: details.label,
      description: details.description,
      avatar: details.avatar,
      slogan: details.slogan,
      totalMembers: details.totalMembers,
    };
  },
  details(state: OrgState): OrgModel {
    return state.details || getOrgDetails();
  },
  userRole(state: OrgState): Roles {
    if (state.details) {
      return state.details.roleWithinOrg;
    }
    return Roles.GUEST;
  },
  userSecurityLevel(state: OrgState): SecurityLevel {
    if (state.details) {
      return state.details.securityLevel;
    }
    return SecurityLevel.None;
  },
};

const actions: ActionTree<OrgState, any> = {
  async loadDetails(context: ActionContext<OrgState, RootState>, payload: LoadDetailsParams) {
    const url = `/org/${payload.orgId}`;
    const storable = await loadDetails<OrgModel>(context.state, url, payload.force);
    context.commit('setDetails', storable);
  },

  clear(context: ActionContext<OrgState, RootState>) {
    context.commit('setDetails', {
      details: null,
      loaded: false,
      error: '',
    });
  },
};

const mutations: MutationTree<OrgState> = {
  setDetails(state: OrgState, s: OrgState) {
    state.details = s.details || getOrgDetails();
    state.loaded = s.loaded;
    state.error = s.error;
  },
};

export default {
  namespaced: true,
  state: orgState,
  getters,
  actions,
  mutations,
};
