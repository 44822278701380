import Vue from 'vue';

import '../styles/quasar.scss';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css';
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import QMenu from 'quasar/src/components/menu/QMenu.js';;

Vue.use(Quasar, {
  config: {
    supportIE: false,
    notify: {
      position: 'top-right',
      timeout: 2000,
      actions: [
        {
          icon: 'close',
          color: 'white',
          handler: () => null,
        },
      ],
    },
  },
  components: {
    /* not needed if importStrategy is not 'manual' */
  },
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  plugins: {
    Notify,
    AppFullscreen,
    QMenu,
  },
});
