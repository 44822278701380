import * as go from 'gojs';
import { DiagramMode } from '@/bridge/enums/diagramOptions';
import { FontWeight } from '@/bridge/enums/textStyle';
import { NodeCategory } from '@/bridge/enums/partCategories';
import resizeAdornmentTemplate from '@/bridge/poolLayout/resizeAdornmentTemplate';
import commonGroupStyle from '@/bridge/poolLayout/commonGroupStyle';
import finishDrop from '@/bridge/poolLayout/finishDrop';
import { AllDiagramSettings, DiagramSettings, NodeSize, templateFn } from '@/bridge/types/diagramModel';
import { layerColor, layerSize } from '@/bridge/settings/layerSettings';
import { getFontString } from '@/bridge/settings/common';
import { reLayoutDiagram, toGoSize, toNodeSize } from '@/bridge/util/shared';
import { layerUtils } from '@/bridge/base/layerInfo';
import { capitalizeAllWords } from '@/util/strings';

import cursorAdd from '@/assets/images/cursor-add.png';

const $ = go.GraphObject.make;

export const layerPadding = new go.Margin(20, 19, 19, 18);

export const defaultLayerLabelHeight = 30;
export const defaultLayerPadding = 20;

export function getLayerPadding(ds: DiagramSettings, negativeValue?: boolean) {
  const { gridWidth, gridHeight } = ds;

  if (gridWidth && gridHeight) {
    if (negativeValue) {
      return new go.Margin(-gridHeight, -gridWidth);
    }
    return new go.Margin(gridHeight, gridWidth - 1, gridHeight - 1, gridWidth);
  }

  if (negativeValue) {
    return new go.Margin(-defaultLayerPadding);
  }
  return new go.Margin(defaultLayerPadding);
}

export function getLayerLabelHeight(ds: DiagramSettings) {
  const { gridWidth } = ds;

  if (gridWidth) {
    if (gridWidth >= defaultLayerLabelHeight) {
      return gridWidth - 1;
    }

    const diff = defaultLayerLabelHeight % gridWidth;

    if (diff === 0) {
      return defaultLayerLabelHeight - 1;
    }

    return defaultLayerLabelHeight + gridWidth - diff - 1;
  }

  return NaN;
}

export function updateLanes(diagram: go.Diagram, ds: DiagramSettings) {
  diagram.clearSelection();
  diagram.clearHighlighteds();

  diagram.findNodesByExample({ category: 'PoolLane' })
    .each((g) => {
      const lanePlaceholder = g.findObject('LanePlaceholder') as go.Part;
      const selectionPlaceholder = g.selectionAdornmentTemplate?.findObject('SelectionLanePlaceholder') as go.Part;

      lanePlaceholder.padding = getLayerPadding(ds);
      selectionPlaceholder.padding = getLayerPadding(ds, true);

      if (selectionPlaceholder.part) {
        (selectionPlaceholder.part.elt(0) as go.Shape).strokeDashArray = [ds.gridWidth / 5];
      }
    });

  reLayoutDiagram(diagram);
}

function layerLabelTemplate(modelData: AllDiagramSettings, readonly?: boolean) {
  return $(
    go.Panel,
    'Vertical',
    {
      angle: 270,
      cursor: 'pointer',
      stretch: go.GraphObject.Fill,
      click(e, panel: any) {
        if (readonly) return;

        const grp = panel.part.findObject('GROUP');
        panel.diagram?.selectCollection(grp.memberParts);
      },

      toolTip: $('ToolTip',
        $(go.Panel, 'Vertical',
          $(
            go.TextBlock,
            {
              font: 'normal bold 18px DIN Next LT Pro',
              margin: 10,
            },
            new go.Binding('text', 'label', (l: string) => `The ${capitalizeAllWords(l)} Layer`),
          ),
          $(
            go.TextBlock,
            {
              font: 'normal 12px DIN Next LT Pro',
              margin: 10,
            },
            new go.Binding('text', '', (_, part) => layerUtils.getLayerDescription(part.part.key)),
          ))),
    },
    $(
      go.Panel,
      'Auto',
      {
        stretch: go.GraphObject.Fill,
        cursor: 'pointer',
      },
      $(
        go.TextBlock, // the lane label
        {
          textAlign: 'center',
          verticalAlignment: go.Spot.Center,
          editable: false,
          font: getFontString({
            fontSize: 12,
            fontWeight: FontWeight.NORMAL,
          }),
          name: 'LayerLabel',
          height: defaultLayerLabelHeight,
        },
        new go.Binding('text', 'label', (s: string) => s.toUpperCase()),
        new go.Binding('stroke', '', (_, part) => layerColor(part.part).textColor),
        new go.Binding('background', '', (_, part) => layerColor(part.part).backgroundColor),
      ),
    ),
  );
}

function layerPanelTemplate(modelData: AllDiagramSettings) {
  return $(
    go.Panel,
    'Auto', // the lane consisting of a background Shape and a Placeholder representing the subgraph
    { padding: 0, name: 'SHAPE' },
    new go.Binding('desiredSize', 'size', (size: NodeSize, panel: go.Panel) => toGoSize(layerSize(panel.part as go.Part))).makeTwoWay(toNodeSize),
    $(
      go.Panel,
      'Auto', // the lane consisting of a background Shape and a Placeholder representing the subgraph
      $(
        go.Shape,
        'Rectangle', // this is the resized object
        {
          fill: 'transparent',
          mouseEnter(e: go.InputEvent, obj: go.GraphObject) {
            if (e.diagram && (e.diagram as any)._addCallout) {
              obj.cursor = `url('${cursorAdd}'), auto`;
            } else {
              obj.cursor = 'pointer';
            }
          },
        },
        new go.Binding('stroke', '', (_, part) => layerColor(part.part).borderColor),
      ),
      $(go.Placeholder,
        {
          name: 'LanePlaceholder',
          alignment: go.Spot.TopLeft,
          padding: getLayerPadding(modelData.diagram),
        }),
    ),
  );
}

function layerTemplate(diagram: go.Diagram, modelData: AllDiagramSettings) {
  return $(
    go.Group,
    'Horizontal',
    commonGroupStyle(),
    {
      name: 'GROUP',
      contextMenu: diagram.contextMenu,
      // Important! - prevents dropping nodes outside a layer
      background: 'rgba(255, 255, 255, 0.1)',
      // selecting a lane causes the body of the lane to be highlighted, not the label
      selectionObjectName: 'SHAPE',
      resizable: true,
      // the custom resizeAdornmentTemplate only permits two kinds of resizing
      resizeObjectName: 'SHAPE',
      layout: $(
        go.LayeredDigraphLayout, // automatically lay out the lane's subgraph
        {
          isInitial: false, // don't even do initial layout
          isOngoing: false, // don't invalidate layout when nodes or links are added or removed
          direction: 90,
          columnSpacing: 0,
          layeringOption: go.LayeredDigraphLayout.LayerLongestPathSource,
          setsPortSpots: false,
          initializeOption: go.LayeredDigraphLayout.InitDepthFirstIn,
        },
      ),
      // needed to prevent recomputing Group.placeholder bounds too soon
      computesBoundsAfterDrag: true,
      // to reduce occurrences of links going briefly outside the lane
      computesBoundsIncludingLinks: false,
      // to support empty space at top-left corner of lane
      computesBoundsIncludingLocation: true,
      // don't need to define handlers on member Nodes and Links
      handlesDragDropForMembers: true,
      mouseDrop: async (e: go.InputEvent, group: go.GraphObject) => {
        await finishDrop(e, group as go.Group, modelData);
      },
      click(e, group) {
        if (group.diagram) {
          diagram.clearHighlighteds();
        }
      },
      resizeAdornmentTemplate: resizeAdornmentTemplate(),
      selectionAdornmentTemplate: $(
        go.Adornment,
        'Auto',
        $(
          go.Shape,
          'Rectangle',
          {
            fill: 'transparent',
            strokeDashArray: [modelData.diagram.gridWidth / 5],
            stroke: 'rgba(37, 174, 234, 0.3)',
          },
        ),
        $(go.Placeholder,
          {
            name: 'SelectionLanePlaceholder',
            padding: getLayerPadding(modelData.diagram, true),
          }),
      ),
    },
    new go.Binding('resizeCellSize', '', (m: AllDiagramSettings) => {
      const { gridWidth, gridHeight } = m.diagram;
      return new go.Size(gridWidth, gridHeight);
    }).ofModel(),
    // the lane header consisting of a Shape and a TextBlock
    $(
      go.Panel,
      'Horizontal',
      layerLabelTemplate(modelData),
      layerPanelTemplate(modelData),
    ), // end Horizontal Panel
  ); // end Group
}

function readonlyLayerTemplate(diagram: go.Diagram, modelData: AllDiagramSettings) {
  return $(
    go.Group,
    'Horizontal',
    commonGroupStyle(),
    {
      resizable: false,
      selectable: false,
      resizeObjectName: 'SHAPE',
      layout: $(
        go.LayeredDigraphLayout, // automatically lay out the lane's subgraph
        {
          isInitial: false, // don't even do initial layout
          isOngoing: false, // don't invalidate layout when nodes or links are added or removed
          direction: 90,
          columnSpacing: 0,
          layeringOption: go.LayeredDigraphLayout.LayerLongestPathSource,
          setsPortSpots: false,
          initializeOption: go.LayeredDigraphLayout.InitDepthFirstIn,
        },
      ),
      // needed to prevent recomputing Group.placeholder bounds too soon
      computesBoundsAfterDrag: true,
      // to reduce occurrences of links going briefly outside the lane
      computesBoundsIncludingLinks: false,
      // to support empty space at top-left corner of lane
      computesBoundsIncludingLocation: true,
      // don't need to define handlers on member Nodes and Links
      handlesDragDropForMembers: true,
    },
    // the lane header consisting of a Shape and a TextBlock
    $(
      go.Panel,
      'Horizontal',
      layerLabelTemplate(modelData),
      layerPanelTemplate(modelData),
    ), // end Horizontal Panel
  ); // end Group
}

const addTemplate: templateFn = (diagram: go.Diagram, options, modelData) => {
  let template;
  if (options.mode === DiagramMode.MODEL) {
    template = layerTemplate(diagram, modelData);
  } else {
    template = readonlyLayerTemplate(diagram, modelData);
  }
  diagram.groupTemplateMap.add(NodeCategory.LAYER, template);
};

export default {
  addTemplate,
};
