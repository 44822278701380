import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { loadDetails } from '@/store/storeHelpers';
import { RootState } from '@/store/rootState';
import { Storable } from '@/types/common';

// Any resource that doesn't have its own module
export type ActiveItemState<T = Record<string, any>> = Storable<T> & { type: string; };

type LoadDetailsParams = {
  url: string;
  force: false;
  type: string;
}

const activeItemState: ActiveItemState = {
  details: {},
  loaded: false,
  error: '',
  type: '',
};

const getters: GetterTree<ActiveItemState, RootState> = {
  details(state: ActiveItemState): ActiveItemState {
    return state;
  },
};

const actions: ActionTree<ActiveItemState, any> = {
  // eslint-disable-next-line max-len
  async loadDetails(context: ActionContext<ActiveItemState, RootState>, payload: LoadDetailsParams) {
    const storable = await loadDetails(context.state, payload.url, payload.force);
    context.commit('setDetails', { ...storable, type: payload.type });
  },

  clear(context: ActionContext<ActiveItemState, RootState>) {
    context.commit('clear');
  },
};

const mutations: MutationTree<ActiveItemState> = {
  setDetails(state: ActiveItemState, s: ActiveItemState) {
    state.details = s.details;
    state.loaded = s.loaded;
    state.error = s.error;
    state.type = s.type;
  },

  clear(state: ActiveItemState) {
    state.details = null;
    state.loaded = false;
    state.error = '';
    state.type = '';
  },
};

export default {
  namespaced: true,
  state: activeItemState,
  getters,
  actions,
  mutations,
};
