export enum DiagramMode {
  VIEW,
  MODEL,
  REVIEW,
}

export enum DiagramType {
  BIT = 'bit',
  DAV = 'dav',
}

export enum DiagramStatus {
  DRAFT = 'draft',
  APPROVED = 'approved',
  PUBLISHED = 'published',
}
