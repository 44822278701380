/* eslint-disable */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import appConfig from '@/app.config.json';
import tokenUtil from '@/plugins/auth/token';

export type ApiError = {
  message?: string;
  fieldsError?: boolean;
  fields?: { [key: string]: string };
  status?: number;
  statusText?: string;
};

const instance: AxiosInstance = axios.create({
  baseURL: appConfig.proxy,
  timeout: 10000,
});

export const genericApiError = 'There was an error, please try again later';

function resolveError(e: AxiosError): ApiError {
  if (!e) {
    return { message: genericApiError };
  }

  if (e.response && e.response.data) {
    const { field_errors, error, message } = e.response.data;

    if (field_errors) {
      const fields: { [k: string]: string } = {};
      Object.keys(field_errors).forEach(k => {
        fields[k] = field_errors[k];
      });

      return {
        fields,
        status: e.response.status,
        statusText: e.response.statusText,
      };
    }

    return {
      message: error || message || genericApiError,
      status: e.response.status,
      statusText: e.response.statusText,
    };
  }

  if (e.request && e.request.response) {
    return { message: e.request.response };
  }

  return { message: e.message };
}

instance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const headers = config.headers || {};
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...headers,
      Authorization: `Bearer ${tokenUtil.getToken()}`,
    };
    return config;
  },
  (error) => Promise.reject(error),
);

const ApiService = {
  instance,
  setHeader(token: string): void {
    instance.defaults.headers = {
      ...instance.defaults.headers,
      common: {
        Authorization: `Bearer ${token || tokenUtil.getToken()}`,
      }
    }
  },

  async get(url: string, params: any = {}): Promise<any> {
    try {
      const response: AxiosResponse = await instance.get(url, { params });
      return response.data;
    } catch (e) {
      throw resolveError(e as AxiosError);
    }
  },

  async post(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {},
  ): Promise<any> {
    try {
      const response: AxiosResponse = await instance.post(url, data, config);
      return response.data;
    } catch (e) {
      throw resolveError(e as AxiosError);
    }
  },

  async put(
    url: string,
    data: any = {},
    config: AxiosRequestConfig = {},
  ): Promise<any> {
    try {
      const response: AxiosResponse = await instance.put(url, data, config);
      return response.data;
    } catch (e) {
      throw resolveError(e as AxiosError);
    }
  },

  async delete(url: string, config: any = {}): Promise<any> {
    try {
      const response: AxiosResponse = await instance.delete(url, config);
      return response.data;
    } catch (e) {
      throw resolveError(e as AxiosError);
    }
  },
};

export default ApiService;
