import { DataForm } from '@/types/dataForm';
import { SecurityLevel } from '@/types';
// import { DiagramStatus } from '@/bridge/enums/diagramOptions';

export enum ResourceType {
  ASSET = 'asset',
  CONNECTION = 'connection',
  DEPENDENCY = 'dependency',
}

export interface AssetResourceRequest {
  type: ResourceType;
  label: string;
  description: string;
  layer: number;
}

export interface ConnectorResourceRequest {
  type: ResourceType;
  // status: DiagramStatus;
  label: string;
  description: string;
  from: string;
  to: string;
}

export interface Resource {
  id: string;
  type: ResourceType;
  projectId: string;
  label: string;
  description: string;
  usedByTotal: number;
  importLabel: string;
  deletable: boolean;
  maxSecurityLevel: SecurityLevel;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
}

export interface AssetResource extends Resource {
  layer: number;
}

export interface ConnectorResource extends Resource {
  from: AssetResource;
  to: AssetResource;
}

export type ResourceWithData = {
  asset: Resource;
  modellingState: {
    id: string;
    label: string;
    description: string;
  }
  forms: DataForm[];
}

export type AddResourceData = {
  asset: Resource;
  forms: Record<string, DataForm>;
  modellingStateId: string;
  remove: string[];
}
