<template>
  <q-expansion-item
    v-model="expanded"
    class="rounded-borders overflow-hidden card-shadow"
    header-class="bg-white q-pa-md"
    switch-toggle-side
    :expand-separator="false"
    expand-icon="o_expand_circle_down"
  >
    <template #header>
      <q-item-section  v-if="!!$slots.avatar" side class="q-pr-sm">
        <slot name="avatar"></slot>
      </q-item-section>

      <q-item-section class="text-h4">{{ details.label }}</q-item-section>

      <q-item-section v-if="!!$slots.header" side>
        <div>
          <slot name="header"></slot>
        </div>
      </q-item-section>
    </template>

    <q-separator inset/>
    <q-card flat>
      <q-card-section>
        <slot></slot>
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<script>
export default {
  name: 'ExpandableListItem',
  props: {
    details: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
};
</script>
