export enum FontStyle {
  NORMAL = 'normal',
  ITALIC = 'italic',
}
export enum FontWeight {
  NORMAL = 'normal',
  BOLD = 'bold',
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

// go.Spot
export enum VerticalAlignment {
  TOP = 'Top',
  CENTER = 'Center',
  BOTTOM = 'Bottom',
}
