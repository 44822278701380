export enum Roles {
  OWNER = 0,
  ADMIN,
  PRODUCT_OWNER,
  PROJECT_MANAGER,
  CONTRIBUTOR,
  VIEWER,
  GUEST
}
export enum SecurityLevel {
  None = 0,
  Low,
  Medium,
  High,
}
