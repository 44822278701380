import {
  isValid,
  format,
  parse,
  add,
  addMilliseconds,
} from 'date-fns';

const titleDateString = 'dd_MM_yyyy';
const dateString = 'dd/MM/yyyy';
const datetimeString = 'dd/MM/yyyy HH:mm';

export function formatDate(timestamp: number, inTitle?: boolean): string {
  if (!isValid(timestamp)) {
    return '';
  }

  return format(timestamp, inTitle ? titleDateString : dateString);
}

export function formatDatetime(timestamp: number): string {
  if (!isValid(timestamp)) {
    return '';
  }

  return format(timestamp, datetimeString);
}

export function getTimestampFromDateString(date: string, addHours?: boolean): number {
  const d = parse(date, dateString, new Date());

  if (addHours) {
    // one millisecond before midnight
    const endOfDay = addMilliseconds(add(d, {
      hours: 23,
      minutes: 59,
      seconds: 59,
    }), 999);

    return new Date(endOfDay).getTime();
  }
  return new Date(d).getTime();
}
