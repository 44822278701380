<template>
  <a
    href="#"
    @click.prevent="() => false"
    class="avatar-wrapper relative-position block"
    v-if="showEdit"
  >
    <AppAvatar
      :size="size"
      :avatar="details.avatar"
      :label="details.label"
      class="bordered overflow-hidden avatar-wrapper--avatar block q-mx-auto"
    />

    <div class="avatar-wrapper--controls absolute-full flex flex-center">
      <AvatarUpload :url="url" @submit="$emit('change')"/>
      <AvatarDelete v-if="details.avatar" :url="url" @submit="$emit('change')"/>
    </div>
  </a>

  <AppAvatar
    v-else
    :size="size"
    :avatar="details.avatar"
    :label="details.label"
    class="bordered overflow-hidden avatar-wrapper--avatar block q-mx-auto"
  />
</template>

<script>
import AvatarUpload from '@/components/avatar/AvatarUpload.vue';
import AvatarDelete from '@/components/avatar/AvatarDelete.vue';
import AppAvatar from '@/components/avatar/Avatar.vue';

export default {
  name: 'AvatarWithControls',
  components: {
    AppAvatar,
    AvatarDelete,
    AvatarUpload,
  },
  props: {
    showEdit: Boolean,
    size: {
      type: String,
      default: '25px',
    },
    url: {
      type: String,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.avatar-wrapper {
  &--controls {
    opacity: 0;
    z-index: -1;
    transition: all 0.2s ease-in;
    flex-direction: column;
  }

  &--avatar {
    transition: all 0.3s ease-in-out;
  }

  &:focus,
  &:hover {
    outline: none;

    .avatar-wrapper--controls {
      opacity: 1;
      z-index: 1;
    }

    .avatar-wrapper--avatar {
      opacity: 0.3;
    }
  }
}
</style>
