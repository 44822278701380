import UserLayout from '@/layouts/UserLayout.vue';

export default [
  {
    path: '/manage/:orgId',
    component: UserLayout,
    meta: { auth: true },
    children: [
      {
        path: 'logs',
        component: () => import(/* webpackChunkName: "list-org-users" */ '@/views/admin/org/audit/ListLogins.vue'),
      },
      {
        path: 'audit',
        component: () => import(/* webpackChunkName: "list-org-users" */ '@/views/admin/org/audit/ListAudits.vue'),
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "list-org-users" */ '@/views/admin/org/users/ListUsers.vue'),
      },
      {
        path: 'users/:id',
        component: () => import(/* webpackChunkName: "list-org-users" */ '@/views/admin/org/users/ViewUser.vue'),
      },
      {
        path: 'roles',
        component: () => import(/* webpackChunkName: "list-org-roles" */ '@/views/admin/org/roles/ListRoles.vue'),
      },
      {
        path: 'divisions',
        component: () => import(/* webpackChunkName: "list-org-divisions" */ '@/views/admin/org/divisions/ListDivisions.vue'),
      },
      {
        path: 'divisions/:id',
        component: () => import(/* webpackChunkName: "view-org-division" */ '@/views/admin/org/divisions/ViewDivision.vue'),
      },
    ],
  },
];
