<template>
  <main-layout>
    <template #drawers>
      <q-drawer v-model="left" side="left" bordered>
        <UserLeftDrawer />
      </q-drawer>
    </template>
  </main-layout>
</template>

<script>
import UserLeftDrawer from '@/layouts/drawers/UserLeftDrawer.vue';
import MainLayout from '@/layouts/main/MainLayout.vue';

export default {
  name: 'UserLayout',
  components: {
    MainLayout,
    UserLeftDrawer,
  },
  data() {
    return {
      left: false,
    };
  },
};
</script>
