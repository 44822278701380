export const TOKEN_KEY = 'token';

export type JWTClaims = {
  iss: string;
  aud: string;
  exp: number;
};

const defaultClaims = {
  iss: 'OBASHI',
  aud: '',
  exp: 0,
};

// Utilities for managing the token
const tokenUtil = {
  save(token: string): void {
    localStorage.setItem(TOKEN_KEY, token);
  },
  remove(): void {
    localStorage.removeItem(TOKEN_KEY);
  },
  getToken(): string {
    return localStorage.getItem(TOKEN_KEY) || '';
  },
  isValid(token: string): boolean {
    const payload: JWTClaims | null = this.decode(token);
    if (!payload) {
      return false;
    }

    const { exp } = payload;

    // Token expired
    if (Number.isNaN(+exp) || exp * 1000 < new Date().getTime()) {
      return false;
    }

    // Issuer claim is not valid
    if (payload.iss !== defaultClaims.iss) {
      return false;
    }

    // Everything is OK! 🎉
    return true;
  },
  decode(token: string): JWTClaims | null {
    if (!token) {
      return null;
    }

    try {
      // A JWt should have the following format Header.Payload.Signature
      // Here we try to get the Payload from thw JWT string
      const b64Payload = token.split('.')[1];

      // Decode the base64 string and parse the JSON
      // If either of these fail, the catch block will
      // be executed
      return JSON.parse(atob(b64Payload));
    } catch {
      return null;
    }
  },
};

export default tokenUtil;
