<template>
  <q-page class="page">

    <div :class="pagePadding" class="page--container">
      <Breadcrumbs
        v-if="breadcrumbs.length"
        :breadcrumbs="breadcrumbs"
        class="q-mb-md"
      />
      <slot name="pageHeader">
        <header class="flex">
          <div class="page-header-left">
            <h5 class="test--page-title q-mt-none q-mb-md" v-if="title">{{ title }}</h5>
            <p v-if="description" class="test--page-description text-subtitle2">
              {{ description }}
            </p>
          </div>
          <q-space v-if="!!$slots.pageActions"/>
          <slot name="pageActions"></slot>
        </header>
      </slot>

      <div v-if="loading" class="text-center">
        <h3 class="text-dark-grey">Loading</h3>
        <q-spinner-cube size="1.5rem" color="primary" />
      </div>

      <div v-else-if="hasError">
        <p class="text-negative">Something went wrong. Please try again later.</p>
      </div>

      <slot v-else></slot>
    </div>
  </q-page>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';
import notify from '@/util/notify';

export default {
  name: 'Page',
  components: { Breadcrumbs },
  props: {
    noPagePadding: Boolean,
    url: String,
    title: String,
    description: {
      type: String,
      required: false,
      default: '',
    },
    hasError: Boolean,
    loading: Boolean,
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    pagePadding() {
      if (this.noPagePadding) {
        return '';
      }
      return `${this.$q.screen.lt.md ? 'q-pa-sm' : 'q-pa-lg'}`;
    },
  },
  async created() {
    if (this.$route.params.orgId && !this.$store.getters['orgModule/details'].id) {
      await this.$router.push('/');
      notify.danger('Could not load the organisation\'s details');
    }
  },
};
</script>

<style>
.page--container {
  max-width: 80vw;
  margin: 0 auto;
}
</style>
