import * as go from 'gojs';
import { NodeCategory } from '@/bridge/enums/partCategories';

// this controls the minimum length of any layer
export const MinLength = 1500;
// this controls the minimum breadth of any non-collapsed layer
export const MinBreadth = 140;

// compute the minimum size of a Pool Group needed to hold all the Lane Groups
export function computeMinPoolSize(pool: go.Group) {
  let len = MinLength;
  pool.memberParts.each((lane) => {
    // pools ought to only contain lanes, not plain Nodes
    if (!(lane instanceof go.Group)) return;
    const holder = lane.placeholder;
    if (holder !== null) {
      const sz = holder.actualBounds;
      len = Math.max(len, sz.width);
    }
  });
  return new go.Size(len, NaN);
}

// determine the minimum size of a Lane Group, even if collapsed
export function computeMinLaneSize(lane: go.Group) {
  if (!lane.isSubGraphExpanded) return new go.Size(MinLength, 1);
  return new go.Size(MinLength, MinBreadth);
}

// compute the minimum size for a particular Lane Group
export function computeLaneSize(lane: go.Group) {
  const sz = computeMinLaneSize(lane);
  if (lane.isSubGraphExpanded) {
    const holder = lane.placeholder;
    if (holder !== null) {
      const hsz = holder.actualBounds;
      sz.height = Math.max(sz.height, hsz.height);
    }
  }
  // minimum height needs to be big enough to hold the header
  const hdr = lane.findObject('HEADER');
  if (hdr !== null) sz.height = Math.max(sz.height, hdr.actualBounds.height);
  return sz;
}

const { isNaN } = Number;

// define a custom grid layout that makes sure the length of each lane is the same
// and that each lane is broad enough to hold its subgraph
class PoolLayout extends go.GridLayout {
  cellSize: go.Size;

  wrappingColumn: number;

  wrappingWidth: number;

  isRealtime: boolean; // don't continuously lay out while dragging

  alignment: go.EnumValue;

  constructor() {
    super();

    this.cellSize = new go.Size(1, 1);
    this.wrappingColumn = 1;
    this.wrappingWidth = Infinity;
    this.isRealtime = false; // don't continuously lay out while dragging
    this.alignment = go.GridLayout.Position;
  }

  /** @override */
  doLayout(coll: go.Diagram | go.Group | go.Iterable<go.Part>) {
    const { diagram } = this;
    if (diagram === null) return;

    diagram.startTransaction('PoolLayout');
    const pool = this.group;
    if (pool !== null && pool.category === NodeCategory.ROOT) {
      // make sure all the Group Shapes are big enough
      const minSize = computeMinPoolSize(pool);

      pool.memberParts.each((lane) => {
        if (!(lane instanceof go.Group)) return;

        if (lane.category !== NodeCategory.ROOT) {
          const shape = lane.resizeObject;

          if (shape !== null) {
            // change the desiredSize to be big enough in both directions
            const sz = computeLaneSize(lane);

            shape.width = isNaN(shape.width) ? minSize.width : Math.max(shape.width, minSize.width);
            shape.height = !isNaN(shape.height) ? Math.max(shape.height, sz.height) : sz.height;
            const cell = lane.resizeCellSize;
            if (!isNaN(shape.width) && !isNaN(cell.width) && cell.width > 0) {
              shape.width = Math.ceil(shape.width / cell.width) * cell.width;
            }
            if (!isNaN(shape.height) && !isNaN(cell.height) && cell.height > 0) {
              shape.height = Math.ceil(shape.height / cell.height) * cell.height;
            }
          }
        }
      });
    }
    // now do all the usual stuff,
    // according to whatever properties have been set on this GridLayout
    go.GridLayout.prototype.doLayout.call(this, coll);
    diagram.commitTransaction('PoolLayout');
  }
}

export default PoolLayout;
