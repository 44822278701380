<template>
  <q-layout view="lhh lpr lff">
    <q-page-container>
      <router-view :key="$route.fullPath"/>
    </q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'ModelDiagramLayout',
};
</script>
