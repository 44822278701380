<template>
  <q-breadcrumbs class="breadcrumbs bg-white rounded-borders bordered q-px-md q-py-sm">
    <template v-slot:separator>
      <q-icon
        size="0.9rem"
        name="chevron_right"
        color="primary"
      />
    </template>

    <q-breadcrumbs-el
      v-for="(route, i) in routes"
      :key="i"
      exact
      :to="route.url"
      :label="route.label"
      active-class="text-primary"
    />
  </q-breadcrumbs>
</template>

<script>
export default {
  name: 'Breadcrumbs',
  props: {
    dark: Boolean,
    withSeparator: {
      type: Boolean,
      default: true,
    },
    backgroundColor: {
      type: String,
      default: 'bg-white',
    },
    breadcrumbs: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    routes() {
      const url = this.$route.path;
      let params = '';
      let query = '';

      const routes = [
        {
          url: '/',
          label: 'Home',
        },
      ];

      if (this.breadcrumbs.length) {
        routes.push(...this.breadcrumbs);
      } else {
        Object.keys(this.$route.params).forEach((param) => {
          params = `${params}/${this.$route.params[param]}`;

          if (!query) {
            query = `?${param}=${this.$route.query[param]}`;
          } else {
            query = `${query}&${param}=${this.$route.query[param]}`;
          }

          routes.push({
            url: `${url}${params}${query}`,
            label: this.$route.query[param],
          });
        });
      }

      return routes;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~quasar-variables";

.breadcrumbs {
  a {
    font-weight: bold;
  }

  span {
    font-style: italic;
    opacity: 0.8;
  }
}
</style>
