import { AxiosRequestConfig } from 'axios';
import ApiService from '@/services/api.service';
import { PhotoEditorModel } from '@/components/photoEditor/editorUtils';
import { FileMetadata } from '@/util/fileIcons';

export async function uploadImage(model: PhotoEditorModel, url: string): Promise<void> {
  if (!model.blob) {
    return Promise.reject(new Error('No image'));
  }

  const formData = new FormData();
  formData.append('image', model.blob, model.name);

  return ApiService.put(url, formData);
}

/**
 * Uploads a files to an endpoint given by the url
 * @param name, for the form data file name, ex: assets, files
 * @param method, put or post
 * @param file blob to update
 * @param data metadata to stringify and append to the payload
 * @param url the endpoint to put to
 * @param callback the callback function to call, used to progress the upload bar
 */
export async function uploadFile(
  name: string,
  method: string,
  file: Blob,
  data: FileMetadata | null,
  url: string,
  callback: (event: any) => any,
): Promise<any[]> {
  const formData = new FormData();

  formData.append(name, file);
  if (data) {
    formData.append('data', JSON.stringify([data]));
  }

  const config: AxiosRequestConfig = {
    onUploadProgress: async (progressEvent: any) => {
      await callback(progressEvent);
    },
    timeout: 50000, // too generous?
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  };

  return method === 'post' ? ApiService.post(url, formData, config) : ApiService.put(url, formData, config);
}
