<template>
  <q-input
    v-bind="$attrs"
    type="number"
    dense
    outlined
    no-error-icon
    :value="value"
    :error="!!errorMessage"
    :error-message="errorMessage"
    :rules="[ validation ]"
    :lazy-rules="lazyRules"
    @input="onInput"
  />
</template>

<script>
import Numeric from '@/validation/numeric';

export default {
  name: 'FieldNumeric',
  props: {
    lazyRules: {
      type: [String, Boolean],
      default: 'ondemand',
    },
    value: {
      type: [String, Number],
      default: null,
      validator: (p) => ['string', 'number'].indexOf(typeof p) !== -1 || p === null,
    },
    errorMessage: String,
    required: Boolean,
    decimalPlaces: {
      type: [String, Number],
      default: null,
      validator: (p) => ['string', 'number'].indexOf(typeof p) !== -1 || p === null,
    },
    minValue: {
      type: [String, Number],
      default: Number.MIN_SAFE_INTEGER,
    },
    maxValue: {
      type: [String, Number],
      default: Number.MAX_SAFE_INTEGER,
    },
  },
  data() {
    return {
      fieldValidation: {},
    };
  },
  watch: {
    minValue(newVal) {
      this.fieldValidation.minValue = newVal;
    },
    maxValue(newVal) {
      this.fieldValidation.maxValue = newVal;
    },
  },
  methods: {
    onInput(e) {
      const value = parseFloat(e);

      if (Number.isNaN(value)) {
        this.$emit('input', null);
        return;
      }
      this.$emit('input', value);
    },
    validation(text) {
      if (this.required) {
        return this.fieldValidation.isRequired(text);
      }
      return this.fieldValidation.isValid(text);
    },
  },
  created() {
    let name;
    if (this.$attrs.label) {
      name = this.$attrs.label;
    }
    this.fieldValidation = new Numeric({
      name,
      minValue: parseInt(this.minValue, 10),
      maxValue: parseInt(this.maxValue, 10),
    });
  },
};
</script>
