import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { Roles } from '@/types';
import authGuard from '@/router/guards/authGuard';
import UserLayout from '@/layouts/UserLayout.vue';
import Home from '@/views/Home.vue';
import ModelDiagramLayout from '@/layouts/ModelDiagramLayout.vue';
import ErrorLayout from '@/layouts/ErrorLayout.vue';
import auth from '@/router/auth';
import profile from '@/router/profile';
import org from '@/router/org/org';
import { modelBit } from '@/router/projectContent/diagrams';
import adminOrg from '@/router/org/adminOrg';
import $store from '@/store';

Vue.use(VueRouter);

/**
 * checks if the logged-in user is the required role
 * To be used in v-ifs
 * @param requiredRole Roles must be equal
 */
Vue.prototype.$isOrgRole = (requiredRole: Roles) => {
  const role = $store.getters['orgModule/userRole'];
  return role === requiredRole;
};

/**
 * checks if the logged-in user has the permissions to access a certain org level component
 * To be used in v-ifs
 * @param minimumRequiredRole Roles must be contained
 */
Vue.prototype.$hasOrgRole = (minimumRequiredRole: Roles) => {
  const role = $store.getters['orgModule/userRole'];
  return role <= minimumRequiredRole;
};

/**
 * checks if the logged-in user has the permissions to access a certain project level component
 * Certain high level roles can simply do project permissions, otherwise we need
 * to check the user's role within the individual project
 * To be used in v-ifs
 * @param minimumRequiredRole Roles must be contained
 */
Vue.prototype.$hasProjectRole = (minimumRequiredRole: Roles) => {
  const role = $store.getters['orgModule/userRole'];
  return role <= Roles.PRODUCT_OWNER || $store.getters['projectModule/userRole'] <= minimumRequiredRole;
};

Vue.prototype.$Roles = Roles;

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: UserLayout,
    meta: { auth: true },
    children: [
      {
        path: '',
        component: Home,
      },
    ],
  },
  ...adminOrg,
  ...auth,
  {
    path: '',
    component: UserLayout,
    children: [
      ...profile,
      ...org,
    ],
  },
  {
    path: '/org/:orgId/project/:projectId',
    component: ModelDiagramLayout,
    children: [
      ...modelBit,
    ],
  },
  {
    path: '/404',
    component: ErrorLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "login" */ '@/views/error/404.vue'),
      },
    ],
  },
  { path: '*', redirect: '/404' },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    const ignoreScrollPosition = to.matched.some((r) => r.meta.ignoreScrollPosition);
    if (ignoreScrollPosition) {
      return null;
    }

    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => authGuard(to, from, next));

export default router;
