import ApiService from '@/services/api.service';
import { Storable } from '@/types/common';

export async function loadDetails<T>(
  state: Storable<T>,
  url: string,
  force = false,
): Promise<Storable<T>> {
  // Avoid over-fetching
  if (state.loaded && !force) {
    return state;
  }

  const storable: Storable<T> = {
    details: null,
    loaded: false,
    error: '',
  };

  try {
    storable.details = await ApiService.get(url);

    storable.loaded = true;
    storable.error = '';
  } catch (e) {
    storable.loaded = false;
    storable.error = 'Error while loading. Please try again later';
  }

  return storable;
}
