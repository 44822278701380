



















































import Vue from 'vue';
import AppAvatar from '@/components/avatar/Avatar.vue';
import { UserDetails } from '@/types/profile';

export default Vue.extend({
  name: 'ToolbarUserMenu',
  components: { AppAvatar },
  computed: {
    displayName(): string {
      return this.user.displayName || `${this.user.forename} ${this.user.surname}`;
    },
    user(): UserDetails {
      return this.$store.getters['profileModule/user'];
    },
  },
  data() {
    return {
      iconName: 'arrow_left',
      darkMode: localStorage.getItem('dm') || false,
    };
  },
  methods: {
    toggleDarkMode(v: boolean) {
      this.darkMode = v;
      this.$q.dark.set(this.darkMode);
      localStorage.setItem('dm', this.darkMode.toString());
    },
    setIcon(val: boolean) {
      if (val) {
        this.iconName = 'arrow_drop_down';
      } else {
        this.iconName = 'arrow_left';
      }
    },
  },
});
