<template>
  <div class="bg-light-blue-1 text-light-blue-9 text-center q-pa-sm">
    <q-icon name="info" size="1.3rem"/>
    <small>
      {{ message }}
    </small>
  </div>
</template>

<script>
export default {
  name: 'InfoBanner',
  props: {
    message: {
      type: String,
      required: true,
    },
  },
};
</script>
