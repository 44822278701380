<template>
  <InfoBanner
    message="Your profile information will be visible to everyone on the Obashi Platform"
  />
</template>

<script>
import InfoBanner from '@/components/banners/InfoBanner.vue';

export default {
  name: 'EditInfoBanner',
  components: { InfoBanner },
};
</script>
