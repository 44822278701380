













































import Vue from 'vue';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';

export default Vue.extend({
  name: 'DeletePreset',
  components: {
    Btn,
    AppModal,
  },
  data() {
    return {
      confirmDelete: false,
    };
  },
  methods: {
    open() {
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      this.$emit('delete');
      if (this.$refs.modal) {
        (this.$refs.modal as any).close();
      }
    },
  },
});
