<template>
  <q-header :class="toolbarColor" bordered class="main-toolbar" reveal>
    <q-toolbar :class="`${$q.screen.lt.md ? 'q-px-sm' : 'q-px-lg'}`">
      <slot name="left"></slot>
      <router-link to="/">
        <img
          alt="OBASHI Platform"
          class="q-mt-sm main-toolbar-logo"
          src="@/assets/images/logo-light.png"
        />
      </router-link>
      <q-space/>
      <ToolbarUserMenu/>
      <slot name="right"></slot>
    </q-toolbar>
  </q-header>
</template>

<script>
import ToolbarUserMenu from '@/layouts/toolbar/ToolbarUserMenu.vue';

export default {
  name: 'Toolbar',
  components: { ToolbarUserMenu },
  props: {
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    toolbarColor() {
      if (this.color) {
        return `bg-${this.color}`;
      }
      return this.$q.dark.isActive ? 'bg-dark' : 'bg-white';
    },
  },
};
</script>

<style lang="scss">
.main-toolbar {
  .main-toolbar-logo {
    max-width: 100px;
    height: auto;
  }
}
</style>
