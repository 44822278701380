


























import Vue, { VueConstructor } from 'vue';
import AppModal from '@/components/AppModal.vue';
import ApiError from '@/components/ApiError.vue';
import Btn from '@/components/buttons/Btn.vue';
import notify from '@/util/notify';
import { FormWrapperRequest } from '@/types/form';
import formSubmit from '@/mixins/formSubmit';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'AvatarDelete',
  components: {
    Btn,
    ApiError,
    AppModal,
  },
  mixins: [formSubmit],
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    request(): FormWrapperRequest {
      return {
        config: {
          method: 'delete',
          url: this.url,
        },
      };
    },
  },
  methods: {
    async onSubmit() {
      await this.submit(this.request);

      if (this.hasError) {
        return;
      }

      (this.$refs.modal as any).close();
      notify.success('Image deleted');
      this.$emit('submit');
    },
  },
});
