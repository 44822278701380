<template>
  <q-list>
    <q-item v-ripple to="/" clickable exact>
      <q-item-section avatar>
        <q-icon name="dashboard" />
      </q-item-section>
      <q-item-section>Dashboard</q-item-section>
    </q-item>
  </q-list>
</template>

<script>
export default {
  name: 'UserLeftDrawer',
};
</script>
