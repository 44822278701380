import { Validation } from '@/validation/validation';

class Code extends Validation {
  public readonly maxLength = 15;
  public name = 'Code';

  public readonly isValidError = 'Only letters, numbers, dots, underscores, and dashes are allowed';

  get maxLengthError() {
    return `${this.name} must be less than ${this.maxLength} characters`;
  }

  get isRequiredError() {
    return `${this.name} is required`;
  }

  isValid(text: string): boolean | string {
    if (!text) {
      return true;
    }

    if (text.length > this.maxLength) {
      return this.maxLengthError;
    }

    if (!/^[\w\d\-_.]+$/g.test(text)) {
      return this.isValidError;
    }

    return true;
  }
}

export default new Code();
