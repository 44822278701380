






















































import Vue, { VueConstructor } from 'vue';
import ApiService from '@/services/api.service';
import formSubmit from '@/mixins/formSubmit';
import { settingsService } from '@/services/preferencesService';
import { getDefaultModelData } from '@/bridge/settings/modelData';
import { FormWrapperRequest } from '@/types/form';
import { AllDiagramSettings as DiagramSettings, NodePreset } from '@/bridge/types/diagramModel';
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';
import AllDiagramSettings from '@/components/diagram/AllDiagramSettings.vue';
import ListNodePresets from '@/components/diagram/presets/ListNodePresets.vue';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ProjectSettingsForm',
  components: {
    ListNodePresets,
    Btn,
    AppModal,
    ApiError,
    AllDiagramSettings,
  },
  mixins: [formSubmit],
  computed: {
    projectId(): string {
      return this.$route.params.projectId;
    },
    request(): FormWrapperRequest {
      return {
        config: {
          data: {
            preferences: {
              canvas: this.model,
            },
          },
          method: 'put',
          url: `/project/${this.projectId}/preferences`,
        },
      };
    },
  },
  data() {
    return {
      tab: 'allSettings',
      model: {} as DiagramSettings,
      prefService: {} as ReturnType<typeof settingsService>,
    };
  },
  methods: {
    async open() {
      await this.setDefaultModel();
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      this.error = '';

      this.prefService.updateVersion(this.model);

      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }
      this.$emit('submit');
      (this.$refs.modal as any).close();
      await this.$store.dispatch('projectModule/loadPreferences', { id: this.projectId, force: true });
      this.notify('Project Preferences Updated');
    },
    async setDefaultModel() {
      const resp = await ApiService.get(`/project/${this.$route.params.projectId}/preferences`);

      this.model = resp.preferences.canvas;
      this.prefService = settingsService(resp.preferences.canvas);
    },
    onClose() {
      this.model = {} as DiagramSettings;
      this.prefService = {} as ReturnType<typeof settingsService>;
    },
    updatePreferences(e: DiagramSettings) {
      this.model = e;
    },
    updatePresets(e: Record<string, NodePreset>) {
      this.model.nodePresets = e;
    },
    resetSettings() {
      const m: DiagramSettings = getDefaultModelData();

      m.nextNodeId = this.model.nextNodeId;
      m.nextLinkId = this.model.nextLinkId;
      m.meta = this.model.meta;

      this.model = { ...m, nodePresets: this.model.nodePresets };
      this.onSubmit();
    },
  },
});
