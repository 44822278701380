import AuthLayout from '@/layouts/AuthLayout.vue';

export default [
  {
    path: '/login',
    component: AuthLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "login" */ '@/views/auth/Login.vue'),
        meta: { guest: true },
      },
    ],
  },
  {
    path: '/logout',
    component: AuthLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "logout" */ '@/views/auth/Logout.vue'),
        meta: { auth: true },
      },
    ],
  },
  {
    path: '/recover',
    component: AuthLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "recover" */ '@/views/auth/recover/RecoverRequest.vue'),
        meta: { guest: true },
      },
    ],
  },
  {
    path: '/email-sent',
    component: AuthLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "recover" */ '@/views/auth/recover/RecoverEmailSent.vue'),
        meta: { guest: true },
      },
    ],
  },
  {
    path: '/password/recover/:id/:code',
    component: AuthLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "recover_set_new" */ '@/views/auth/recover/RecoverSetNew.vue'),
        meta: { guest: true },
      },
    ],
  },
  {
    path: '/invite/:id/:action',
    component: AuthLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "recover_set_new" */ '@/views/auth/InvitationAction.vue'),
        meta: { guest: true },
      },
    ],
  },
  {
    path: '/register/:invitationId',
    component: AuthLayout,
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "recover_set_new" */ '@/views/auth/Register.vue'),
        meta: { guest: true },
      },
    ],
  },
];
