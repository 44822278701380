import { Validation } from '@/validation/validation';

export const labelMaxLen = 250;

export type LabelOptions = {
  name: string
  minLen: number
  maxLen: number
}

const defaultOptions: LabelOptions = {
  name: 'Label',
  minLen: 0,
  maxLen: labelMaxLen,
};

class Label extends Validation {
  maxLen = labelMaxLen;
  minLen = 0;
  name = '';

  constructor(options: Partial<LabelOptions> = defaultOptions) {
    super();

    if (options.name) {
      this.name = options.name;
    }

    if (options.minLen) {
      this.minLen = options.minLen;
    }

    if (options.maxLen) {
      this.maxLen = options.maxLen;
    }
  }

  get isValidError() {
    return `${this.name} must be between ${this.minLen} and ${this.maxLen} characters`;
  }

  get isRequiredError() {
    return `${this.name} is required`;
  }

  isValid(text: string): boolean | string {
    if (!text) {
      return true;
    }

    const len = text.length;

    if (len < this.minLen || len > this.maxLen) {
      return this.isValidError;
    }
    return true;
  }
}

export default Label;
