













































































import Vue, { PropType } from 'vue';
import { NodePreset } from '@/bridge/types/diagramModel';
import { VerticalAlignment } from '@/bridge/enums/textStyle';
import DeletePreset from '@/components/diagram/presets/DeletePreset.vue';
import PresetModal from '@/components/diagram/presets/PresetModal.vue';
import { TableColumn } from '@/types/list';

function getVerticalAlignment(va: VerticalAlignment) {
  if (va === VerticalAlignment.TOP) return 'start';
  if (va === VerticalAlignment.CENTER) return 'center';
  return 'end';
}

export default Vue.extend({
  name: 'ListNodePresets',
  components: { PresetModal, DeletePreset },
  props: {
    selectMode: Boolean,
    value: {
      type: Object as PropType<Record<string, NodePreset>>,
      required: false,
    },
  },
  computed: {
    rows(): NodePreset[] {
      if (!this.value) return [];

      return Object.values(this.value).map((v: NodePreset) => ({
        ...v,
        preview: v.id,
        edit: v.id,
      }));
    },
    presetLabels(): string[] {
      return this.rows.map((r: NodePreset) => r.label);
    },
    columns(): TableColumn[] {
      const cols = [
        {
          name: 'label',
          label: 'Label',
          field: 'label',
          align: 'left',
        },
        {
          name: 'showOnLegend',
          label: 'Show on legend',
          field: 'showOnLegend',
          align: 'center',
        },
        {
          name: 'preview',
          label: 'Preview',
          field: 'preview',
          align: 'center',
        },
      ];

      if (this.selectMode) {
        return cols;
      }

      cols.push(
        {
          name: 'edit',
          label: 'Edit',
          field: 'edit',
          align: 'center',
        },
        {
          name: 'delete',
          label: 'Delete',
          field: 'delete',
          align: 'center',
        },
      );

      return cols;
    },
  },
  data() {
    return {
      model: { ...this.value },
    };
  },
  methods: {
    onRowClick(row: Record<string, any>) {
      if (this.selectMode) {
        this.$emit('rowClick', row);
      }
    },
    emitPreset(preset: NodePreset) {
      this.$emit('input', {
        ...this.value,
        [preset.id]: preset,
      });
    },
    deletePreset(preset: NodePreset) {
      const presets = { ...this.value };
      delete presets[preset.id];
      this.$emit('delete', {
        presets,
        id: preset.id,
      });
    },
    getPreviewStyle(preset: NodePreset) {
      let tmp = {};
      if (preset.color) {
        tmp = {
          color: preset.color?.textColor,
          backgroundColor: preset.color.backgroundColor,
          border: `2px solid ${preset.color.borderColor}`,
        };
      } else {
        tmp = {
          backgroundColor: '#fcfcfc',
          border: '2px solid #f5f5f5',
        };
      }
      if (preset.textStyle) {
        tmp = {
          ...tmp,
          lineHeight: 1,
          height: `${3 * preset.textStyle.fontSize}px`,
          fontStyle: preset.textStyle.fontStyle,
          fontWeight: preset.textStyle.fontWeight,
          fontSize: `${preset.textStyle.fontSize}px`,
          fontFamily: `${preset.textStyle.fontFamily}`,
          textAlign: preset.textStyle.textAlign,
          textDecoration: preset.textStyle.isUnderline ? 'underline' : 'none',
          display: 'flex',
          alignItems: getVerticalAlignment(preset.textStyle.verticalAlignment),
        };
      }
      return tmp;
    },
  },
});
