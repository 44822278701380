import * as go from 'gojs';
import { ElementSettings, NodeColor, ElementData } from '@/bridge/types/diagramModel';
import { FontStyle, FontWeight, TextAlign, VerticalAlignment } from '@/bridge/enums/textStyle';
import { getPartModelData } from '@/bridge/settings/common';

export const defaultElementSize = {
  width: 120,
  height: 40,
};

export function getDefaultElementSettings(): ElementSettings {
  return {
    label: '',
    size: defaultElementSize,
    color: {
      backgroundColor: 'rgb(222,222,222)',
      borderColor: 'rgba(0, 0, 0, 1)',
      textColor: 'rgba(0, 0, 0, 1)',
    },
    textStyle: {
      fontSize: 16,
      fontWeight: FontWeight.NORMAL,
      fontStyle: FontStyle.NORMAL,
      fontFamily: 'DIN Next LT Pro',
      verticalAlignment: VerticalAlignment.CENTER,
      textAlign: TextAlign.CENTER,
      isUnderline: false,
    },
  };
}

export function elementColor(part: go.Part): NodeColor {
  const defaultColor = getDefaultElementSettings().color;
  const { data }: { data: ElementData } = part;

  // An element could have custom text settings and no colour
  if (data.settings && data.settings.color) {
    return data.settings.color;
  }

  const md = getPartModelData(part);

  if (!md) return defaultColor;

  if (data.preset) {
    const preset = md.nodePresets[data.preset];
    if (preset && preset.color) {
      return preset.color;
    }
  }

  // a new node won't have a layer
  if (part.data.group === undefined) return defaultColor;

  if (!md.layers) return defaultColor;

  const layerColor = md.layers[part.data.group].color;
  if (!layerColor) return defaultColor;

  return layerColor;
}
