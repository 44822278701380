import { formatDate } from '@/util/date';

type Requester = {
  name: string;
  email: string;
}

export default async (fields: Record<string, any>, requester: Requester, uploads: string[]) => {
  const req: Record<string, any> = {
    request: {
      requester,
      subject: fields.subject,
      comment: {
        body: fields.description,
      },
      tags: ['v1feedback'],
      custom_fields: [
        {
          id: 360004589738,
          value: 'platform',
        },
        {
          id: 360004953578,
          value: fields.orgLabel,
        },
        {
          id: 360003201658,
          value: fields.type,
        },
        {
          id: 360003201338,
          value: document.location.href,
        },
        {
          id: 360003201358,
          value: `V1-${formatDate(Date.now())}`,
        },
      ],
    },
  };
  if (uploads.length) {
    req.request.comment.uploads = uploads;
  }

  return req;
};
