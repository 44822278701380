import ApiService from '@/services/api.service';
import tokenUtil from '@/plugins/auth/token';

/**
 * Random between 5000 and 8000
 * @param {number} threshold - How many milliseconds the token should be
 * refreshed before it expires
 * @param {number} delta - random number of milliseconds used to avoid
 * conflict between multiple clients
 * @return {number} - Milliseconds
 */
const getThreshold = (threshold = 5000, delta = 3000): number => {
  if (Number.isNaN(threshold) || Number.isNaN(delta)) {
    return 5000 + Math.floor(Math.random() * 3000);
  }
  return threshold + Math.floor(Math.random() * delta);
};

/**
 * getDelay - Number of milliseconds before next refresh
 * @param {string} token
 * @return {number} - Milliseconds
 */
export const getDelay = (token: string): number => {
  const payload = tokenUtil.decode(token);

  // Invalid token.
  if (!payload) {
    return -1;
  }

  const now = Date.now();
  const oneSecondMS = 1000;

  // Token expired
  if (payload.exp * 1000 <= now - oneSecondMS) {
    return -1;
  }
  const threshold = getThreshold();
  const refreshAtMS = payload.exp * 1000 - threshold;

  // if exp is smaller than threshold
  // try refreshing one second before it expires.
  if (refreshAtMS <= now + threshold) {
    return Math.floor(payload.exp * 1000 - now - oneSecondMS);
  }

  return Math.floor(refreshAtMS - now);
};

export const getNewToken = async (): Promise<string> => {
  if (!tokenUtil.getToken()) {
    return '';
  }

  // API request
  let data;
  try {
    data = await ApiService.get('/auth/refresh');
  } catch {
    return '';
  }
  return data.token;
};
