

















import Vue from 'vue';
import appConfig from '@/app.config.json';
import ListTooltip from '@/components/list/ListTooltip.vue';

export default Vue.extend({
  name: 'AppAvatar',
  components: { ListTooltip },
  props: {
    size: {
      type: String,
      default: '25px',
    },
    label: {
      type: String,
      default: 'app-avatar',
    },
    avatar: String,
  },
  data() {
    return {
      // @aly just some colours I thought would look good with white text for avatars
      colours: [
        'teal',
        'teal-9',
        'teal-10',
        'blue-grey',
        'grey-8',
        'brown',
        'deep-orange',
        'orange',
        'lime-8',
        'light-green-8',
        'green',
        'cyan',
        'cyan-9',
        'cyan-10',
        'light-blue',
        'blue',
        'blue-10',
        'indigo',
        'indigo-6',
        'indigo-9',
        'deep-purple',
        'deep-purple-9',
        'purple',
        'purple-6',
        'pink',
        'red',
      ],
    };
  },
  computed: {
    backgroundColor(): string {
      if (!this.defaultLabel) {
        return this.colours[0];
      }
      const name = this.defaultLabel.toLowerCase();
      let index = 0;
      for (let i = 0; i < name.length / 2; i += 1) {
        index += name.charCodeAt(i);
      }
      return this.colours[index % 25];
    },
    fontSize(): string {
      const value = this.size.split('px')[0];
      return `${Number(value) / 3}px`;
    },
    defaultLabel(): string {
      const matches = this.label.match(/\b(\w)/g);
      return (matches || []).join('').toUpperCase();
    },
    imageSrc(): string {
      return this.avatar || appConfig.avatarPlaceholder;
    },
  },
});
