










































































import Vue, { PropType } from 'vue';
import { NodePreset } from '@/bridge/types/diagramModel';
import { getDefaultElementSettings } from '@/bridge/settings/elementSettings';
import Btn from '@/components/buttons/Btn.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import ColorSettings from '@/components/diagram/ColorSettings.vue';
import TextSettings from '@/components/diagram/TextSettings.vue';
import ApiError from '@/components/ApiError.vue';
import FieldCheckbox from '@/components/fields/FieldCheckbox.vue';

function getDefaultModel(preset?: NodePreset) {
  let model: NodePreset = {
    id: Date.now(),
    label: '',
    usedByTotal: 0,
    color: undefined,
    textStyle: undefined,
    showOnLegend: true,
  };

  if (preset) {
    model = { ...model, ...preset };
  }

  return model;
}

export default Vue.extend({
  name: 'PresetForm',
  components: {
    FieldCheckbox,
    ApiError,
    Btn,
    FieldLabel,
    TextSettings,
    ColorSettings,
  },
  props: {
    isEditForm: Boolean,
    preset: {
      type: Object as PropType<NodePreset>,
      required: false,
    },
    presetLabels: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  data() {
    return {
      error: '',
      model: {} as NodePreset,
    };
  },
  methods: {
    async submit() {
      this.error = '';
      const valid = await (this.$refs.form as any).validate();
      if (!valid) return;

      if (!this.isEditForm && this.presetLabels.includes(this.model.label)) {
        this.error = 'A preset with this label already exists. Please choose another label.';
        return;
      }

      if (!this.model.color && !this.model.textStyle) {
        this.error = 'A preset must have either colour or text settings.';
        return;
      }

      this.$emit('submitted', this.model);
      this.error = '';
    },
    setDefaultModel() {
      this.model = getDefaultModel(this.preset);
    },
    addTextStyleSettings() {
      this.$set(this.model, 'textStyle', getDefaultElementSettings().textStyle);
    },
    removeTextStyleSettings() {
      this.model.textStyle = undefined;
    },
    addColorSettings() {
      this.$set(this.model, 'color', getDefaultElementSettings().color);
    },
    removeColorSettings() {
      this.model.color = undefined;
    },
  },
  created() {
    this.setDefaultModel();
  },
});
