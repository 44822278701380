<template>
  <q-input
    v-bind="$attrs"
    v-on="$listeners"
    dense
    outlined
    no-error-icon
    :value="value"
    :error="!!errorMessage"
    :error-message="errorMessage"
    :rules="[ validation ]"
    lazy-rules="ondemand"
    type="url"
  >
    <template #prepend v-if="icon">
      <q-icon :name="icon" color="light-grey-1"/>
    </template>
  </q-input>
</template>

<script>
import urlValidation from '@/validation/url';

export default {
  name: 'FieldLink',
  props: {
    value: String,
    errorMessage: String,
    required: Boolean,
    icon: String,
    type: String,
  },
  methods: {
    validation(text) {
      const validator = urlValidation[this.type];
      if (!validator) {
        return false;
      }
      if (this.required) {
        return validator.isRequired(text);
      }
      return validator.isValid(text);
    },
  },
};
</script>
