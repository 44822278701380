<template>
  <q-input
    v-bind="$attrs"
    v-on="$listeners"
    dense
    outlined
    no-error-icon
    :value="value"
    :error="!!errorMessage"
    :error-message="errorMessage"
    :rules="[ validation ]"
    lazy-rules="ondemand"
  >
    <template #prepend v-if="icon">
      <q-icon :name="icon" :color="iconColor || 'grey-7'">
        <q-tooltip>
          {{ tooltip }}
        </q-tooltip>
      </q-icon>
    </template>
  </q-input>
</template>

<script>
import Label from '@/validation/label';

export default {
  name: 'FieldLabel',
  props: {
    icon: String,
    iconColor: String,
    value: String,
    errorMessage: String,
    required: Boolean,
    tooltip: String,
    minLen: [Number, String],
    maxLen: [Number, String],
  },
  data() {
    return {
      labelValidation: {},
    };
  },
  watch: {
    minLen(newVal) {
      this.labelValidation.minLen = newVal;
    },
    maxLen(newVal) {
      this.labelValidation.maxLen = newVal;
    },
    '$attrs.label': function watchLabel(newVal) {
      this.labelValidation.name = newVal;
    },
  },
  methods: {
    validation(text) {
      if (this.required) {
        return this.labelValidation.isRequired(text);
      }
      return this.labelValidation.isValid(text);
    },
  },
  created() {
    let name;
    if (this.$attrs.label) {
      name = this.$attrs.label;
    }
    this.labelValidation = new Label({
      name,
      minLen: parseInt(this.minLen, 10),
      maxLen: parseInt(this.maxLen, 10),
    });
  },
};
</script>
