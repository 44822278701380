












































import Vue, { PropType } from 'vue';
import { OrgModel } from '@/types/org';
import { SocialDetailsMap } from '@/types/social';
import ApiService from '@/services/api.service';
import OrgUsers from '@/components/org/OrgUsers.vue';
import ViewSocial from '@/components/social/ViewSocial.vue';
import AvatarWithControls from '@/components/avatar/AvatarWithControls.vue';
import ExpandableListItem from '@/components/list/ExpandableListItem.vue';

function getDefaultSocial(): SocialDetailsMap {
  return {
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    website: '',
  };
}

export default Vue.extend({
  name: 'OrgDetails',
  components: {
    OrgUsers,
    ViewSocial,
    ExpandableListItem,
    AvatarWithControls,
  },
  props: {
    showEdit: Boolean,
    details: {
      type: Object as PropType<OrgModel>,
      required: true,
    },
  },
  data() {
    return {
      socialDetails: getDefaultSocial(),
      loaded: false,
    };
  },
  methods: {
    async loadOrgDetails() {
      await this.$store.dispatch('orgModule/loadDetails', {
        orgId: this.$route.params.orgId,
        force: true,
      });
    },
    async loadSocialDetails() {
      try {
        this.socialDetails = await ApiService.get(`/org/${this.details.id}/social`);
      } catch {
        this.socialDetails = getDefaultSocial();
      }
      this.loaded = true;
    },
  },
  async created() {
    await this.loadSocialDetails();
  },
});
