import {
  LayerMap,
  LayerName,
  LayerIndex,
} from '@/bridge/types/diagramModel';

/**
 * ObashiLayers
 * We need to wrap the map in a function to make sure none of these properties can be mutated.
 */
const ObashiLayers = (): LayerName[] => [
  'ownership',
  'business process',
  'application',
  'system',
  'hardware',
  'infrastructure',
];

const obashiLayerDescription: Record<LayerName, string> = {
  ownership: `The Ownership layer contains elements representing
    the person(s), role(s), or group(s) that 'owns', utilizes or
    is responsible for the business processes portrayed in
    the Business Process layer.`,
  'business process': `The Business Process layer contains elements
    representing the business processes or business functions that
    are utilized by owner(s).
    In the OBASHI framework they are positioned under their appropriate
    owner.`,
  application: `The Application layer contains elements representing
    software applications or definable parts of software applications.
    In the OBASHI framework, Application elements are positioned beneath
    the business processes that utilizes them.`,
  system: `The System layer contains elements that represent operating systems.
    In the OBASHI framework they are positioned above the hardware on which
    they are installed and below the applications that reside on them.`,
  hardware: `The Hardware layer contains elements representing the computer
    hardware on which the operating systems run. In the OBASHI framework
    they are positioned underneath these operating systems.`,
  infrastructure: `The Infrastructure layer contains elements representing the
    network infrastructure. In the OBASHI framework, Infrastructure elements
    are positioned beneath the Hardware elements that utilizes them.
    Infrastructure elements can also be positioned beneath other Infrastructure
    elements to create a hierarchy that supports the business.`,
  any: '',
};

class LayerUtils {
  private layerMap: LayerMap = ObashiLayers();

  // Layers can be accessed from outside but can't be changed
  get layers() {
    return this.layerMap;
  }

  get layerKeys(): LayerName[] {
    return Object.keys(this.layerMap) as LayerName[];
  }

  getLayerDescription(layer: LayerIndex|LayerName): string {
    if (typeof layer === 'string') {
      return obashiLayerDescription[layer];
    }
    return obashiLayerDescription[this.getName(layer)];
  }

  getIndex(name: LayerName): LayerIndex {
    return this.layers.indexOf(name) as LayerIndex;
  }

  getName(index: LayerIndex): LayerName {
    return this.layers[index];
  }

  isLayer(layer: any): boolean {
    if (typeof layer === 'string') {
      return this.layers.includes(layer as any);
    }

    return !!this.layers[layer];
  }
}

export const layerUtils = new LayerUtils();
