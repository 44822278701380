<template>
  <q-item class="no-padding">
    <q-item-section v-if="label">
      <q-item-label caption>{{ label }}</q-item-label>
    </q-item-section>
    <q-item-section side>
      <q-input
        dense
        outlined
        hide-bottom-space
        :value="value"
        style="width: 150px;"
        :rules="[validColor]"
        @input="$emit('input', $event)"
      >
        <template #append>
          <q-icon
            :style="`color: ${value}; border-radius: 50%;`"
            :class="targetClass"
            class="fas fa-circle bordered cursor-pointer q-mr-sm"
            size="1.5rem"
          />
        </template>
      </q-input>
    </q-item-section>
    <q-popup-proxy
      :target="`.${targetClass}`"
      transition-hide="scale"
      transition-show="scale"
    >
      <q-color
        :value="value"
        no-footer
        no-header
        format-model="rgba"
        default-view="palette"
        @change="$emit('input', $event)"
      />
    </q-popup-proxy>
  </q-item>
</template>

<script>
export default {
  name: 'FieldColorPicker',
  props: {
    label: String,
    value: {
      type: String,
      default: '#000000',
    },
    errorMessage: String,
  },
  computed: {
    targetClass() {
      const label = this.label || '';
      return `field_${label.split(' ').join('_')}_${Date.now()}`;
    },
  },
  methods: {
    validColor(v) {
      const hexOrHexa = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{4}|[0-9a-fA-F]{6}|[0-9a-fA-F]{8})$/;
      const rgb = /^rgb\(((0|[1-9][\d]?|1[\d]{0,2}|2[\d]?|2[0-4][\d]|25[0-5]),){2}(0|[1-9][\d]?|1[\d]{0,2}|2[\d]?|2[0-4][\d]|25[0-5])\)$/;
      const rgba = /^rgba\(((0|[1-9][\d]?|1[\d]{0,2}|2[\d]?|2[0-4][\d]|25[0-5]),){2}(0|[1-9][\d]?|1[\d]{0,2}|2[\d]?|2[0-4][\d]|25[0-5]),(0|0\.[0-9]+[1-9]|0\.[1-9]+|1)\)$/;

      const str = v.replace(/\s/g, '');
      if (hexOrHexa.test(str) || rgb.test(str) || rgba.test(str)) {
        return true;
      }

      return 'Please enter a valid colour';
    },
  },
};
</script>
