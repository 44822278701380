import { Part } from 'gojs';
import { LinkSettings } from '@/bridge/types/diagramModel';
import { getDefaultConnectionSettings } from '@/bridge/settings/connectionSettings';

export const getDefaultDependencySettings = (): LinkSettings => ({
  borderColor: '#fc5c65',
  hoverColor: 'rgba(193, 0, 21, 0.3)',
  borderWidth: 2,
  visible: true,
  showDirection: true,
});

export function dependencySettings(part?: Part): LinkSettings {
  if (!part) return getDefaultConnectionSettings();

  return part.data;
}
