
















import Vue from 'vue';
import DeleteModal from '@/components/DeleteModal.vue';

export default Vue.extend({
  name: 'DeleteProject',
  components: { DeleteModal },
  computed: {
    orgId(): string {
      return this.$route.params.orgId;
    },
    url(): string {
      return `/project/${this.$route.params.projectId}`;
    },
  },
  methods: {
    async onDelete() {
      await this.$router.replace(`/org/${this.orgId}/projects`);
    },
  },
});
