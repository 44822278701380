
















































































// library
import Vue, { VueConstructor, PropType } from 'vue';
import formSubmit from '@/mixins/formSubmit';
import { getSocialIconMap } from '@/util/socialNetworks';
// types
import { FormWrapperRequest } from '@/types/form';
import { SocialDetailsMap, SocialNetworks } from '@/types/social';
// components
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import AppModal from '@/components/AppModal.vue';
import EditInfoBanner from '@/components/profile/edit/EditInfoBanner.vue';
import FieldLink from '@/components/fields/FieldLink.vue';

function getDefaultModel(isCompany = false): SocialDetailsMap {
  const model: SocialDetailsMap = {
    facebook: '',
    instagram: '',
    linkedin: '',
    twitter: '',
  };
  if (isCompany) {
    model.website = '';
  }

  return model;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'EditSocial',
  components: {
    EditInfoBanner,
    AppModal,
    Btn,
    ApiError,
    FieldLink,
    FieldLabel,
  },
  mixins: [formSubmit],
  props: {
    isCompany: Boolean,
    url: {
      type: String,
      required: true,
    },
    details: {
      type: Object as PropType<SocialDetailsMap>,
      required: true,
    },
  },
  computed: {
    request(): FormWrapperRequest {
      const data: SocialDetailsMap = getDefaultModel(this.isCompany);

      Object.keys(this.model).forEach((key) => {
        const k = key as keyof SocialDetailsMap;

        if (!this.model[k]) {
          data[k] = '';
          return;
        }

        data[k] = `${(this.socialIconMap as any)[k].link}${(this.model as any)[k]}`;
        if (data[k]?.indexOf('https://') === -1) {
          data[k] = `https://${data[k]}`;
        }
      });

      return {
        config: {
          data,
          method: 'put',
          url: this.url,
        },
      };
    },
  },
  data() {
    return {
      socialIconMap: getSocialIconMap(this.isCompany),
      model: getDefaultModel(this.isCompany),
    };
  },
  methods: {
    onPaste(evt: ClipboardEvent, field: SocialNetworks | 'website') {
      const value = this.socialIconMap[field];
      if (evt.clipboardData && value) {
        const pasted = evt.clipboardData.getData('text');
        const prefixIndex = pasted.indexOf(value.link);
        this.model[field] = prefixIndex < 0 ? pasted
          : pasted.substring(prefixIndex + value.link.length);
      }
    },
    async onSubmit() {
      await this.submit(this.request, this.$refs.form);

      if (this.hasError) {
        return;
      }

      (this.$refs.modal as any).close();
      this.notify('Social details updated');
      this.$emit('submit');
    },
    openModal() {
      Object.keys(this.details).forEach((key) => {
        const k = key as keyof SocialDetailsMap;
        const current = this.details[k];
        // to avoid checking the loaded flag as well
        if (current && current.length > 0) {
          if (k === 'website') {
            this.model[k] = this.details.website;
          } else {
            // we only need te profile data not the entire url
            const value = this.socialIconMap[k];
            if (value) {
              const index = current.indexOf(value.link);
              this.model[k] = current.substring(index + value.link.length);
            }
          }
        }
      });
      (this.$refs.modal as any).open();
    },
  },
});
