























import Vue from 'vue';
import { OrgModel } from '@/types/org';
import { ProjectModel } from '@/types/project';
import { PageTab } from '@/types/common';
import PageTabs from '@/layouts/PageTabs.vue';
import ProjectDetails from '@/views/projects/ProjectDetails.vue';
import ProjectSettingsForm from '@/views/projects/ProjectSettingsForm.vue';
import ProjectForm from '@/views/projects/ProjectForm.vue';
import DeleteProject from '@/views/projects/DeleteProject.vue';

const tabs: PageTab[] = [
  {
    label: 'B&ITs',
    route: 'bits',
  },
  {
    label: 'DAVs',
    route: 'davs',
  },
  {
    label: 'Assets',
    route: 'assets',
  },
  {
    label: 'Connections',
    route: 'connections',
  },
  {
    label: 'Dependencies',
    route: 'dependencies',
  },
  {
    label: 'Forms',
    route: 'forms',
  },
  {
    label: 'Files',
    route: 'files',
  },
  {
    label: 'Users',
    route: 'users',
  },
  {
    label: 'Modelling States',
    route: 'modelling-states',
  },
  {
    label: 'Project Stats',
    route: 'project-stats',
  },
  {
    label: 'BIT Analytics',
    route: 'bit-analytics',
  },
  {
    label: 'DAV Analytics',
    route: 'dav-analytics',
  },
];

export default Vue.extend({
  name: 'ProjectTabs',
  components: {
    DeleteProject,
    ProjectForm,
    ProjectSettingsForm,
    ProjectDetails,
    PageTabs,
  },
  computed: {
    project(): ProjectModel {
      return this.$store.getters['projectModule/details'];
    },
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    breadcrumbs(): any[] {
      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}/projects`,
        },
        {
          label: this.project.label,
        },
      ];
    },
    tabs(): PageTab[] {
      if (this.$hasProjectRole(this.$Roles.PROJECT_MANAGER)) {
        return tabs;
      }

      if (this.$hasProjectRole(this.$Roles.VIEWER)) {
        return tabs.filter((t) => !['users'].includes(t.route));
      }
      return tabs.filter((t) => [
        '',
        'bits',
        'davs',
        'bit-analytics',
        'dav-analytics',
        'project-stats',
      ].includes(t.route));
    },
  },
  methods: {
    async loadProject() {
      await this.$store.dispatch('projectModule/loadDetails', {
        force: true,
        id: this.$route.params.projectId,
      });
    },
  },
});
