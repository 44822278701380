import OrgTabs from '@/views/org/OrgTabs.vue';

export default [
  {
    path: '',
    component: OrgTabs,
    meta: { ignoreScrollPosition: true },
    children: [
      {
        path: 'projects',
        component: () => import(/* webpackChunkName: "view-org-projects" */ '@/views/projects/ListProjects.vue'),
      },
      {
        path: 'divisions',
        component: () => import(/* webpackChunkName: "view-org-divisions" */ '@/views/admin/org/divisions/ListDivisions.vue'),
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "view-org-users" */ '@/views/admin/org/users/ListUsers.vue'),
      },
      {
        path: 'roles',
        component: () => import(/* webpackChunkName: "view-org-roles" */ '@/views/admin/org/roles/ListRoles.vue'),
      },
      {
        path: 'audit',
        component: () => import(/* webpackChunkName: "view-org-roles" */ '@/views/admin/org/audit/ListAudits.vue'),
      },
      {
        path: 'logs',
        component: () => import(/* webpackChunkName: "view-org-roles" */ '@/views/admin/org/audit/ListLogins.vue'),
      },
      {
        path: 'shared',
        component: () => import(/* webpackChunkName: "view-shared-diagrams" */ '@/views/shared/ListSharedDiagrams.vue'),
      },
    ],
  },
];
