import { render, staticRenderFns } from "./LayerSettings.vue?vue&type=template&id=38edb424&"
import script from "./LayerSettings.vue?vue&type=script&lang=ts&"
export * from "./LayerSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSpace from 'quasar/src/components/space/QSpace.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpace,QItem,QItemSection,QItemLabel});
