type inputValidator = (input: string) => boolean|string;

interface Validator {
  isValid: inputValidator;
  isRequired: inputValidator;
}

export abstract class Validation implements Validator {
  abstract isValid(text: unknown): boolean|string;
  public isRequired(text: string): boolean|string {
    if (!text) return this.isRequiredError;
    return this.isValid(text);
  }

  abstract readonly isValidError: string;
  abstract readonly isRequiredError: string;
}
