











import 'cropperjs/dist/cropper.css';
import Vue from 'vue';
import PhotoEditorLoader from '@/components/photoEditor/PhotoEditorLoader.vue';
import PhotoEditorControls from '@/components/photoEditor/PhotoEditorControls.vue';
import { PhotoEditorModel } from '@/components/photoEditor/editorUtils';

export default Vue.extend({
  name: 'PhotoEditor',
  components: {
    PhotoEditorLoader,
    PhotoEditorControls,
  },
  data() {
    return {
      cropperData: {
        name: '',
        blobUrl: '',
        blob: null,
        fileType: '',
      },
    };
  },
  methods: {
    updateData(data: Partial<PhotoEditorModel>) {
      (this.cropperData as PhotoEditorModel) = { ...this.cropperData, ...data };
    },
    handleDone(data: Partial<PhotoEditorModel>) {
      this.$emit('done', { ...this.cropperData, ...data });
    },
  },
});
