import { NavigationGuard } from 'vue-router';
import { verifySession } from '@/plugins/auth/auth';
import $store from '@/store';
import notify from '@/util/notify';

const authGuard: NavigationGuard = async function authGuard(to, from, next) {
  const isAuth = await verifySession();

  // Constraint for protected org
  const requiresAuth = to.matched.some((r) => r.meta.auth);
  // Constraint for guest-only org
  const guestOnly = to.matched.some((r) => r.meta.guest);

  // A user that is not authenticated wants to access a protected route
  if (requiresAuth && !isAuth) {
    await $store.dispatch('clear');
    notify.info('Please login to continue');

    return next({ path: '/login' });
  }

  // An authenticated user wants to access a guestOnly route
  // for example authenticated user wants to navigate to /login
  if (guestOnly && isAuth) {
    return next({ path: '/' });
  }

  return next();
};

export default authGuard;
