<template>
  <div class="q-mt-lg row">
    <div class="col-6 q-pa-lg offset-3 text-center bg-blue-1 bordered rounded-borders">
      <h1 class="q-mt-lg">Hello {{ name }}</h1>

      <q-icon name="o_feedback" size="4rem" class="text-blue-3"/>

      <h5>
        It looks like you don't have an active subscription with us
        and are not associated to any organisations.
      </h5>
      <p class="text-subtitle1">
        If you think that's not correct, please contact an admin.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyOrgList',
  computed: {
    user() {
      return this.$store.getters['profileModule/user'];
    },
    name() {
      return this.user.displayName || this.user.forename;
    },
  },
};
</script>
