










































// library
import Vue, { VueConstructor } from 'vue';
import format from 'quasar/src/utils/format.js';;
import formSubmit from '@/mixins/formSubmit';
// types
import { FormWrapperRequest } from '@/types/form';
// components
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';

const { capitalize } = format;

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'DeleteModal',
  components: {
    Btn,
    AppModal,
    ApiError,
  },
  mixins: [formSubmit],
  props: {
    resourceId: String,
    title: {
      type: String,
      default: 'Delete',
    },
    // The resource type - project, user, role, etc
    resource: {
      type: String,
      default: 'resource',
    },
    url: {
      type: String,
      required: true,
    },
    customIcon: {
      type: String,
      default: 'delete',
    },
  },
  computed: {
    request(): FormWrapperRequest {
      if (this.resourceId) {
        // For APIs that support batch delete
        return {
          config: {
            method: 'put',
            url: this.url,
            data: { ids: [this.resourceId] },
          },
        };
      }
      return {
        config: {
          method: 'delete',
          url: this.url,
        },
      };
    },
  },
  methods: {
    open() {
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }

      this.notify(`${capitalize(this.resource)} deleted`);
      this.$emit('deleted');
      if (this.$refs.modal) {
        (this.$refs.modal as any).close();
      }
    },
  },
});
