import { PluginObject } from 'vue';
import EventBus from '@/util/EventBus';
import { getNewToken, getDelay } from '@/plugins/auth/refreshUtils';
import ApiService from '@/services/api.service';
import tokenUtil, { JWTClaims } from './token';

export type LoginDetails = {
  email: string;
  password: string;
};

type AuthConfig = {
  loginRedirect: string;
  logoutRedirect: string;
};

export function getUser(): JWTClaims | null {
  const token = tokenUtil.getToken();
  if (!token || !tokenUtil.isValid(token)) {
    return null;
  }
  return tokenUtil.decode(token);
}

export async function verifySession(): Promise<boolean> {
  if (!getUser()) {
    return false;
  }
  try {
    // @TODO - enable when backend supports this
    // await ApiService.get('/auth/verify');
    return true;
  } catch {
    return false;
  }
}

class Auth extends EventBus {
  private timeoutId: any = null;

  private config: AuthConfig = {
    loginRedirect: '/',
    logoutRedirect: '/login',
  };

  constructor() {
    super();

    window.addEventListener('storage', (event) => {
      this.emitStorage(event);
    });
  }

  /**
   * emitStorage - Use authEvents to emit window.storage events.
   * This way we can easily unregister the event listener.
   * EventTarget.addEventListener requires to pass the same listener
   * to correctly unregister an event.
   * @param event
   */
  emitStorage(event: StorageEvent) {
    this.$emit('storage', event);
  }

  async init() {
    await this.startRefresh(tokenUtil.getToken());

    this.$on('storage', async (event: StorageEvent) => {
      await this.globalAuth(event);
    });
  }

  // Login/Logout to all clients
  async globalAuth(event: StorageEvent) {
    if (event.key !== 'token') {
      return;
    }

    // New token
    if (event.oldValue === null && event.newValue) {
      await ApiService.setHeader(event.newValue);
      await this.startRefresh(event.newValue);
      window.location.href = this.config.loginRedirect;
      return;
    }

    // token removed
    if (event.oldValue && event.newValue === null) {
      window.location.href = this.config.logoutRedirect;
      // A timer might run for the current client
      // Even though the logout was called by another client
      this.cancelRefresh();
    }
  }

  async startRefresh(existingToken?: string) {
    let token: string;

    // Initially we will pass the token we get from login
    // then the function will call itself without passing a token
    // which will trigger the API request getNewToken
    if (existingToken) {
      token = existingToken;
    } else {
      token = await getNewToken();
    }

    if (!token) {
      this.$emit('auth:error', {
        message: 'Invalid token',
        type: 'refresh',
      });
      return;
    }

    tokenUtil.save(token);

    const delay = getDelay(token);
    if (delay < 0) {
      this.$emit('auth:error', {
        message: 'Token expired',
        type: 'refresh',
      });
      return;
    }

    this.cancelRefresh();
    this.timeoutId = setTimeout(() => {
      this.startRefresh();
    }, delay);
  }

  cancelRefresh() {
    clearTimeout(this.timeoutId);
  }

  // Remove all event listeners to avoid any memory leaks
  destroy() {
    // Unregister window.storage event
    window.removeEventListener('storage', (event) => {
      this.emitStorage(event);
    });
    // Unregister all authEvents
    this.$off();
  }

  async login(payload: LoginDetails): Promise<string> {
    const { token } = await ApiService.post('/auth/login', payload);
    await this.storeAndRefresh(token);

    return token;
  }

  async logout() {
    if (tokenUtil.getToken()) {
      try {
        await ApiService.get('/auth/logout');
      } catch (e) {
        console.log('Logout error: ', e);
      } finally {
        tokenUtil.remove();
        this.cancelRefresh();
        window.location.replace(this.config.logoutRedirect);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isAuthenticated(): boolean {
    return !!tokenUtil.getToken();
  }

  async storeAndRefresh(token: string) {
    tokenUtil.save(token);
    await this.startRefresh(token);
  }
}

export const auth = new Auth();

export default {
  install(V): void {
    ApiService.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          error
          && error.response
          && error.response.data.error === 'invalid token'
        ) {
          setTimeout(() => {
            auth.$emit('auth:error', {
              action: 'logout',
              message: error.response.data.error,
            });
          }, 500);
        }
        return Promise.reject(error);
      },
    );

    // eslint-disable-next-line no-param-reassign
    (V.prototype as any).$auth = auth;
  },
} as PluginObject<Vue>;
