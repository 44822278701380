import Vue from 'vue';
import Vuex, { ActionContext } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import rootState, { RootState } from '@/store/rootState';

// Modules
import orgModule from '@/store/orgModule/orgModule';
import profileModule from '@/store/profileModule/profileModule';
import projectModule from '@/store/projectModule/projectModule';
import activeItemModule from '@/store/activeItemModule/activeItemModule';
import diagramModule from '@/store/diagramModule/diagramModule';

Vue.use(Vuex);

const storageKey = 'appData';

export default new Vuex.Store<RootState>({
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      key: storageKey,
    }),
  ],
  modules: {
    orgModule,
    projectModule,
    diagramModule,
    profileModule,
    activeItemModule,
  },
  state: { ...rootState },
  actions: {
    async clear(context: ActionContext<RootState, RootState>): Promise<void> {
      await context.dispatch('profileModule/clear');
      await context.dispatch('projectModule/clear');
      await context.dispatch('orgModule/clear');
      await context.dispatch('activeItemModule/clear');

      window.sessionStorage.removeItem(storageKey);
    },
    async init(context: ActionContext<RootState, RootState>): Promise<void> {
      await context.dispatch('profileModule/loadUserDetails');
    },
  },
  // for dev mode only
  strict: !!process.env.DEV,
});
