






















import Vue, { PropType } from 'vue';
import { NodePreset } from '@/bridge/types/diagramModel';
import Btn from '@/components/buttons/Btn.vue';
import AppModal from '@/components/AppModal.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';
import PresetForm from '@/components/diagram/presets/PresetForm.vue';

export default Vue.extend({
  name: 'PresetModal',
  components: {
    Btn,
    EditBtn,
    AppModal,
    PresetForm,
  },
  props: {
    preset: {
      type: Object as PropType<NodePreset>,
      required: false,
    },
    presetLabels: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },
  computed: {
    isEditForm(): boolean {
      return this.preset && !!this.preset.id;
    },
    title(): string {
      const action = this.isEditForm ? 'Edit' : 'New';
      return `${action} preset`;
    },
  },
  methods: {
    submitForm() {
      (this.$refs.form as any).submit();
    },
    async onSubmit(data: NodePreset) {
      (this.$refs.modal as any).close();
      this.$emit('submit', data);
    },
    async open() {
      (this.$refs.modal as any).open();
    },
  },
});
