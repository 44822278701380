import { Validation } from '@/validation/validation';
import { capitalizeFirstWord } from '@/util/strings';

enum UrlTypes {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  WEBSITE = 'website',
}

class Url extends Validation {
  /* eslint-disable-next-line */
  private readonly websiteRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/i;
  /* eslint-disable-next-line */
  private readonly facebookRegex = /(?:https?:\/\/)?(?:www\.)?(?:facebook|fb|m\.facebook)\.(?:com|me)\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]+)(?:\/)?/i;
  /* eslint-disable-next-line */
  private readonly instagramRegex = /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/i;
  /* eslint-disable-next-line */
  private readonly twitterRegex = /(https:\/\/|)(www\.|)twitter\.com\/[A-Za-z0-9_]{5,15}(\?(\w+=\w+&?)*)?/i;
  /* eslint-disable-next-line */
  private readonly linkedinRegex = /((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/i;

  public readonly maxLength = 250;

  constructor(public type: UrlTypes) {
    super();
  }

  get maxLengthError() {
    return `${capitalizeFirstWord(this.type)} url too long - max 250 characters`;
  }

  get isRequiredError() {
    return `${capitalizeFirstWord(this.type)} url is required`;
  }

  get isValidError() {
    return `Please enter a valid ${this.type} url`;
  }

  isValid(text: string): boolean | string {
    if (!text) return true;

    if (text.length > this.maxLength) {
      return this.maxLengthError;
    }

    let re: RegExp;

    switch (this.type) {
      case UrlTypes.FACEBOOK:
        re = this.facebookRegex;
        break;
      case UrlTypes.INSTAGRAM:
        re = this.instagramRegex;
        break;
      case UrlTypes.TWITTER:
        re = this.twitterRegex;
        break;
      case UrlTypes.LINKEDIN:
        re = this.linkedinRegex;
        break;
      default:
        re = this.websiteRegex;
    }

    if (!re.test(text)) {
      return this.isValidError;
    }

    return true;
  }
}

export default {
  [UrlTypes.INSTAGRAM]: new Url(UrlTypes.INSTAGRAM),
  [UrlTypes.FACEBOOK]: new Url(UrlTypes.FACEBOOK),
  [UrlTypes.TWITTER]: new Url(UrlTypes.TWITTER),
  [UrlTypes.LINKEDIN]: new Url(UrlTypes.LINKEDIN),
  [UrlTypes.WEBSITE]: new Url(UrlTypes.WEBSITE),
};
