import { Validation } from '@/validation/validation';

export const descriptionMaxLen = 500;

class Description extends Validation {
  public readonly maxLength = descriptionMaxLen;
  public readonly isRequiredError = 'Description is required';

  get isValidError() {
    return `Description must be less than ${this.maxLength} characters`;
  }

  isValid(text: string): boolean | string {
    if (text && text.length > this.maxLength) {
      return this.isValidError;
    }
    return true;
  }
}

export default new Description();
