import * as go from 'gojs';
import { NodeCategory } from '@/bridge/enums/partCategories';

function lockElement(n: go.Part, isAnchored: boolean) {
  n.movable = !isAnchored;
  n.copyable = !isAnchored;
}

export function anchorElements(d: go.Diagram, isAnchored: boolean, element?: go.Part) {
  d.clearSelection();
  d.clearHighlighteds();

  (d as any)._isAnchored = isAnchored;

  if (element && element.category === NodeCategory.ELEMENT) {
    lockElement(element, isAnchored);
  }

  d.nodes
    .filter((n) => n.category === NodeCategory.ELEMENT)
    .each((n) => {
      lockElement(n, isAnchored);
    });
}
