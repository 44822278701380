import Notify from 'quasar/src/plugins/Notify.js';;

type NotifyFn = (message: string) => void;

const success: NotifyFn = (message) => {
  Notify.create({
    type: 'positive',
    group: `positive_${message}`,
    message,
  });
};

const info: NotifyFn = (message) => {
  Notify.create({
    type: 'info',
    group: `info_${message}`,
    message,
  });
};

const danger: NotifyFn = (message) => {
  Notify.create({
    type: 'negative',
    group: `negative_${message}`,
    message,
  });
};

const warning: NotifyFn = (message) => {
  Notify.create({
    type: 'warning',
    group: `warning_${message}`,
    message,
  });
};

export default {
  success,
  danger,
  info,
  warning,
};
