<template>
  <div
    class="photo-editor-loader test--photo-editor-loader"
    :class="{ active }"
    @change="change"
    @dragover="dragover"
    @dragleave="dragleave"
    @drop="drop"
  >
    <div class="text-center">
      <label class="text-subtitle1 cursor-pointer" for="file">
        Drop image here or
        <span class="text-light-blue-6">browse...</span>
        <input id="file" accept="image/*" class="sr-only" type="file" />
      </label>

      <p class="text-grey-6 q-mb-sm q-mt-md">.png, .jpeg, .bmp, .webp, .gif</p>
      <p class="text-grey-6 q-my-none">max {{ maxSizeInMb }}Mb</p>
    </div>
  </div>
</template>

<script>
import notify from '@/util/notify';
import { imageLoader } from '@/components/photoEditor/editorUtils';

export default {
  name: 'PhotoEditorLoader',
  data() {
    return {
      active: false,
      maxSizeInMb: imageLoader.maxSizeInMb,
    };
  },
  methods: {
    async change({ target }) {
      await this.update(target.files);
      target.value = '';
    },
    async drop(e) {
      e.preventDefault();
      await this.update(e.dataTransfer.files);
      this.active = false;
    },
    async update(files) {
      try {
        const data = await imageLoader.read(files);
        this.$emit('update', data);
      } catch (e) {
        notify.danger(e.message);
      }
    },
    dragover(e) {
      e.preventDefault();
      this.active = true;
    },
    dragleave(e) {
      e.preventDefault();
      this.active = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.photo-editor-loader {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px dashed $grey-6;
  transition: all 0.2s ease-in-out;

  p {
    transition: all 0.2s ease-in-out;
  }

  &.active {
    border-color: $light-blue-5;
    background-color: $light-blue-1;

    p {
      opacity: 0;
    }
  }
}
</style>
