<template>
  <q-badge
    :color="`layer-${layerIndex}`"
    :text-color="`accent-layer-${layerIndex}`"
    :label="noText ? '' : layerLabel"
  />
</template>

<script>
import { getLayerByIndex } from '@/util/layers';

export default {
  name: 'LayerBadge',
  props: {
    noText: Boolean,
    layerIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    layerLabel() {
      const l = getLayerByIndex(this.layerIndex);
      return l ? l.label : '';
    },
  },
};
</script>
