import ProjectTabs from '@/views/projects/ProjectTabs.vue';
import { ResourceType } from '@/types/resource';
import { DiagramType } from '@/bridge/enums/diagramOptions';

export default [
  {
    path: '',
    component: ProjectTabs,
    meta: { ignoreScrollPosition: true },
    children: [
      {
        path: 'bits',
        component: () => import(/* webpackChunkName: "list-bits" */ '@/views/diagram/ListDiagrams.vue'),
        props: {
          diagramType: DiagramType.BIT,
        },
      },
      {
        path: 'davs',
        component: () => import(/* webpackChunkName: "list-davs" */ '@/views/diagram/ListDiagrams.vue'),
        props: {
          diagramType: DiagramType.DAV,
        },
      },
      {
        path: 'assets',
        component: () => import(/* webpackChunkName: "list-assets" */ '@/views/resources/ListResources.vue'),
        props: {
          resourceType: ResourceType.ASSET,
        },
      },
      {
        path: 'connections',
        component: () => import(/* webpackChunkName: "list-connections" */ '@/views/resources/ListResources.vue'),
        props: {
          resourceType: ResourceType.CONNECTION,
        },
      },
      {
        path: 'dependencies',
        component: () => import(/* webpackChunkName: "list-dependencies" */ '@/views/resources/ListResources.vue'),
        props: {
          resourceType: ResourceType.DEPENDENCY,
        },
      },
      {
        path: 'forms',
        component: () => import(/* webpackChunkName: "view-project-forms" */ '@/views/forms/ListForms.vue'),
      },
      {
        path: 'files',
        component: () => import(/* webpackChunkName: "view-project-file" */ '@/views/projects/files/ListFiles.vue'),
      },
      {
        path: 'users',
        component: () => import(/* webpackChunkName: "view-project-users" */ '@/views/projects/users/ManageProjectUsers.vue'),
      },
      {
        path: 'modelling-states',
        component: () => import(/* webpackChunkName: "view-modelling-states" */ '@/views/projects/modellingState/ListModellingStates.vue'),
      },
      {
        path: 'project-stats',
        component: () => import(/* webpackChunkName: "project-stats" */ '@/views/projects/statistics/ProjectStatistics.vue'),
      },
      {
        path: 'bit-analytics',
        component: () => import(/* webpackChunkName: "list-dependencies" */ '@/views/projects/statistics/DiagramAnalytics.vue'),
        props: {
          diagramType: DiagramType.BIT,
        },
      },
      {
        path: 'dav-analytics',
        component: () => import(/* webpackChunkName: "list-dependencies" */ '@/views/projects/statistics/DiagramAnalytics.vue'),
        props: {
          diagramType: DiagramType.DAV,
        },
      },
    ],
  },
];
