














import Vue from 'vue';
import { Breadcrumbs, PageTab } from '@/types/common';
import { OrgModel } from '@/types/org';
import PageTabs from '@/layouts/PageTabs.vue';
import OrgDetails from '@/views/org/OrgDetails.vue';
import EditOrgDetails from '@/views/org/EditOrgDetails.vue';

const tabs: PageTab[] = [
  {
    label: 'Projects',
    route: 'projects',
  },
  {
    label: 'Divisions',
    route: 'divisions',
  },
  {
    label: 'Users',
    route: 'users',
  },
  {
    label: 'Roles',
    route: 'roles',
  },
  {
    label: 'Audit Logs',
    route: 'audit',
  },
  {
    label: 'Login activity',
    route: 'logs',
  },
  {
    label: 'Shared',
    route: 'shared',
  },
];

export default Vue.extend({
  name: 'OrgTabs',
  components: {
    EditOrgDetails,
    OrgDetails,
    PageTabs,
  },
  computed: {
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    breadcrumbs(): Breadcrumbs[] {
      return [{ label: this.org.label }];
    },
    tabs(): PageTab[] {
      if (this.$hasOrgRole(this.$Roles.ADMIN)) {
        return tabs.filter((t) => !['shared'].includes(t.route));
      }

      if (this.$isOrgRole(this.$Roles.GUEST)) {
        return tabs.filter((t) => !['users', 'roles', 'divisions', 'audit', 'logs', 'projects'].includes(t.route));
      }

      if (this.$hasProjectRole(this.$Roles.CONTRIBUTOR)
        || this.$hasProjectRole(this.$Roles.VIEWER)
        || this.$hasOrgRole(this.$Roles.CONTRIBUTOR)
        || this.$hasOrgRole(this.$Roles.VIEWER)) {
        return tabs.filter((t) => !['users', 'roles', 'divisions', 'audit', 'logs'].includes(t.route));
      }

      return [tabs[0]];
    },
  },
});
