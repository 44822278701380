<template>
  <div
    class="app-card bg-white"
    :class="{ 'card-shadow': !flat, dense }"
    :style="gridTemplateRows"
  >
    <div v-if="!noHeader">
      <div
        class="q-px-sm q-py-sm app-card--header"
        :class="{ 'q-px-sm': dense, 'q-px-md': !dense }"
      >
        <slot name="preTitle"></slot>
        <p
          class="text-dark-grey q-my-none"
          :class="{ 'text-subtitle2': dense, 'text-subtitle-1': !dense }"
          v-if="title"
        >{{ title }}</p>

        <slot name="actions"></slot>
      </div>

      <q-separator v-if="!noHeader" color="light-grey"/>
    </div>

    <div
      class="q-py-sm app-card--body"
      :class="{ 'q-px-sm': dense, 'q-px-md': !dense }"
    >
      <slot></slot>
    </div>

    <div
      class="q-py-sm bg-white app-card--footer"
      :class="{ 'q-px-sm': dense, 'q-px-md': !dense, 'sticky-footer': hasStickyFooter }"
      v-if="!!$slots.footer"
    >
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCard',
  props: {
    dense: Boolean,
    title: String,
    noHeader: Boolean,
    flat: Boolean,
    hasStickyFooter: Boolean,
  },
  computed: {
    gridTemplateRows() {
      const size = this.dense ? '35px' : '50px';

      if (!this.$slots.footer) {
        if (this.noHeader) {
          return 'grid-template-rows: 0 1fr';
        }
        return `grid-template-rows: ${size} 1fr`;
      }

      if (this.noHeader) {
        return `grid-template-rows: 0 1fr ${size}`;
      }
      return `grid-template-rows: ${size} 1fr ${size};`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~quasar-variables';

.app-card {
  border-radius: $generic-border-radius;
  // display: grid;
  // grid-template-columns: 1fr;
  // gap: 0 0;
  // grid-template-areas:
  //  "header"
  //  "body"
  //  "footer";

  &--header {
    // grid-area: header;
    // height: 50px;
  }
  // &--body { grid-area: body; }
  // &--footer { grid-area: footer; }

  &--header,
  &--footer {
    display: flex;
    align-items: center;
    height: 50px;
  }

  .sticky-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
