import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { RootState } from '@/store/rootState';
import { ProjectModel, ProjectPreferences } from '@/types/project';
import { Storable } from '@/types/common';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import { loadDetails } from '@/store/storeHelpers';
import { Roles } from '@/types';
import ApiService from '@/services/api.service';
import { getDefaultModelData } from '@/bridge/settings/modelData';

type ProjectState = {
  project: Storable<ProjectModel>;
  preferences: Storable<ProjectPreferences>;
};

type LoadDetailsParams = {
  id: string;
  orgId: string;
  force: boolean;
}

const getProjectDetails = () => ({
  id: '',
  label: '',
  description: '',
  avatar: '',
  code: '',
  divisions: [],
  createdBy: '',
  updatedBy: '',
  createdAt: 0,
  updatedAt: 0,
  roleWithinProject: Roles.GUEST,
});

const projectState: ProjectState = {
  project: {
    details: getProjectDetails(),
    loaded: false,
    error: '',
  },
  preferences: {
    details: null,
    loaded: false,
    error: '',
  },
};

const getters: GetterTree<ProjectState, RootState> = {
  details(state: ProjectState): ProjectModel {
    if (state.project.details) {
      return state.project.details;
    }

    return getProjectDetails();
  },

  canvasSettings(state: ProjectState): AllDiagramSettings|null {
    if (state.preferences.details) {
      return state.preferences.details.preferences.canvas;
    }
    return null;
  },

  userRole(state: ProjectState): Roles {
    if (state.project.details) {
      return state.project.details.roleWithinProject;
    }
    return Roles.GUEST;
  },
};

const actions: ActionTree<ProjectState, any> = {
  async loadDetails(context: ActionContext<ProjectState, RootState>, payload: LoadDetailsParams) {
    const url = `/project/${payload.id}`;
    const storable = await loadDetails<ProjectModel>(context.state.project, url, payload.force);

    context.commit('setProjectDetails', storable);
  },

  async loadPreferences(
    context: ActionContext<ProjectState, RootState>,
    payload: LoadDetailsParams,
  ) {
    const url = `/project/${payload.id}/preferences`;
    const storable = await loadDetails<ProjectPreferences>(
      context.state.preferences,
      url,
      payload.force,
    );

    context.commit('setPreferencesDetails', storable);
  },

  async loadSharedPreferences(
    context: ActionContext<ProjectState, RootState>,
    payload: LoadDetailsParams,
  ) {
    const url = `/org/${payload.orgId}/shared/diagram/${payload.id}/preferences`;
    const storable = await loadDetails<ProjectPreferences>(
      context.state.preferences,
      url,
      payload.force,
    );

    context.commit('setPreferencesDetails', storable);
  },

  async addProjectPreferences(context: ActionContext<ProjectState, RootState>, id: string) {
    // create the default project preferences
    await ApiService.put(`/project/${id}/preferences`, {
      preferences: {
        canvas: getDefaultModelData(),
      },
    });
  },

  clear(context: ActionContext<ProjectState, RootState>) {
    context.commit('clear');
  },
};

const mutations: MutationTree<ProjectState> = {
  setProjectDetails(state: ProjectState, storable: Storable<ProjectModel>) {
    state.project.details = storable.details || getProjectDetails();
    state.project.loaded = storable.loaded;
    state.project.error = storable.error;
  },

  setPreferencesDetails(state: ProjectState, storable: Storable<ProjectPreferences>) {
    state.preferences.details = storable.details;
    state.preferences.loaded = storable.loaded;
    state.preferences.error = storable.error;
  },

  clear(state: ProjectState) {
    state.project.details = getProjectDetails();
    state.project.loaded = false;
    state.project.error = '';

    state.project.details = null;
    state.project.loaded = false;
    state.project.error = '';
  },
};

export default {
  namespaced: true,
  state: projectState,
  getters,
  actions,
  mutations,
};
