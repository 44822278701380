<template>
  <q-btn
    v-bind="$attrs"
    v-on="$listeners"
    :ripple="false"
    flat
    dense
    no-caps
    size="sm"
    icon="edit"
    color="primary"
  >
    <q-tooltip v-if="tooltip && !$attrs.disable">
      {{tooltip}}
    </q-tooltip>
  </q-btn>
</template>

<script>
export default {
  name: 'EditBtn',
  props: {
    tooltip: {
      type: String,
      required: false,
    },
  },
};
</script>
