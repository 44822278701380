<template>
  <q-footer>
    <GradientSeparator color="primary-gradient"/>
    <div
      class="flex items-center full-height q-py-md q-px-lg"
    >
      <q-space/>
      <div>
<!--        <router-link class="q-px-sm" to="">About</router-link>-->
<!--        <router-link class="q-px-sm" to="">Privacy Policy</router-link>-->
<!--        <router-link class="q-px-sm" to="">Contact</router-link>-->

        <span
          class="copy inline-block"
          :class="`${$q.screen.lt.sm ? 'q-mt-lg' : 'q-ml-lg'}`"
        >&copy;{{ currentYear }} OBASHI</span>
      </div>
    </div>
  </q-footer>
</template>

<script>
import GradientSeparator from '@/components/GradientSeparator.vue';

export default {
  name: 'Footer',
  components: { GradientSeparator },
  data() {
    return {
      currentYear: '',
    };
  },
  created() {
    const d = new Date();
    this.currentYear = d.getFullYear();
  },
};
</script>

<style lang="scss" scoped>
@import '~quasar-variables';

body {
  footer {
    font-size: 0.79rem;
  }

  &.body--dark {
    footer {
      background-color: $dark;

      a,
      .copy {
        color: $light-grey;
      }
    }
  }

  &:not(.body--dark) {
    footer {
      background: $blue-grey-2;

      a,
      .copy {
        color: $dark-grey;
      }
    }
  }
}
</style>
