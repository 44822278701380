



























import Vue, { PropType } from 'vue';
import { getSocialIconMap } from '@/util/socialNetworks';
import { SocialNetworks, SocialDetailsMap, SocialItem } from '@/types/social';
import ExternalLink from '@/components/ExternalLink.vue';

const socialIconMap = getSocialIconMap();

export default Vue.extend({
  name: 'SocialDetails',
  components: { ExternalLink },
  props: {
    size: {
      type: String,
      default: 'md',
    },
    details: {
      type: Object as PropType<SocialDetailsMap>,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    icons(): SocialItem[] {
      if (!this.details) {
        return [];
      }
      const keys = Object.keys(socialIconMap) as Array<SocialNetworks>;
      return keys.map((key) => {
        const item = socialIconMap[key] as SocialItem;

        item.link = this.details[key];

        return item;
      });
    },
  },
  methods: {
    openLink(icon: SocialItem) {
      if (!icon.link) {
        return;
      }
      window.open(icon.link, '_blank', 'noopener');
    },
  },
});
