import Notify from 'quasar/src/plugins/Notify.js';;
import { mdiCached } from '@quasar/extras/mdi-v5';

type NotifyEvent = {
  detail: ServiceWorkerRegistration;
};

export default {
  refreshing: false,
  registration: {} as ServiceWorkerRegistration,
  notify(e: NotifyEvent): void {
    this.registration = e.detail;

    Notify.create({
      color: 'white',
      textColor: 'blue-grey-10',
      icon: mdiCached,
      message: 'New content is available.',
      caption: 'Please refresh the page to see the changes.',
      position: 'bottom-right',
      timeout: 0,
      ignoreDefaults: true,
      classes: 'new-update-notification',
      actions: [
        {
          label: 'Refresh',
          color: 'info',
          handler: () => {
            this.refreshApp();
          },
        },
        {
          label: 'Dismiss',
          color: 'blue-grey-10',
          handler: () => null,
        },
      ],
    });
  },
  refreshApp(): void {
    if (this.registration.waiting) {
      this.registration.waiting.postMessage({ type: 'SKIP_WAITING' });
    }
  },
  init() {
    document.addEventListener('swUpdated', (e) => {
      this.notify(e as CustomEvent);
    }, { once: true });

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
};
