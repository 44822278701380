<template>
  <div
    :class="`bg-${color}`"
    :style="{ height: `${height}px` }"
    class="gradient-separator"
  ></div>
</template>

<script>
export default {
  name: 'GradientSeparator',
  props: {
    color: {
      type: String,
      default: 'primary',
    },
    height: {
      type: Number,
      default: 2,
    },
  },
};
</script>
