<template>
  <q-input
    v-bind="$attrs"
    v-on="$listeners"
    dense
    outlined
    no-error-icon
    :value="value"
    :error="!!errorMessage"
    :error-message="errorMessage"
    :rules="[ validation ]"
    lazy-rules="ondemand"
    class="code-input"
  />
</template>

<script>
import codeValidation from '@/validation/code';

export default {
  name: 'FieldCode',
  props: {
    value: String,
    errorMessage: String,
    required: Boolean,
  },
  methods: {
    validation(text) {
      if (this.required) {
        return codeValidation.isRequired(text);
      }
      return codeValidation.isValid(text);
    },
  },
};
</script>
