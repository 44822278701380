<template>
  <q-item tag="label" v-ripple dense class="no-padding">
    <q-item-section v-if="label">
      <q-item-label caption>{{ label }}</q-item-label>
    </q-item-section>
    <q-item-section side>
      <q-checkbox
        :disable="disabled"
        color="primary"
        :value="value"
        @input="$emit('input', $event)"
      />
    </q-item-section>
  </q-item>
</template>

<script>
export default {
  name: 'FieldCheckbox',
  props: {
    disabled: Boolean,
    label: String,
    value: Boolean,
  },
};
</script>
