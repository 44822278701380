<template>
  <q-dialog
    v-model="modal"
    class="bg-white test--session-expired"
    persistent
    transition-hide="slide-down"
    transition-show="slide-up"
  >
    <q-card class="q-px-lg q-py-sm">
      <q-card-section>
        <p class="text-subtitle1">Your session has expired</p>
        <span class="text-caption">Please log in to continue</span>
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          label="Log in"
          color="positive"
          :disable="loading"
          :loading="loading"
          @click="closeAndLogout"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'SessionExpiredInfo',
  data() {
    return {
      modal: false,
      loading: false,
    };
  },
  methods: {
    openModal() {
      this.modal = true;
    },
    async closeAndLogout() {
      this.loading = true;
      this.modal = false;
      this.$router
        .replace('/logout')
        .catch((e) => {
          console.log('Navigate to logout error: ', e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
