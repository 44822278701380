<template>
  <q-select
    v-bind="$attrs"
    dense
    outlined
    emit-value
    map-options
    no-error-icon
    option-value="index"
    :value="value"
    @keyup.enter.stop
    @input="$emit('input', $event)"
    :error="!!errorMessage"
    :error-message="errorMessage"
    :required="required"
    :multiple="multiple"
    :options="layersOptions"
    :rules="[ validation ]"
    lazy-rules="ondemand"
  >
    <template #prepend v-if="layerIndex !== null">
      <LayerBadge :layer-index="layerIndex" no-text/>
    </template>
  </q-select>
</template>

<script>
import { isLayerIndex, getLayerMap } from '@/util/layers';
import LayerBadge from '@/components/LayerBadge.vue';

export default {
  name: 'SelectLayer',
  components: { LayerBadge },
  props: {
    multiple: Boolean,
    required: Boolean,
    errorMessage: String,
    value: {
      type: [Number, Array],
    },
  },
  computed: {
    layerIndex() {
      if (isLayerIndex(this.value)) {
        return this.value;
      }
      return null;
    },
  },
  data() {
    return {
      layersOptions: getLayerMap(),
    };
  },
  methods: {
    validation(selected) {
      if (this.required) {
        if (this.multiple && (!selected || !selected.length)) {
          return 'Layer is required';
        }
        if (!this.multiple && Number.isNaN(selected)) {
          return 'Layer is required';
        }
      }

      return true;
    },
  },
};
</script>
