import { Diagram } from 'gojs';
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { loadDetails } from '@/store/storeHelpers';
import { RootState } from '@/store/rootState';
import { Storable } from '@/types/common';
import { DiagramModel, DiagramVersion } from '@/types/diagram';
import { AllDiagramSettings, JSONModel } from '@/bridge/types/diagramModel';
import autoSave from '@/bridge/base/AutoSave';
import { getDiagramFromDiv } from '@/bridge/util/shared';
import { DiagramStatus } from '@/bridge/enums/diagramOptions';

type DiagramState = Storable<DiagramModel> & {
  diagramDivId: string;
};

type LoadDetailsParams = {
  projectId: string;
  diagramId: string;
  orgId?: string;
  versionId?: string;
  force?: boolean;
  shared?: boolean;
}

type SaveDiagram = {
  diagram: Diagram;
  params: LoadDetailsParams;
}

type SaveDiagramSettings = SaveDiagram & { settings: AllDiagramSettings };

const diagramState: DiagramState = {
  // The object is too complex to have a default value
  details: null,
  diagramDivId: '',
  loaded: false,
  error: '',
};

const getters: GetterTree<DiagramState, RootState> = {
  settings(state: DiagramState): AllDiagramSettings|null {
    if (state.details) {
      return state.details.version.checkpoint.model.modelData;
    }
    return null;
  },
  details(state: DiagramState): DiagramModel|null {
    return state.details;
  },
  diagramDivId(state: DiagramState): string {
    return state.diagramDivId;
  },
  model(state: DiagramState): JSONModel|null {
    if (state.details) {
      return state.details.version.checkpoint.model;
    }
    return null;
  },
  version(state: DiagramState): DiagramVersion|null {
    if (state.details) {
      return state.details.version;
    }
    return null;
  },
};

const actions: ActionTree<DiagramState, any> = {
  async loadDetails(context: ActionContext<DiagramState, RootState>, payload: LoadDetailsParams) {
    const { orgId, diagramId, projectId, versionId, force, shared } = payload;
    let url = '';
    if (shared) {
      url = `/org/${orgId}/shared/diagram/${diagramId}/version`;
    } else {
      url = `/project/${projectId}/diagrams/${diagramId}`;
    }
    if (versionId) {
      url = `${url}?versionId=${versionId}`;
    }
    const storable = await loadDetails<DiagramModel>(context.state, url, force);
    context.commit('setDetails', storable);
  },

  async saveDiagram(context: ActionContext<DiagramState, RootState>, payload: SaveDiagram) {
    try {
      await autoSave.save(payload.diagram, payload.params);
      await context.dispatch('loadDetails', { ...payload.params, force: true });
    } catch (e) {
      console.log('saveDiagram err: ', e);
    }
  },

  async saveSettings(
    context: ActionContext<DiagramState, RootState>,
    { diagram, settings, params }: SaveDiagramSettings,
  ) {
    await autoSave.saveSettings(diagram, settings, params);
    await context.dispatch('loadDetails', params);
  },

  async setDiagramDivId(context: ActionContext<DiagramState, RootState>, id: string) {
    context.commit('setDiagramDivId', id);
  },

  async updateStatus(context: ActionContext<DiagramState, RootState>, status: DiagramStatus) {
    context.commit('setStatus', status);
  },

  async updateVersionLabel(context: ActionContext<DiagramState, RootState>, label: string) {
    context.commit('setVersionLabel', label);
  },

  async setDiagram(context: ActionContext<DiagramState, RootState>, diagramDivId = '') {
    const diagram = getDiagramFromDiv(diagramDivId || context.state.diagramDivId);
    context.commit('setDiagram', diagram);
  },

  clear(context: ActionContext<DiagramState, RootState>) {
    context.commit('clear');
  },
};

const mutations: MutationTree<DiagramState> = {
  setDetails(state: DiagramState, s: DiagramState) {
    state.details = s.details;
    state.loaded = s.loaded;
    state.error = s.error;
  },

  setDiagramDivId(state: DiagramState, id: string) {
    state.diagramDivId = id;
  },

  setStatus(state: DiagramState, status: DiagramStatus) {
    if (state.details) {
      state.details.version.status = status;
    }
  },

  setVersionLabel(state: DiagramState, label: string) {
    if (state.details) {
      state.details.version.label = label;
    }
  },

  clear(state: DiagramState) {
    state.diagramDivId = '';
    state.details = null;
    state.loaded = false;
    state.error = '';
  },
};

export default {
  namespaced: true,
  state: diagramState,
  getters,
  actions,
  mutations,
};
