<template>
  <div v-if="error" class="q-mb-md text-negative">{{ error|capitalizeFirstWord }}</div>
</template>

<script>
export default {
  name: 'ApiError',
  props: {
    error: String,
  },
};
</script>
