type LayerColor = {
  primary: string;
  accent: string;
}

type Layer = {
  index: number;
  label: string;
  color: LayerColor;
}

const lm: Readonly<Layer[]> = Object.freeze([
  {
    index: 0,
    label: 'Ownership',
    color: {
      primary: '#d5b7e1',
      accent: '#47145a',
    },
  },
  {
    index: 1,
    label: 'Business Process',
    color: {
      primary: '#e8dce6',
      accent: '#4d2b5a',
    },
  },
  {
    index: 2,
    label: 'Application',
    color: {
      primary: '#ffb0b7',
      accent: '#5a2a2a',
    },
  },
  {
    index: 3,
    label: 'System',
    color: {
      primary: '#c4d8e5',
      accent: '#003a5a',
    },
  },
  {
    index: 4,
    label: 'Hardware',
    color: {
      primary: '#eeea9d',
      accent: '#595a0c',
    },
  },
  {
    index: 5,
    label: 'Infrastructure',
    color: {
      primary: '#abdebe',
      accent: '#005a29',
    },
  },
]);

export const getLayerMap = (): Readonly<Layer[]> => lm;

export function getLayerByIndex(index: number): Readonly<Layer>|null {
  const layer = Object.values(lm)[index];
  if (!layer) {
    return null;
  }
  return { ...layer };
}

export function isLayerIndex(value: any): boolean {
  if (Array.isArray(value)) {
    return false;
  }

  if (Number.isNaN(parseInt(value, 10))) {
    return false;
  }
  return (value >= 0 && value <= 5);
}
