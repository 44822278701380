import * as go from 'gojs';
import { AllDiagramSettings } from '@/bridge/types/diagramModel';
import { isTopLevelCategory, reLayoutDiagram } from '@/bridge/util/shared';
import { layerUtils } from '@/bridge/base/layerInfo';
import { NodeCategory } from '@/bridge/enums/partCategories';
import { capitalizeAllWords } from '@/util/strings';
import { anchorElements } from '@/bridge/element/anchorElements';

function addNewElement(newElement: go.Part, group: go.Group, md: AllDiagramSettings) {
  if (!group.diagram) {
    return;
  }

  if (!layerUtils.isLayer(newElement.data.layer)) {
    group.addMembers(group.diagram.selection, true);

    group.diagram.commit((d) => {
      const m = d.model;
      // Update new node to match layer it was dropped in
      m.set(newElement.data, 'label', capitalizeAllWords(group.data.label));

      const layerSettings = md.layers[group.data.id];
      if (layerSettings && layerSettings.elementSize) {
        m.set(newElement.data, 'size', layerSettings.elementSize);
      } else {
        const { element } = md;
        m.set(newElement.data, 'size', element.size);
      }

      m.set(newElement.data, 'color', md.layers[group.data.id].color);
    }, null);

    if (group.diagram && (group.diagram as any)._isAnchored) {
      anchorElements(group.diagram, true, newElement);
    }

    newElement.updateTargetBindings();
  }
}

/**
 * Here layerName refers to go.Layer
 * The shapes have the layer set to 'Grid' when they are dragged from the palette
 */
function resetLayerName(diagram: go.Diagram) {
  diagram.nodes.each((n) => {
    if (isTopLevelCategory(n.category) || n.category === NodeCategory.ELEMENT) {
      return;
    }
    // Reset layer name only for shapes
    n.layerName = '';
  });
}

export default async function finishDrop(e: go.InputEvent, grp: go.Group, md: AllDiagramSettings) {
  const newElement: go.Part | null = e.diagram.selection.first();
  if (!newElement || !grp.diagram) {
    return;
  }

  // Keep the nodes on the same layer when moving
  if (newElement.category !== NodeCategory.ELEMENT) {
    resetLayerName(e.diagram);
    return;
  }

  if (!e.shift && newElement.data.group > -1) {
    return;
  }

  e.handled = true;
  const elGrp = newElement.data.group;
  const validDrop = grp.data.id === elGrp || elGrp === undefined;

  if (!validDrop) {
    e.diagram.currentTool.doCancel();
    (e.diagram.toolManager.draggingTool as any).clearGuidelines();
    return;
  }

  if (!elGrp) {
    addNewElement(newElement, grp, md);
    reLayoutDiagram(e.diagram);
    resetLayerName(e.diagram);
  }
}
