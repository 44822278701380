<template>
  <lazy-load :url="`/org/${orgId}/people`">
    <template #fallback>This organisation doesn't have any users</template>

    <template #default="{ data }">
      <AppAvatar
        v-for="(user, index) in data"
        size="48px"
        :class="{ 'q-mr-sm': index < data.length - 1 }"
        class="bordered"
        :key="user.id"
        :avatar="getUserAvatar(user)"
        :label="fullName(user)"
      />
    </template>
  </lazy-load>
</template>

<script>
import LazyLoad from '@/components/LazyLoad.vue';
import AppAvatar from '@/components/avatar/Avatar.vue';

export default {
  name: 'OrgUsers',
  components: { AppAvatar, LazyLoad },
  props: {
    orgId: {
      type: String,
      required: true,
    },
    showText: Boolean,
  },
  methods: {
    fullName(user) {
      return `${user.forename} ${user.surname}`;
    },
    getUserAvatar(user) {
      return user.avatar;
    },
  },
};
</script>
