/* eslint-disable no-console */
import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      // console.log('App is being served from cache');
    },
    registered(registration: ServiceWorkerRegistration) {
      // Check for updates every half-hour
      setInterval(async () => {
        await registration.update();
      }, 30 * 60 * 1000);
    },
    cached() {
      // console.log('Content has been cached for offline use.');
    },
    updatefound() {
      // console.log('New content is downloading.');
    },
    updated(registration: ServiceWorkerRegistration) {
      // new content available - refresh
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration }),
      );
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error: Error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
