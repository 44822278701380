import * as go from 'gojs';
import { NodeCategory } from '@/bridge/enums/partCategories';
import { NodeLocation, NodeSize } from '@/bridge/types/diagramModel';

export function reLayoutDiagram(diagram: go.Diagram) {
  diagram.layout.invalidateLayout();
  diagram.findTopLevelGroups().each((g) => {
    if (g.category === NodeCategory.ROOT && g.layout) {
      g.layout.invalidateLayout();
    }
  });
  diagram.layoutDiagram();
}

export function getNormalizedScale(e: go.DiagramEvent) {
  if (e.diagram.scale > 10) {
    e.diagram.scale = 10;
  } else if (e.diagram.scale < 0.01) {
    e.diagram.scale = 0.01;
  }
  return e.diagram.scale;
}

export function pointParse(stringPoint: string) {
  if (!stringPoint) {
    throw new Error('Argument `stringPoint` is required');
  }

  const [x, y] = stringPoint.split(' ');
  const intX = Math.round(parseInt(x, 10));
  const intY = Math.round(parseInt(y, 10));

  if (Number.isNaN(intX) || Number.isNaN(intY)) {
    throw new TypeError('Invalid argument `stringPoint`');
  }

  return new go.Point(intX, intY);
}

export function pointStringify(point: go.Point) {
  return `${Math.round(point.x)} ${Math.round(point.y)}`;
}

export function pointListParse(stringList: string) {
  if (!stringList) {
    throw new Error('Argument `stringList` is required');
  }

  const pointArr = JSON.parse(stringList);
  const list = new go.List();

  for (let i = 0; i < pointArr.length; i += 2) {
    const pt = new go.Point(
      Math.round(pointArr[i]),
      Math.round(pointArr[i + 1]),
    );
    list.add(pt);
  }
  return list;
}

export function pointListStringify(list: go.List<go.Point>) {
  const arr: number[] = [];

  list.each((p) => {
    arr.push(Math.round(p.x));
    arr.push(Math.round(p.y));
  });

  return arr;
}

export function isTopLevelCategory(category: string): boolean {
  return ([NodeCategory.ROOT, NodeCategory.LAYER] as string[]).includes(category);
}

export function locToGoPoint(l: NodeLocation): go.Point {
  return new go.Point(l.x, l.y);
}
export function pointToNodeLoc(p: go.Point): NodeLocation {
  return { x: p.x, y: p.y };
}

export function toGoSize(s: NodeSize): go.Size {
  return new go.Size(s.width, s.height);
}
export function toNodeSize(s: go.Size): NodeSize {
  return { width: s.width, height: s.height };
}

export function getDiagramFromDiv(divId: string): go.Diagram|null {
  return go.Diagram.fromDiv(divId);
}
