



























import Vue, { VueConstructor } from 'vue';
import notify from '@/util/notify';
import formSubmit from '@/mixins/formSubmit';
import AppModal from '@/components/AppModal.vue';
import ApiError from '@/components/ApiError.vue';
import PhotoEditor from '@/components/photoEditor/PhotoEditor.vue';
import PhotoEditorShortcuts from '@/components/photoEditor/PhotoEditorShortcuts.vue';
import Btn from '@/components/buttons/Btn.vue';
import { FormWrapperRequest } from '@/types/form';
import { PhotoEditorModel } from '@/components/photoEditor/editorUtils';
import { uploadImage } from '@/util/upload';

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'AvatarUpload',
  components: {
    Btn,
    ApiError,
    PhotoEditor,
    PhotoEditorShortcuts,
    AppModal,
  },
  mixins: [formSubmit],
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  computed: {
    request(): FormWrapperRequest {
      return {
        callback: () => uploadImage(this.model, this.url),
      };
    },
  },
  data() {
    return {
      model: {} as PhotoEditorModel,
    };
  },
  methods: {
    async onSubmit(data: PhotoEditorModel) {
      if (!data.blob) {
        notify.info('Could not process image');
        return;
      }

      this.model = data;
      await this.submit(this.request, this.$refs.form);

      if (this.hasError) {
        return;
      }

      (this.$refs.modal as any).close();
      notify.success('Image updated');
      this.$emit('submit');
    },
  },
});
