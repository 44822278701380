import { SocialItem, SocialDetailsMap } from '@/types/social';

type SocialIconMap = Partial<Record<keyof SocialDetailsMap, SocialItem>>;

export function getSocialIconMap(isCompany = false): SocialIconMap {
  const map: SocialIconMap = {
    facebook: {
      name: 'fab fa-facebook-f',
      color: 'facebook',
      link: 'facebook.com/',
    },
    linkedin: {
      name: 'fab fa-linkedin-in',
      color: 'linkedin',
      link: `linkedin.com/${isCompany ? 'company/' : 'in/'}`,
    },
    twitter: {
      name: 'fab fa-twitter',
      color: 'twitter',
      link: 'twitter.com/',
    },
    instagram: {
      name: 'fab fa-instagram',
      color: 'instagram',
      link: 'instagram.com/',
    },
  };

  if (isCompany) {
    map.website = {
      name: 'fas fa-link',
      color: 'blue-5',
      link: '',
    };
  }

  return map;
}
