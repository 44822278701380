import * as go from 'gojs';
import { GuideCategory, NodeCategory, CalloutCategory } from '@/bridge/enums/partCategories';
import ApiService from '@/services/api.service';
import EventBus from '@/util/EventBus';
import {
  AllDiagramSettings,
  UsedByAsset,
  ElementData,
  GuideData,
  JSONModel,
  LayerData,
  ShapeData,
  LinkData,
  CalloutData,
} from '@/bridge/types/diagramModel';
import { onUpdateDiagramSettings } from '@/bridge/settings/common';
import { updateLanes } from '@/bridge/poolLayout/layerTemplate';
import { getDefaultModelData } from '@/bridge/settings/modelData';

interface NodeWithAsset {
  id: number;
  assetId?: string|null;
}

export function addAssetToMap(data: NodeWithAsset, map: UsedByAsset) {
  const { assetId } = data;

  if (assetId) {
    // The asset is not part of the map. Create a new entry.
    if (!map[assetId]) {
      map[assetId] = { usedBy: [] };
    }

    if (!map[assetId].usedBy.includes(data.id)) {
      map[assetId].usedBy.push(data.id);
    }
  }
}

class AutoSave extends EventBus {
  timeout: any;
  interval: number;
  idle: boolean;
  first: boolean;
  error: boolean;
  errorMessage: string;
  diagramId: string;

  EVENT_SAVE = 'save';
  EVENT_DONE_SAVE = 'saved';

  constructor(interval: number) {
    super();

    this.timeout = null;
    this.interval = interval * 1000;
    this.idle = true;
    this.first = true;
    this.error = false;
    this.errorMessage = '';
    this.diagramId = '';
  }

  init(id: string) {
    this.diagramId = id;
  }

  start(diagram: go.Diagram, params: Record<any, any>) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.first = false;
    this.idle = false;
    this.error = false;
    this.errorMessage = '';
    this.timeout = setTimeout(() => this.save(diagram, params), this.interval);
  }

  async cancelAndSave(diagram: go.Diagram, params: Record<any, any>) {
    this.idle = true;
    // Save only if there is an ongoing timeout
    if (this.timeout) {
      clearTimeout(this.timeout);
      await this.save(diagram, params);
    }
  }

  async saveSettings(diagram: go.Diagram, settings: AllDiagramSettings, params: Record<any, any>) {
    try {
      diagram.model.modelData = settings;
      onUpdateDiagramSettings(diagram);
      updateLanes(diagram, settings.diagram);
      await this.save(diagram, params);
    } catch (e) {
      console.log('Save settings error: ', e);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  getDiagramData(diagram: go.Diagram): JSONModel {
    // We need to see model.toJson to get the correct mode
    // If we access the nodeDataArray directly, we'll also get
    // some GoJS specific properties like __goHashId
    const json = JSON.parse(diagram.model.toJson());
    const layerDataArray: LayerData[] = [];
    const nodeDataArray: ElementData[] = [];
    const shapeDataArray: ShapeData[] = [];
    const guideDataArray: GuideData[] = [];
    const calloutDataArray: CalloutData[] = [];

    const linkAssets: UsedByAsset = {};
    const elementAssets: UsedByAsset = {};

    json.nodeDataArray.forEach((node: { category: any }) => {
      const isGuide = node.category === GuideCategory.V_GUIDE
        || node.category === GuideCategory.H_GUIDE;

      if (node.category === NodeCategory.LAYER) {
        layerDataArray.push(node as LayerData);
      } else if (node.category === NodeCategory.ELEMENT) {
        nodeDataArray.push(node as ElementData);
        addAssetToMap(node as ElementData, elementAssets);
      } else if (node.category === CalloutCategory.DEFAULT) {
        calloutDataArray.push(node as CalloutData);
      } else if (isGuide) {
        guideDataArray.push(node as GuideData);
      } else if (node.category !== NodeCategory.ROOT) {
        shapeDataArray.push(node as ShapeData);
      }
    });

    json.linkDataArray.forEach((link: LinkData) => {
      addAssetToMap(link, linkAssets);
    });

    return {
      modelData: json.modelData || getDefaultModelData(),
      layerDataArray,
      nodeDataArray,
      shapeDataArray,
      guideDataArray,
      calloutDataArray,
      linkAssets,
      elementAssets,
      linkDataArray: json.linkDataArray || [],
    };
  }

  async save(diagram: go.Diagram, params: Record<any, any>) {
    try {
      const model = this.getDiagramData(diagram);

      const response = await ApiService.put(
        `/project/${params.projectId}/diagram/${params.diagramId}/version/model?versionId=${params.versionId}`,
        { model },
      );

      this.$emit(this.EVENT_DONE_SAVE, response);

      // reset the state
      this.timeout = null;
      this.idle = true;
    } catch (e: any) {
      this.idle = true;
      this.error = true;

      if (e.error) {
        this.errorMessage = e.error;
      } else if (e.message) {
        this.errorMessage = e.message;
      } else {
        this.errorMessage = 'There was an error on the server. Please try again later';
      }
    }
  }

  emitSave() {
    this.$emit(this.EVENT_SAVE);
  }
}

// Interval in seconds
export default new AutoSave(60 * 3);
