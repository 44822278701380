import { render, staticRenderFns } from "./PhotoEditorControls.vue?vue&type=template&id=e8c61412&scoped=true&"
import script from "./PhotoEditorControls.vue?vue&type=script&lang=ts&"
export * from "./PhotoEditorControls.vue?vue&type=script&lang=ts&"
import style0 from "./PhotoEditorControls.vue?vue&type=style&index=0&id=e8c61412&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8c61412",
  null
  
)

export default component.exports
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QColor from 'quasar/src/components/color/QColor.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QMenu,QColor});
