import Vue from 'vue';
import VueApexCharts from 'vue-apexcharts';
import App from '@/App.vue';
import '@/registerServiceWorker';
import router from '@/router';
import store from '@/store';
import '@/plugins/quasar';
import authPlugin from '@/plugins/auth/auth';
import { formatDate, formatDatetime } from '@/util/date';

import '@/styles/main.scss';
import { capitalizeAllWords, capitalizeFirstWord } from '@/util/strings';

Vue.use(authPlugin);
Vue.use(VueApexCharts);

Vue.filter('formatDate', formatDate);
Vue.filter('formatDatetime', formatDatetime);
Vue.filter('capitalizeFirstWord', capitalizeFirstWord);
Vue.filter('capitalizeAllWords', capitalizeAllWords);

// Don't warn about using the dev globalSettingsVersion of Vue in development.
Vue.config.productionTip = process.env.NODE_ENV === 'production';

const VApp = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

// If running e2e tests...
if (window.Cypress) {
  // Ensure tests fail when Vue emits an error.
  Vue.config.errorHandler = window.Cypress.cy.onUncaughtException;

  window.VApp = VApp;
}
