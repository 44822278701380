import { Route } from 'vue-router';
import FormTabs from '@/views/forms/FormTabs.vue';
import store from '@/store';
import { ResourceType } from '@/types/resource';

export default [
  {
    path: 'form/:formId',
    component: FormTabs,
    meta: { ignoreScrollPosition: true },
    async beforeEnter(to: Route, from: Route, next: () => void) {
      const { projectId, formId } = to.params;
      await store.dispatch('activeItemModule/loadDetails', {
        force: true,
        url: `/project/${projectId}/forms/${formId}`,
        type: 'form',
      });
      next();
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "view-project-form" */ '@/views/forms/ViewForm.vue'),
      },
      {
        path: 'assets',
        component: () => import(/* webpackChunkName: "list-assets" */ '@/views/resources/ListResources.vue'),
        props: {
          resourceType: ResourceType.ASSET,
          listTitle: 'Assets using this form',
          params: (route: Route) => ({
            formIds: route.params.formId,
          }),
        },
      },
      {
        path: 'connections',
        component: () => import(/* webpackChunkName: "list-assets" */ '@/views/resources/ListResources.vue'),
        props: {
          resourceType: ResourceType.CONNECTION,
          listTitle: 'Connections using this form',
          params: (route: Route) => ({
            formIds: route.params.formId,
          }),
        },
      },
      {
        path: 'dependencies',
        component: () => import(/* webpackChunkName: "list-assets" */ '@/views/resources/ListResources.vue'),
        props: {
          resourceType: ResourceType.DEPENDENCY,
          listTitle: 'Dependencies using this form',
          params: (route: Route) => ({
            formIds: route.params.formId,
          }),
        },
      },
    ],
  },
];
