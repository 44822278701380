import { Roles } from '@/types';

export type RoleInformation = {
  name: string;
  type: Roles;
  description: string;
  permissionsIndex: number;
};

export const availablePermissions: string[] = [
  'Subscription Management',
  'Organisation Management',
  'Role Management',
  'User Management',
  'Division Management',
  'Project Management',
  'Project Content Create/Update/Delete',
  'Project Content View',
  'Shared Content View',
];

export const availableRoles: RoleInformation[] = [
  {
    name: 'Invitation Pending',
    type: -1,
    description: '',
    permissionsIndex: 200,
  },
  {
    name: 'Owner',
    type: Roles.OWNER,
    description: 'The Owner has full control over all entities within their organisation and subscription.',
    permissionsIndex: 0,
  },
  {
    name: 'Admin',
    type: Roles.ADMIN,
    description: 'The Admin has control over all entities within their organisation.',
    permissionsIndex: 1,
  },
  {
    name: 'Project Admin',
    type: Roles.PRODUCT_OWNER,
    description: 'The Project Admin can fully manage any project\'s members, files and workflows.',
    permissionsIndex: 4,
  },
  {
    name: 'Project Manager',
    type: Roles.PROJECT_MANAGER,
    description: 'The Project Manager can only manage projects they have been assigned to.',
    permissionsIndex: 5,
  },
  {
    name: 'Contributor',
    type: Roles.CONTRIBUTOR,
    description: 'The Contributor can edit and model projects where they are members.',
    permissionsIndex: 6,
  },
  {
    name: 'Viewer',
    type: Roles.VIEWER,
    description: 'The Viewer can view projects where they are members.',
    permissionsIndex: 7,
  },
  {
    name: 'Guest',
    type: Roles.GUEST,
    description: 'The guest can view diagrams shared with them.',
    permissionsIndex: 8,
  },
];
