<template>
  <lazy-load :url="`/project/${projectId}/people`">
    <template #fallback>This Project doesn't have any people</template>

    <template #default="{ data }">
      <div class="row">
        <q-item
          class="col-12 col-sm-6 q-mb-md"
          v-for="user in data"
          :key="user.id"
        >
          <q-item-section avatar>
            <AppAvatar
              :avatar="user.avatar"
              :label="user.displayName || `${user.forename} ${user.surname}`"
              size="40px"
            />
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ fullName(user) }}</q-item-label>
            <q-item-label v-if="user.role" caption>{{ getRole(user.role) }}</q-item-label>
          </q-item-section>
        </q-item>
      </div>
    </template>
  </lazy-load>
</template>

<script type="ts">
import LazyLoad from '@/components/LazyLoad.vue';
import AppAvatar from '@/components/avatar/Avatar.vue';
import { availableRoles } from '@/components/admin/org/roleInformation';

export default {
  name: 'ProjectPeople',
  components: { AppAvatar, LazyLoad },
  props: {
    projectId: {
      type: String,
      required: true,
    },
    showText: Boolean,
    availableRoles,
  },
  computed: {
    orgId() {
      return this.$route.params.orgId;
    },
  },
  methods: {
    fullName(user) {
      return `${user.forename} ${user.surname}`;
    },
    getRole(role) {
      return availableRoles.find((r) => r.type === role).name;
    },
  },
};
</script>
