import { Validation } from '@/validation/validation';
import { capitalizeFirstWord } from '@/util/strings';

export type NumericOptions = {
  name: string
  minValue: number
  maxValue: number
}

const defaultOptions: NumericOptions = {
  name: 'number',
  minValue: Number.MIN_SAFE_INTEGER,
  maxValue: Number.MAX_SAFE_INTEGER,
};

class Numeric extends Validation {
  public name = 'Number';
  private readonly minValue: number;
  private readonly maxValue: number;

  constructor(options: NumericOptions = defaultOptions) {
    super();

    this.name = options.name || defaultOptions.name;
    this.minValue = options.minValue || defaultOptions.minValue;
    this.maxValue = options.maxValue || defaultOptions.maxValue;
  }

  get isValidRangeError() {
    const label = capitalizeFirstWord(this.name.toLowerCase());
    if (this.minValue === Number.MIN_SAFE_INTEGER && this.maxValue === Number.MAX_SAFE_INTEGER) {
      return 'Please enter a valid number';
    }
    if (this.minValue > Number.MIN_SAFE_INTEGER && this.maxValue === Number.MAX_SAFE_INTEGER) {
      return `${label} must be greater than or equal to ${this.minValue}`;
    }
    if (this.minValue === Number.MIN_SAFE_INTEGER && this.maxValue < Number.MAX_SAFE_INTEGER) {
      return `${label} must be smaller than or equal to ${this.maxValue}`;
    }
    return `${label} must be between ${this.minValue} and ${this.maxValue}`;
  }

  get isValidError() {
    return `${this.name} must be a number`;
  }

  get isRequiredError() {
    return `${this.name} is required`;
  }

  isValid(x: any): boolean | string {
    const val = parseFloat(x);
    if (Number.isNaN(val)) {
      return this.isValidError;
    }

    const min = parseInt(`${this.minValue}`, 10);
    const max = parseInt(`${this.maxValue}`, 10);

    if (Number.isNaN(val)) {
      return this.isValidError;
    }

    if (val < min || val > max) {
      return this.isValidRangeError;
    }

    return true;
  }
}

export default Numeric;
