














































import Vue, { PropType, VueConstructor } from 'vue';
import formSubmit from '@/mixins/formSubmit';
import { ProjectForm } from '@/types/project';
import { FormWrapperRequest } from '@/types/form';
import Btn from '@/components/buttons/Btn.vue';
import ApiError from '@/components/ApiError.vue';
import AppModal from '@/components/AppModal.vue';
import FieldLabel from '@/components/fields/FieldLabel.vue';
import FieldDescription from '@/components/fields/FieldDescription.vue';
import EditBtn from '@/components/buttons/EditBtn.vue';
import FieldCode from '@/components/fields/FieldCode.vue';
import SelectDivisions from '@/views/projects/SelectDivisions.vue';

function getDefaultModel(project?: ProjectForm) {
  const model: ProjectForm = {
    label: '',
    description: '',
    code: '',
    divisions: [],
  };

  if (project) {
    model.label = project.label;
    model.code = project.code;
    model.description = project.description;
    model.divisions = project.divisions?.map((d: any) => d.id);
  }

  return model;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof formSubmit>>).extend({
  name: 'ProjectForm',
  components: {
    SelectDivisions,
    FieldCode,
    EditBtn,
    Btn,
    AppModal,
    ApiError,
    FieldLabel,
    FieldDescription,
  },
  mixins: [formSubmit],
  props: {
    project: {
      type: Object as PropType<ProjectForm>,
      required: false,
    },
  },
  computed: {
    isEditForm(): boolean {
      return this.project && !!this.project.label;
    },
    title(): string {
      const action = this.isEditForm ? 'Edit' : 'New';
      return `${action} project`;
    },
    request(): FormWrapperRequest {
      const { orgId, projectId } = this.$route.params;

      const req: FormWrapperRequest = {
        config: {
          data: this.model,
          method: 'post',
          url: `/org/${orgId}/project`,
        },
      };

      if (this.isEditForm && req.config) {
        req.config.method = 'put';
        req.config.url = `/project/${projectId}`;
      }

      return req;
    },
  },
  data() {
    return {
      model: {} as ProjectForm,
    };
  },
  methods: {
    async open() {
      this.setDefaultModel();
      (this.$refs.modal as any).open();
    },
    async onSubmit() {
      this.error = '';

      const resp = await this.submit(this.request, this.$refs.form);
      if (this.hasError) {
        return;
      }
      this.$emit('submit', resp);
      (this.$refs.modal as any).close();
      this.notify(`Project ${this.isEditForm ? 'edited' : 'created'}`);
    },
    setDefaultModel() {
      this.model = getDefaultModel(this.project);
    },
    onClose() {
      this.model = {} as ProjectForm;
    },
  },
});
