import { Route } from 'vue-router';
import ResourceTabs from '@/views/resources/ResourceTabs.vue';
import { ResourceType } from '@/types/resource';
import { DiagramType } from '@/bridge/enums/diagramOptions';

export default [
  {
    path: 'connection/:resourceId',
    component: ResourceTabs,
    meta: { ignoreScrollPosition: true },
    props: {
      resourceType: ResourceType.CONNECTION,
      tabs: [
        { label: 'Overview', route: '' },
        { label: 'Files', route: 'files' },
        { label: 'BIT', route: 'bit' },
        { label: 'DAV', route: 'dav' },
        // We might need more tabs to show stats, who's using the connection and so on.
      ],
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "view-connection" */ '@/views/resources/ViewResource.vue'),
        props: {
          resourceType: ResourceType.CONNECTION,
        },
      },
      {
        path: 'files',
        component: () => import(/* webpackChunkName: "view-asset" */ '@/views/resources/files/ListFilesForResource.vue'),
      },
      {
        path: 'bit',
        component: () => import(/* webpackChuckName: "list-bits" */ '@/views/diagram/ListDiagrams.vue'),
        props: {
          listTitle: 'B&IT using this asset',
          diagramType: DiagramType.BIT,
          params: (route: Route) => ({
            usedBy: route.params.resourceId,
          }),
        },
      },
      {
        path: 'dav',
        component: () => import(/* webpackChuckName: "list-davs" */ '@/views/diagram/ListDiagrams.vue'),
        props: {
          listTitle: 'DAV using this asset',
          diagramType: DiagramType.DAV,
          params: (route: Route) => ({
            usedBy: route.params.resourceId,
          }),
        },
      },
    ],
  },
];
