import Vue from 'vue';

type EventCallback = (...args: any) => void;

/**
 * This is mirroring Vue event bus but we could
 * easily change to something else
 */
interface VueEvents {
  $on(event: string | string[], callback: EventCallback): this;
  $once(event: string | string[], callback: EventCallback): this;
  $off(event: string | string[], callback?: EventCallback): this;
  $emit(event: string, ...args: any[]): this;
}

/**
 * This can be used by different classes that require an event bus
 * This way we avoid conflicts between events and it's easier to follow
 * where the event was emitted from or who listens to an event.
 */
export default class EventBus implements VueEvents {
  protected bus = new Vue();

  $on(event: string | string[], callback: EventCallback): this {
    this.bus.$on(event, callback);
    return this;
  }

  $once(event: string | string[], callback: EventCallback): this {
    this.bus.$once(event, callback);
    return this;
  }

  $off(event?: string | string[], callback?: EventCallback): this {
    this.bus.$off(event, callback);

    return this;
  }

  $emit(event: string, ...args: any[]): this {
    this.bus.$emit(event, ...args);

    return this;
  }
}
