





































import Vue, { PropType } from 'vue';
import { Breadcrumbs, PageTab } from '@/types/common';
import Page from '@/layouts/Page.vue';
import ApiError from '@/components/ApiError.vue';

export default Vue.extend({
  name: 'PageTabs',
  components: {
    ApiError,
    Page,
  },
  props: {
    apiError: String,
    tabs: {
      type: Array as PropType<PageTab[]>,
      required: true,
    },
    breadcrumbs: {
      type: Array as PropType<Breadcrumbs[]>,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedTab: '',
    };
  },
  methods: {
    getUrl(route: string): string {
      return `${this.baseUrl}/${route}`;
    },
  },
  created() {
    this.selectedTab = this.tabs[0].label;
  },
});
