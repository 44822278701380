<template>
  <q-list>
    <q-item>
      <q-item-section class="text-weight-bold">Zoom In</q-item-section>
      <q-item-section side>
        <kbd>i</kbd>
      </q-item-section>
    </q-item>

    <q-item>
      <q-item-section class="text-weight-bold">Zoom Out</q-item-section>
      <q-item-section side>
        <kbd>o</kbd>
      </q-item-section>
    </q-item>

    <q-item>
      <q-item-section class="text-weight-bold">Rotate Left</q-item-section>
      <q-item-section side>
        <kbd>l</kbd>
      </q-item-section>
    </q-item>

    <q-item>
      <q-item-section class="text-weight-bold">Rotate Right</q-item-section>
      <q-item-section side>
        <kbd>r</kbd>
      </q-item-section>
    </q-item>

    <q-item>
      <q-item-section class="text-weight-bold">Crop</q-item-section>
      <q-item-section side>
        <kbd>c</kbd>
      </q-item-section>
    </q-item>

    <q-item>
      <q-item-section class="text-weight-bold">Move image</q-item-section>
      <q-item-section>
        <div class="text-right">
          <kbd>&larr;</kbd>
          <kbd>&uarr;</kbd>
          <kbd>&rarr;</kbd>
          <kbd>&darr;</kbd>
        </div>
      </q-item-section>
    </q-item>
  </q-list>
</template>

<script>
export default {
  name: 'PhotoEditorShortcuts',
};
</script>
