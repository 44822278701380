import { AllDiagramSettings, LayerSettings } from '@/bridge/types/diagramModel';

const ignoreKeys: Partial<keyof AllDiagramSettings>[] = [
  'meta',
  'nextNodeId',
  'nextLinkId',
];

function equalDiagramPreferences(p1: any, p2: any) {
  let equal = true;

  Object.keys(p1).forEach((group: any) => {
    if (ignoreKeys.includes(group) || !equal) return;

    if (group === 'layers') {
      p1.layers.forEach((layer: LayerSettings, j: number) => {
        if (!equal) return;

        equal = equalDiagramPreferences(layer, p2.layers[j]);
      });
    } else if (typeof p1[group] === 'object') {
      Object.keys(p1[group]).forEach((pref) => {
        if (!equal) return;

        // p2 doesn't have the same properties as p1
        if (!p2[group] || !p2[group][pref]) {
          equal = false;
          return;
        }

        if (typeof p1[group][pref] === 'object') {
          // example p1.element.size.width
          equal = equalDiagramPreferences(p1[group][pref], p2[group][pref]);
        } else if (p1[group][pref] !== p2[group][pref]) {
          // example p1.diagram.backgroundColour
          equal = false;
        }
      });
    }
  });

  return equal;
}

export function settingsService(preferences: AllDiagramSettings) {
  // keep track of the initial data
  const initialPref = JSON.parse(JSON.stringify(preferences));

  return {
    settingsChanged(newPref: AllDiagramSettings): boolean {
      return !equalDiagramPreferences(newPref, initialPref);
    },
    updateVersion(p: AllDiagramSettings) {
      if (!this.settingsChanged(p)) {
        return;
      }

      if (p.meta.globalSettingsVersion === initialPref.meta.globalSettingsVersion) {
        p.meta.globalSettingsVersion += 1;
      } else {
        p.meta.globalSettingsVersion = initialPref.meta.globalSettingsVersion;
      }

      p.meta.hasLocalSettings = false;
      p.meta.showUpdateNotification = true;
      p.meta.skipVersion = p.meta.globalSettingsVersion;
    },
  };
}
