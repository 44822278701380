export enum NodeCategory {
  ROOT = 'root',
  LAYER = 'layer',
  ELEMENT = 'element',
  PALETTE_SEPARATOR = 'paletteSeparator',
}

export enum ShapeCategory {
  TEXT = 'text',
  CLOUD = 'cloud',
}

// We might implement other types
export enum CalloutCategory {
  DEFAULT = 'callout',
}

export enum GuideCategory {
  H_GUIDE = 'hGuide',
  V_GUIDE = 'vGuide',
}

export enum LinkCategory {
  CONNECTION = 'connection',
  DEPENDENCY = 'dependency',
}
