


























































































import Vue, { PropType } from 'vue';
import PhotoEditorButton from '@/components/photoEditor/PhotoEditorButton.vue';
import {
  getBlob,
  getCropper,
  PhotoEditorModel,
  useKeyboardShortcuts,
} from '@/components/photoEditor/editorUtils';
import Btn from '@/components/buttons/Btn.vue';

export default Vue.extend({
  name: 'PhotoEditorControls',
  components: { Btn, PhotoEditorButton },
  props: {
    imageData: {
      type: Object as PropType<PhotoEditorModel>,
      required: true,
    },
  },
  data() {
    return {
      bgColor: 'white',
      showCropper: false,
      cropper: null,
      cropped: false,
      cropping: false,
      previousData: {} as PhotoEditorModel,
      initial: true,
      loading: false,
    };
  },
  methods: {
    zoomIn() {
      (this.cropper as any).zoom(0.1);
    },
    zoomOut() {
      (this.cropper as any).zoom(-0.1);
    },
    rotateLeft() {
      (this.cropper as any).rotate(-90);
    },
    rotateRight() {
      (this.cropper as any).rotate(90);
    },
    move(x: number, y: number) {
      (this.cropper as any).move(x, y);
    },
    start(fullSize = false) {
      if (this.cropped || this.cropper) {
        return;
      }

      (this.cropper as any) = getCropper(this.$refs.image as HTMLImageElement, fullSize);
      // Start is called when by image element load event or toggleCropper.
      // Initially we want to load the full size.
      // If the user clicks on crop and revert, the image element will call start again
      // but this time it should be in edit mode
      this.initial = false;
    },
    stop() {
      if (this.cropper) {
        (this.cropper as any).destroy();
        this.cropper = null;
      }
    },
    crop() {
      if (this.cropping) {
        this.cropped = true;
        this.cropping = false;

        this.previousData = { ...this.imageData };

        getBlob(this.cropper as any, (blob) => {
          if (blob) {
            this.update({
              blob,
              blobUrl: URL.createObjectURL(blob),
            });
          }
          this.stop();
        }, this.bgColor);
      }
    },
    restore() {
      if (this.cropped) {
        this.previousData.blob = this.imageData.blob;
        this.update(this.previousData);
        this.cropped = false;
        this.previousData = {
          name: '',
          blobUrl: '',
          blob: null,
          fileType: '',
        };
      }
    },
    reset() {
      this.stop();
      this.cropped = false;
      this.cropping = false;
      URL.revokeObjectURL(this.previousData.blobUrl);
      this.previousData = {
        name: '',
        blobUrl: '',
        blob: null,
        fileType: '',
      };

      this.update({ blobUrl: '', name: '', blob: null, fileType: '' });
      this.toggleCropper(false);
    },
    update(data: Partial<PhotoEditorModel>) {
      this.$emit('update', data);
    },
    onCrop({ detail }: CustomEvent) {
      if (detail.width > 0 && detail.height > 0 && !this.cropping) {
        this.cropping = true;
      }
    },
    done() {
      this.loading = true;
      this.$emit('done');
    },
    saveWithoutCrop() {
      this.loading = true;
      getBlob(this.cropper as any, (blob) => {
        if (blob) {
          this.$emit('done', {
            blob,
            blobUrl: URL.createObjectURL(blob),
          });
        }
        this.stop();
      }, this.bgColor);
    },
    toggleCropper(fullSize: boolean) {
      this.stop();
      this.start(fullSize);
      this.showCropper = !fullSize;
    },
    onKeydown(e: any) {
      useKeyboardShortcuts(e, this.cropper as any, this.crop.bind(this));
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown);
    (this.$refs.image as any).addEventListener('crop', this.onCrop);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onKeydown);
    (this.$refs.image as any).removeEventListener('crop', this.onCrop);
    this.stop();
  },
});
