








import Vue from 'vue';
import { Breadcrumbs, PageTab } from '@/types/common';
import { OrgModel } from '@/types/org';
import { ProjectModel } from '@/types/project';
import { DataForm } from '@/types/dataForm';
import PageTabs from '@/layouts/PageTabs.vue';
import { ActiveItemState } from '@/store/activeItemModule/activeItemModule';

const tabs: PageTab[] = [
  {
    label: 'Overview',
    route: '',
  },
  {
    label: 'Assets',
    route: 'assets',
  },
  {
    label: 'Connections',
    route: 'connections',
  },
  {
    label: 'Dependencies',
    route: 'dependencies',
  },
];

export default Vue.extend({
  name: 'FormTabs',
  components: {
    PageTabs,
  },
  computed: {
    form(): DataForm | null {
      const item: ActiveItemState<DataForm> = this.$store.getters['activeItemModule/details'];
      if (item.details && item.type === 'form') {
        return item.details;
      }
      return null;
    },
    project(): ProjectModel {
      return this.$store.getters['projectModule/details'];
    },
    org(): OrgModel {
      return this.$store.getters['orgModule/details'];
    },
    breadcrumbs(): Breadcrumbs[] {
      return [
        {
          label: this.org.label,
          url: `/org/${this.org.id}/projects`,
        },
        {
          label: this.project.label,
          url: `/org/${this.org.id}/project/${this.project.id}/forms`,
        },
        {
          label: this.form?.label || '',
        },
      ];
    },
  },
  data() {
    return {
      tabs,
    };
  },
});
