<template>
  <q-dialog
    v-model="visible"
    :persistent="persistent"
    class="test--action-modal"
    v-bind="$attrs"
    @hide="$emit('close')"
  >
    <app-card
      :title="title"
      :has-sticky-footer="hasStickyFooter"
      class="action-modal--card"
    >
      <template #actions>
        <q-space/>
        <q-btn
          v-if="!persistent"
          flat
          round
          dense
          v-close-popup
          size="sm"
          icon="close"
          class="test--action-modal-close"
        />
      </template>

      <slot></slot>

      <template #footer>
        <slot name="footer"></slot>
      </template>
    </app-card>
  </q-dialog>
</template>

<script>
import AppCard from '@/components/card/AppCard.vue';

export default {
  name: 'AppModal',
  components: { AppCard },
  props: {
    title: String,
    hasStickyFooter: Boolean,
    persistent: Boolean,
  },
  computed: {
    hasFooter() {
      return Boolean(this.$slots.footer);
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style>
.action-modal--card {
  min-width: 320px;
}
</style>
