import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';
import { loadDetails } from '@/store/storeHelpers';
import { RootState } from '@/store/rootState';
import { PersonalDetails, UserDetails, WorkDetails } from '@/types/profile';
import { Storable } from '@/types/common';
import { SocialDetailsMap } from '@/types/social';
import { Roles } from '@/types';

type StorableUserDetails = Storable<UserDetails>;
type StorableSocialDetails = Storable<SocialDetailsMap>;
type StorablePersonalDetails = Storable<PersonalDetails>;
type StorableWorkDetails = Storable<WorkDetails>;

type ProfileState = {
  user: StorableUserDetails;
  social: StorableSocialDetails;
  personal: StorablePersonalDetails;
  work: StorableWorkDetails;
};

const getUserDetails = () => ({
  id: '',
  email: '',
  surname: '',
  forename: '',
  displayName: '',
  bio: '',
  avatar: '',
  createdAt: 0,
  actions: [],
  role: Roles.VIEWER,
});

const getSocialDetails = () => ({
  facebook: '',
  instagram: '',
  twitter: '',
  linkedin: '',
});

const getPersonalDetails = (): PersonalDetails => ({
  nationality: '',
  residency: '',
  gender: '',
  dateOfBirth: 0,
});

const getWorkDetails = () => ({
  role: '',
  industry: '',
  companyWebsite: '',
});

const profileState: ProfileState = {
  user: {
    details: getUserDetails(),
    loaded: false,
    error: '',
  },
  social: {
    details: getSocialDetails(),
    loaded: false,
    error: '',
  },
  personal: {
    details: getPersonalDetails(),
    loaded: false,
    error: '',
  },
  work: {
    details: getWorkDetails(),
    loaded: false,
    error: '',
  },
};

const $state: ProfileState = { ...profileState };

const getters: GetterTree<ProfileState, RootState> = {
  user(state: ProfileState): UserDetails {
    return state.user.details || getUserDetails();
  },
  social(state: ProfileState): SocialDetailsMap {
    return state.social.details || getSocialDetails();
  },
  personal(state: ProfileState): PersonalDetails {
    return state.personal.details || getPersonalDetails();
  },
  work(state: ProfileState): WorkDetails {
    return state.work.details || getWorkDetails();
  },
  avatar(state: ProfileState) {
    if (state.user.details) {
      return state.user.details.avatar;
    }
    return '';
  },
};

const actions: ActionTree<ProfileState, any> = {
  async loadUserDetails(context: ActionContext<ProfileState, RootState>, force = false) {
    const storable = await loadDetails<UserDetails>(context.state.user, 'user/account', force);
    context.commit('setUserDetails', storable);
  },

  async loadSocialDetails(context: ActionContext<ProfileState, RootState>, force = false) {
    const storable = await loadDetails<SocialDetailsMap>(context.state.social, 'user/social', force);
    context.commit('setSocialDetails', storable);
  },

  async loadPersonalDetails(context: ActionContext<ProfileState, RootState>, force = false) {
    const storable = await loadDetails<PersonalDetails>(context.state.personal, 'user/profile', force);
    context.commit('setPersonalDetails', storable);
  },

  async loadWorkDetails(context: ActionContext<ProfileState, RootState>, force = false) {
    const storable = await loadDetails<WorkDetails>(context.state.work, 'user/work', force);
    context.commit('setWorkDetails', storable);
  },

  clear(context: ActionContext<ProfileState, RootState>) {
    context.commit('clear');
  },
};

const mutations: MutationTree<ProfileState> = {
  setUserDetails(state: ProfileState, s: StorableUserDetails) {
    state.user.details = s.details || getUserDetails();
    state.user.loaded = s.loaded;
    state.user.error = s.error;
  },
  setSocialDetails(state: ProfileState, s: StorableSocialDetails) {
    state.social.details = s.details || getSocialDetails();
    state.social.loaded = s.loaded;
    state.social.error = s.error;
  },
  setPersonalDetails(state: ProfileState, s: StorablePersonalDetails) {
    state.personal.details = s.details || getPersonalDetails();
    state.personal.loaded = s.loaded;
    state.personal.error = s.error;
  },
  setWorkDetails(state: ProfileState, s: StorableWorkDetails) {
    state.work.details = s.details || getWorkDetails();
    state.work.loaded = s.loaded;
    state.work.error = s.error;
  },
  clear(state: ProfileState) {
    state.user.details = getUserDetails();
    state.user.loaded = false;
    state.user.error = '';

    state.social.details = getSocialDetails();
    state.social.loaded = false;
    state.social.error = '';

    state.social.details = getSocialDetails();
    state.social.loaded = false;
    state.social.error = '';

    state.work.details = getWorkDetails();
    state.work.loaded = false;
    state.work.error = '';
  },
};

export default {
  namespaced: true,
  state: $state,
  getters,
  actions,
  mutations,
};
