



























































import Vue, { PropType } from 'vue';
import { DiagramSettings } from '@/bridge/types/diagramModel';
import FieldNumeric from '@/components/fields/FieldNumeric.vue';
import FieldCheckbox from '@/components/fields/FieldCheckbox.vue';
import FieldColorPicker from '@/components/fields/FieldColorPicker.vue';

export default Vue.extend({
  name: 'DiagramSettings',
  components: {
    FieldCheckbox,
    FieldNumeric,
    FieldColorPicker,
  },
  props: {
    value: {
      type: Object as PropType<DiagramSettings>,
      required: true,
    },
  },
  data() {
    return {
      model: { ...this.value },
    };
  },
  methods: {
    emitInput() {
      this.$emit('input', this.model);
    },
  },
});
