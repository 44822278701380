export function pluralize(word: string, count = 1): string {
  const c = Math.abs(count);

  if (c === 1) {
    return word;
  }
  return `${word}s`;
}

/**
 * cat -> Cat
 * some cats -> Some cats
 * @param {string} str - the word to capitalize
 * @return {string}
 */
export function capitalizeFirstWord(str: string): string {
  if (!str) return '';
  return `${str[0].toUpperCase()}${str.substring(1)}`;
}

/**
 * cat -> Cat
 * some cats -> Some Cats
 * @param {string} str - the word/s to capitalize
 * @return {string}
 */
export function capitalizeAllWords(str: string): string {
  if (!str) return '';

  return str.split(' ').map(capitalizeFirstWord).join(' ');
}
