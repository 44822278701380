<template>
  <div class="oplApp">
    <div class="flex flex-center app-spinner-container" v-if="loading">
      <PageSpinner />
    </div>
    <transition name="fade" v-else>
      <router-view />
    </transition>
    <SessionExpiredInfo ref="sessionExpired" />
  </div>
</template>

<script>
import * as go from 'gojs';
import PageSpinner from '@/components/PageSpinner.vue';
import SessionExpiredInfo from '@/components/SessionExpiredInfo.vue';
import contentUpdateListener from '@/util/contentUpdateListener';
import { verifySession } from '@/plugins/auth/auth';

go.licenseKey = '73f947e3ba6431b700ca0d2b113f69ec1bb37f3b9ed41ef6590041f5ef0e68403089ee2b01d48bc382f81afb182ac28ad4966c299e1c0069e162d38945b784f0e13f77b3475a44d9a3562ec08bea2cf4ac7e71a092b465b2d9798cfaf4a0dc9f5e';

export default {
  name: 'App',
  components: {
    PageSpinner,
    SessionExpiredInfo,
  },
  data() {
    return {
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;

    await this.$auth.init();

    setTimeout(async () => {
      const hasSession = await verifySession();

      if (!hasSession) {
        if (!this.$route.matched.some((r) => r.meta.guest)) {
          await this.$store.dispatch('clear');
          this.$auth.$emit('sessionExpired');
        }
        this.loading = false;
        return;
      }

      await this.$store.dispatch('init');

      this.$auth.$on('auth:error', async () => {
        if (!this.$route.matched.some((r) => r.meta.guest)) {
          await this.$store.dispatch('clear');
          this.$refs.sessionExpired.openModal();
        }
      });

      contentUpdateListener.init();
      this.loading = false;
    }, 300);
  },
  beforeDestroy() {
    this.$auth.destroy();
  },
};
</script>

<style>
.oplApp {
  min-height: 100vh;
}

.app-spinner-container {
  width: 100vw;
  height: 100vh;
}

/* For GoJS - do not remove! */
.gsfBackground {
  margin-bottom: 17px;
  font-size: 10px;
  border-color: #ba9cae;
}
</style>
