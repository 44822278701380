<template>
  <div class="auth-layout flex flex-center">
    <div class="auth-layout--card rounded-borders bg-white q-px-lg q-py-xl">
      <img
        style="width: 260px;"
        alt="Obashi Platform"
        src="@/assets/images/logo-dark.png"
        class="q-mx-auto q-mb-xl block"
      />
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthLayout',
  props: {
    title: String,
    description: String,
  },
};
</script>

<style lang="scss">
.auth-layout {
  min-height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.auth-layout--card {
  width: 812px;
}

@media screen and (max-width: 1923px) {
  .auth-layout {
    background-image: url('../assets/images/auth-bg.jpg');
  }
}

@media screen and (min-width: 1924px) {
  .auth-layout {
    background-image: url('../assets/images/auth-bg@2x.jpg');
  }
}
</style>
